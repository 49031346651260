import { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Modal } from '@mui/material';
// import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUserContext } from '../../../Providers/UserContext';
import { useDeviceContext } from '../../../Providers/DeviceContext';
import { useSessionsContext } from '../../../Providers/SessionsContext';
import { useMixpanelContext } from '../../../Providers/MixpanelContext';
import ClassCardHeader from './ClassCardHeader';
import ClassCardBody from './ClassCardBody';
import BookRestrictionBtn from './BookRestrictionBtn';
import RestricitonAlert from '../../Modals/RestrictionAlert';
import { Session, UserWeeklyStatus } from '../../../helpers/types';
import AddCalendarImage from '../../../assets/image/calendar.png';
import CalendarAddBtnImage from '../../../assets/image/calendar-add-btn.png';
import BottomConfirmationPopup from '../../Shared/BottomConfirmationPopup';
import CalendarModal from '../../Modals/CalendarModal';
import {
  TIME_TILL_ENABLE_BOOKING,
  differenceInMinutes,
  getFromLocalStorage,
  getIsUserLiveTutoring,
  getCurrWeekBookedSessionsStatus,
  getMinsSymbolByLang,
  getCurrWeekStartDate,
  getFromLocalStorageWithExpiry,
  isSessionOverlapping,
} from '../../../helpers/utils';

interface ClassCardProps {
  isBooked: boolean;
  session: Session;
  cancelSession: (sessionId: number) => void;
  joinSession: (sessionId: number) => void;
  onSessionBook: (sessionId: number) => void;
  recentEnrolledSessionId: number | null;
  setRecentEnrolledSessionId: (sessionId: number | null) => void;
  lang: string;
  checkIfBlockedUser: () => void;
  platform: string;
  userWeeklyStatus?: UserWeeklyStatus;
  groupWeeklyLimit?: number;
}

function ClassCard({
  isBooked,
  session,
  cancelSession,
  joinSession,
  onSessionBook,
  recentEnrolledSessionId,
  setRecentEnrolledSessionId,
  lang,
  checkIfBlockedUser,
  platform,
  userWeeklyStatus,
  groupWeeklyLimit,
}: ClassCardProps) {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const { os } = useDeviceContext();
  const { user, token } = useUserContext();
  const { sessions, updateCurrentSession } = useSessionsContext();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const [openRestrictionMsgModal, setOpenRestrictionMsgModal] = useState(false);
  const [openCalendarAlertModal, setOpenCalendarAlertModal] = useState(false);
  const [showCalendarClientsModal, setShowCalendarClientsModal] = useState(false);
  const refCalendarClientContainer = useRef<HTMLDivElement>(null);
  const refCalendarModal = useRef<HTMLDivElement>(null);
  const [isSessionBooked, setIsSessionBooked] = useState(false);
  const [restrictionMsgType, setRestrictionMsgType] = useState('');
  const [showCalendarAnimation, setShowCalendarAnimation] = useState(false);
  const [showCalendarSuccess, setShowCalendarSuccess] = useState(false);
  // const [showNotifyMeSuccess, setShowNotifyMeSuccess] = useState(false);
  const [isPrevAddedToCalendar, setIsPrevAddedToCalendar] = useState(
    getFromLocalStorage(`calendar-${session.id}`) || false,
  );
  // const { preventEarlyBooking } = useFlags();
  const intl = useIntl();
  // console.log('session: ', session);

  const timeTillSessionHours = (new Date(session.date).getTime() - new Date().getTime()) / 3600000;
  const [timeTillSessionEnable, setTimeTillSessionEnable] = useState(
    timeTillSessionHours - TIME_TILL_ENABLE_BOOKING < 0,
  );
  const [isCancelBtnDisable, setIsCancelBtnDisable] = useState(
    differenceInMinutes(new Date(session?.date), Date.now()) < 60,
  );

  const isJoinClicked = getFromLocalStorageWithExpiry('isJoin') === session.id;
  const [isJoinDisable, setIsJoinDisable] = useState(
    differenceInMinutes(new Date(session?.date), Date.now()) < -10 && !isJoinClicked,
  );

  const setAppCurrentSession = async (sessionId: string) => {
    try {
      updateCurrentSession(sessionId, token!);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const checkEnableCancelButtonInterval = setInterval(() => {
      setIsCancelBtnDisable(differenceInMinutes(new Date(session?.date), Date.now()) < 60);
    }, 60 * 1000);
    return () => clearInterval(checkEnableCancelButtonInterval);
  }, [session.date, isCancelBtnDisable]);

  useEffect(() => {
    const checkEnableJoinButtonInterval = setInterval(() => {
      setIsJoinDisable(
        differenceInMinutes(new Date(session?.date), Date.now()) < -10 && !isJoinClicked,
      );
    }, 60 * 1000);
    return () => clearInterval(checkEnableJoinButtonInterval);
  }, [session.date, isJoinDisable]);

  useEffect(() => {
    const checkEnableBookButtonInterval = setInterval(() => {
      setTimeTillSessionEnable(
        (new Date(session.date).getTime() - new Date().getTime()) / 3600000 -
          TIME_TILL_ENABLE_BOOKING <
          0,
      );
    }, 60 * 1000);

    return () => clearInterval(checkEnableBookButtonInterval);
  }, [session.date, timeTillSessionEnable]);

  const addToCalendar = () => {
    if (session) {
      // Mixpanel metric:
      mixpanelTrackEvent('AddToCalendarClicked', {
        SessionId: session.id,
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      });

      window.mondlyNative
        ?.addCalendarEvent(
          `Mondly: ${session?.topic?.title}`,
          {
            start: new Date(session?.date).getTime(),
            end: new Date(new Date(session.date).getTime() + 45 * 60 * 1000).getTime(),
          },
          session?.topic?.description,
          `https://www.mondlylanguages.com/home?live_tutoring=true&uri=/session/${session.id}`,
        )
        .then((res) => {
          if (res) {
            if (!isPrevAddedToCalendar) {
              setIsPrevAddedToCalendar(true);
              localStorage.setItem(`calendar-${session.id}`, JSON.stringify(true));
            }
            setShowCalendarSuccess(true);
            setTimeout(() => {
              setShowCalendarSuccess(false);
            }, 3000);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // Close calendar modal when click outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!refCalendarModal.current?.contains(event.target)) {
        setShowCalendarClientsModal(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [refCalendarModal]);

  const handleBookSession = async () => {
    if (!user?.isWizard || user.wizardSessionsNum === 0) {
      // prevent book if blocked user:
      checkIfBlockedUser();
      // Mixpanel metric:
      mixpanelTrackEvent('SessionBookBtnClicked', {
        SessionId: session.id,
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      });

      if (
        (sessions &&
          sessions.filter((item: Session) => item.isEnroll).length >= 3 &&
          (!user?.b2b.groupId || !user.isPrivate)) ||
        (user?.isWizard &&
          sessions &&
          sessions.filter((item: Session) => item.isEnroll).length >= 1)
      ) {
        if (user?.isWizard) {
          setRestrictionMsgType('max1BookedSessions');
        } else {
          setRestrictionMsgType('max3BookedSessions');
        }
        setOpenRestrictionMsgModal(true);
      } else if (sessions && isSessionOverlapping(sessions, session)) {
        setRestrictionMsgType('noDoubleBooking');
        setOpenRestrictionMsgModal(true);
      } else {
        await setAppCurrentSession(session.id.toString());
        onSessionBook(session.id);
      }
    }
  };

  const handleJoinSession = async () => {
    await setAppCurrentSession(session.id.toString());
    joinSession(session.id);
    // Mixpanel metric:
    mixpanelTrackEvent('JoinSessionBtnClicked', {
      SessionId: session.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
  };

  function handleCancelModalClose() {
    setOpenCancelModal(false);
  }

  function handleCancelModalYesBtn() {
    setIsSessionBooked(false);
    cancelSession(session.id);
    // Mixpanel metric:
    mixpanelTrackEvent('CancelSessionBtnClicked', {
      SessionId: session.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
    // remove from local storage the calendar
    localStorage.removeItem(`calendar-${session.id}`);
    handleCancelModalClose();
  }

  const handleCalendarModalYesBtn = () => {
    setOpenCalendarAlertModal(false);
    addToCalendar();
  };

  useEffect(() => {
    if (recentEnrolledSessionId && recentEnrolledSessionId === session.id) {
      setShowCalendarAnimation(true);
      setRecentEnrolledSessionId(null);
    }
  }, [recentEnrolledSessionId, setRecentEnrolledSessionId, session.id]);

  // weeklyLimit (private groups):
  const isBookBtnDisabledWeeklyLimit = () => {
    if (userWeeklyStatus) {
      const weeklyBookedSessionsByDate: number = getCurrWeekBookedSessionsStatus(
        dayjs(session.date),
        userWeeklyStatus,
      );
      if (weeklyBookedSessionsByDate && groupWeeklyLimit) {
        return (
          weeklyBookedSessionsByDate +
            userWeeklyStatus[getCurrWeekStartDate(dayjs(session.date)).format('YYYY-MM-DD')]
              .attended >=
          groupWeeklyLimit
        );
      }
    }
    return false;
  };

  const [isBookBtnDisabled, setIsBookBtnDisabled] = useState<boolean>(
    isBookBtnDisabledWeeklyLimit(),
  );

  useEffect(() => {
    setIsBookBtnDisabled(isBookBtnDisabledWeeklyLimit());
  }, [session.date]);

  return (
    <section
      className={`class-card-container ${!isBooked && isSessionBooked ? 'session-booked' : ''}`}
      dir={lang === 'ar' ? 'rtl' : 'ltr'}
    >
      <div className="class-card">
        <ClassCardHeader session={session} joinSession={joinSession} lang={lang} />
        <div className="divider" />
        <ClassCardBody
          session={session}
          isBooked={isBooked}
          isSessionBooked={isSessionBooked}
          lang={lang}
        />
        {!isBooked &&
          !isSessionBooked &&
          (session.enrolledUsers ? session.enrolledUsers : 0) < session.maxParticipants && (
            <div
              className={`class-action-btns ${
                !isBooked && isSessionBooked ? 'session-booked' : ''
              }`}
            >
              {/* case 1: user has 8h booking restriction */}
              {user && !getIsUserLiveTutoring(user.roles) ? (
                <div className="full-width">
                  {timeTillSessionHours > TIME_TILL_ENABLE_BOOKING ? (
                    <BookRestrictionBtn
                      session={session}
                      lang={lang}
                      // setShowNotifyMeSuccess={setShowNotifyMeSuccess}
                      // platform={platform}
                    />
                  ) : (
                    <button
                      type="button"
                      className={`cta-btn card-cta ${
                        user.wizardSessionsNum && user.wizardSessionsNum >= 1 ? 'disable' : ''
                      }`}
                      onClick={() => handleBookSession()}
                    >
                      <FormattedMessage id="classCard.book" />
                    </button>
                  )}
                </div>
              ) : (
                // case 2: user has no booking restriction
                <button
                  disabled={isBookBtnDisabled}
                  type="button"
                  className={`cta-btn card-cta ${isBookBtnDisabled ? 'disable' : ''}`}
                  onClick={() => handleBookSession()}
                >
                  <FormattedMessage id="classCard.book" />
                </button>
              )}
            </div>
          )}
        {/* BOOKED LESSON CASE 1: If there is less the 3 minutes - show the Join button */}
        {(isBooked || (!isBooked && isSessionBooked)) && (
          <div className="class-action-btns">
            {differenceInMinutes(new Date(session?.date), Date.now()) < 3 && (
              <button
                disabled={isJoinDisable}
                type="button"
                className={`cta-btn card-cta ${isJoinDisable ? 'disable' : ''}`}
                onClick={() => handleJoinSession()}
              >
                <FormattedMessage id="common.join" />
              </button>
            )}
            {/* BOOKED LESSON CASE 2: If there is more then 60 minutes - show the Cancel button */}
            {differenceInMinutes(new Date(session?.date), Date.now()) > 60 && (
              <button
                disabled={isCancelBtnDisable}
                type="button"
                className={`class-cancel-btn secondary-btn ${isCancelBtnDisable ? 'disable' : ''} ${
                  platform === 'web' ? 'web' : ''
                }`}
                onClick={() => setOpenCancelModal(true)}
              >
                <FormattedMessage id="classCard.cancelBooking" />
              </button>
            )}
            {/* BOOKED LESSON CASE 3: If there is less then 60 and more the 15 minutes - Show disable cancelation */}
            {differenceInMinutes(new Date(session?.date), Date.now()) > 15 &&
              differenceInMinutes(new Date(session?.date), Date.now()) <= 60 && (
                <button
                  disabled={isCancelBtnDisable}
                  type="button"
                  className={`class-cancel-btn secondary-btn ${
                    isCancelBtnDisable ? 'disable' : ''
                  } ${platform === 'web' ? 'web' : ''}`}
                  onClick={() => setOpenCancelModal(true)}
                >
                  <FormattedMessage id="classCard.cancelBooking" />
                </button>
              )}
            {/* BOOKED LESSON CASE 4: If there is less the 15 and more the 3 minutes - show disable button with countdown */}
            {differenceInMinutes(new Date(session?.date), Date.now()) <= 15 &&
              differenceInMinutes(new Date(session?.date), Date.now()) >= 3 && (
                <button
                  type="button"
                  className={`class-cancel-btn secondary-btn ${
                    isCancelBtnDisable ? 'disable' : ''
                  } ${platform === 'web' ? 'web' : ''}`}
                >
                  <FormattedMessage id="preClassScreen.classStartsIn" />
                  {Math.round(differenceInMinutes(new Date(session?.date), Date.now()) % 60)}{' '}
                  {lang === 'tr' ? 'dakikada başlıyor' : getMinsSymbolByLang(lang)}
                </button>
              )}
            {platform === 'web' &&
              differenceInMinutes(new Date(session?.date), Date.now()) > 15 && (
                <div className="calendar-items-list" ref={refCalendarModal}>
                  <button
                    type="button"
                    className="calendar-cta"
                    onClick={() => setShowCalendarClientsModal(!showCalendarClientsModal)}
                  >
                    <FormattedMessage id="classCard.addToCalendar" />
                  </button>
                  {showCalendarClientsModal && (
                    <div className="calendar-client-container" ref={refCalendarClientContainer}>
                      <CalendarModal
                        session={session}
                        lang={lang}
                        setShowCalendarClientsModal={setShowCalendarClientsModal}
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
        {platform !== 'web' && !user?.isWizard && (isBooked || (!isBooked && isSessionBooked)) && (
          <button
            type="button"
            className={`add-to-calendar ${showCalendarAnimation ? 'show-calendar-animation' : ''}`}
            onClick={() => setOpenCalendarAlertModal(true)}
          >
            <img
              className={`calendar-icon-img ${
                showCalendarAnimation ? 'show-calendar-animation' : ''
              }`}
              src={AddCalendarImage}
              alt="Calendar-icon"
            />
            <img
              className={`calendar-add-btn-img ${
                showCalendarAnimation ? 'show-calendar-animation' : ''
              }`}
              src={CalendarAddBtnImage}
              alt="Calendar-add-icon"
            />
          </button>
        )}
      </div>
      <Modal
        open={openCancelModal}
        onClose={() => handleCancelModalClose()}
        className="system-alert-modal"
        dir="auto"
      >
        <div className={`${os !== 'iOS' ? 'android' : ''} modal-panel`}>
          <div className={`modal-content${os !== 'iOS' ? '-android' : ''}`}>
            <h4 className={`${os !== 'iOS' ? 'title' : 'modal-content__title'}`}>
              <FormattedMessage id="classCard.areYouSureYoudLikeToCancel" />
            </h4>
          </div>
          <div className={`modal-footer${os !== 'iOS' ? '-android' : ''}`}>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__danger'}`}
              type="button"
              onClick={() => handleCancelModalYesBtn()}
            >
              <FormattedMessage id="common.yes" />
            </button>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__default'}`}
              type="button"
              onClick={() => handleCancelModalClose()}
            >
              <FormattedMessage id="common.no" />
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openRestrictionMsgModal}
        onClose={() => setOpenRestrictionMsgModal(false)}
        className="system-alert-modal"
        dir="auto"
      >
        <div className={`${os !== 'iOS' ? 'android' : ''} modal-panel`}>
          <div className={`modal-content${os !== 'iOS' ? '-android' : ''}`}>
            <h4 className={`${os !== 'iOS' ? 'title' : 'modal-content__title'}`}>
              <RestricitonAlert restrictionMsgType={restrictionMsgType} />
            </h4>
          </div>
          <div className={`modal-footer${os !== 'iOS' ? '-android' : ''}`}>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__default'}`}
              type="button"
              onClick={() => setOpenRestrictionMsgModal(false)}
            >
              <FormattedMessage id="restrictionAlert.ok" />
            </button>
          </div>
        </div>
      </Modal>
      <Modal open={openCalendarAlertModal} className="system-alert-modal">
        <div className={`${os !== 'iOS' ? 'android' : ''} modal-panel `} dir="auto">
          <div className={`modal-content calendar-modal ${os !== 'iOS' ? '-android' : ''}`}>
            {isPrevAddedToCalendar ? (
              <h4 className={`${os !== 'iOS' ? 'title' : 'modal-content__title'}`}>
                <FormattedMessage id="classCard.areYouSureYouWantToAddThisClassToYourCalendarAgain" />
              </h4>
            ) : (
              <h4 className={`${os !== 'iOS' ? 'title' : 'modal-content__title'}`}>
                <FormattedMessage id="classCard.mondlyWouldLikeToAccessYourCalendar" />
              </h4>
            )}
            {!isPrevAddedToCalendar && (
              <p>
                {' '}
                <FormattedMessage id="classCard.TheMondlyApplicationNeedsToAccessYourCalendarToAddEventsToIt" />
              </p>
            )}
          </div>
          <div className={`modal-footer${os !== 'iOS' ? '-android' : ''}`}>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__danger'}`}
              type="button"
              onClick={() => handleCalendarModalYesBtn()}
            >
              <FormattedMessage id="common.yes" />
            </button>
            <button
              className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__default'}`}
              type="button"
              onClick={() => setOpenCalendarAlertModal(false)}
            >
              <FormattedMessage id="common.no" />
            </button>
          </div>
        </div>
      </Modal>
      {showCalendarSuccess && os === 'iOS' && (
        <BottomConfirmationPopup
          confirmationType="booked"
          msgTxt={intl.formatMessage({
            id: 'bottomConfirmationPopup.yourBookedClassWasAddedToYourCalendar',
          })}
        />
      )}
      {/* {showNotifyMeSuccess && (
        <BottomConfirmationPopup
          confirmationType="booked"
          msgTxt={intl.formatMessage({
            id: 'bottomConfirmationPopup.yournotificationIsAllSet',
          })}
          msgSubHTxt={intl.formatMessage({
            id: 'bottomConfirmationPopup.wellAlertYouWhenBookingIsOpen',
          })}
        />
      )} */}
    </section>
  );
}

export default ClassCard;
