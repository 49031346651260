import { createContext, useContext, useCallback, useMemo, useState, memo, ReactNode } from 'react';
import { getMobileOperatingSystem } from '../helpers/utils';

interface State {
  os: string;
  updateDeviceOS: (updatedDeviceOs: string) => void;
  platform: string;
  setPlatform: (updatedPlatform: string) => void;
}

const initContext: State = {
  os: getMobileOperatingSystem(),
  platform: 'mobile',
  updateDeviceOS: () => {},
  setPlatform: () => {},
};

const DeviceContext = createContext(initContext);

type DeviceProviderProps = {
  children: ReactNode;
};

export const DeviceProvider = memo(({ children }: DeviceProviderProps) => {
  const [os, setDeviceOS] = useState(initContext.os);
  const [platform, setPlatform] = useState(initContext.platform);

  const updateDeviceOS = useCallback((updatedDeviceOs: string) => {
    setDeviceOS(updatedDeviceOs);
  }, []);

  const value = useMemo(
    () => ({
      os,
      updateDeviceOS,
      platform,
      setPlatform,
    }),
    [os, updateDeviceOS, platform, setPlatform],
  );

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
});

export const useDeviceContext = () => useContext(DeviceContext);
