import useWindowSize from '../../hooks/useWindowSize';
import { useDeviceContext } from '../../Providers/DeviceContext';

function TermsAndConditions() {
  const { os } = useDeviceContext();
  const windowSize = useWindowSize();
  const screenWidth = windowSize.width || 768;

  return (
    <section className="terms-and-conditions" style={{ width: screenWidth }}>
      <div className="terms-iframe-container">
        <iframe
          id="terms-and-conditions-iframe"
          title="Terms and Conditions"
          src={
            os === 'iOS'
              ? 'https://www.mondlylanguages.com/ios-terms-2.html'
              : 'https://www.mondlylanguages.com/android-terms-2.html'
          }
        />
      </div>
    </section>
  );
}

export default TermsAndConditions;
