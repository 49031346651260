import { useRef } from 'react';
import { Avatar } from '@mui/material';
import { Session } from '../../../helpers/types';
import useWindowSize from '../../../hooks/useWindowSize';
import { useMixpanelContext } from '../../../Providers/MixpanelContext';
import { useUserContext } from '../../../Providers/UserContext';

interface ClassCardHeaderProps {
  session: Session;
  joinSession: (sessionId: number) => void;
  lang: string;
}

function ClassCardHeader({ session, joinSession, lang }: ClassCardHeaderProps) {
  const clickCount = useRef(0);
  const windowSize = useWindowSize();
  const screenWidth = windowSize.width;
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();

  function handleClickOnTutorTitle() {
    if (process.env.REACT_APP_CONTENT_ENV === 'prod') {
      if (user?.id) {
        if (user.id === '116045799' || user.id === '116046062') {
          const newValue = clickCount.current + 1;
          clickCount.current = newValue;
          if (clickCount.current === 5) {
            clickCount.current = 0;
            mixpanelTrackEvent('ForcePreSessionNavigate', {
              SessionId: session?.id,
              UserId: user?.id,
              companyId: user?.b2b.companyId,
              groupId: user?.b2b.groupId,
              groupName: user?.b2b.groupName,
              companyName: user?.b2b.companyName,
              userRoles: user?.roles,
            });
            joinSession(session.id);
          }
          setTimeout(() => {
            clickCount.current = 0;
          }, 5000);
        }
      }
    } else {
      const newValue = clickCount.current + 1;
      clickCount.current = newValue;
      if (clickCount.current === 5) {
        clickCount.current = 0;
        joinSession(session.id);
      }
      setTimeout(() => {
        clickCount.current = 0;
      }, 5000);
    }
  }
  return (
    <section className="class-card-header-container" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="card-header flex justify-start">
        <div className="avatar-container">
          <Avatar
            src={session?.tutor?.thumb}
            sx={{
              width: screenWidth! > 768 ? 100 : 57,
              height: screenWidth! > 768 ? 100 : 57,
            }}
            alt={`${session.tutor.firstName} ${session.tutor.lastName}`}
          />
        </div>
        <div onClick={() => handleClickOnTutorTitle()} aria-hidden>
          <div className="card-title">{session?.topic?.title}</div>
          <div className="card-subtitle font-bold flex">
            <div className="flex-1">
              {session.tutor.firstName} {session.tutor.lastName}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClassCardHeader;
