import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FilterCheckbox from './FilterCheckbox';
import { useUserContext } from '../../Providers/UserContext';
import { ReactComponent as LevelIcon } from '../../assets/image/level-icon.svg';

interface LevelsFilterProps {
  levelQuery: number[];
  setLevelQuery: (value: number[]) => void;
}

function LevelsFilter({ levelQuery, setLevelQuery }: LevelsFilterProps) {
  const [isBeginnerEnabled, setIsBeginnerEnabled] = useState<boolean>(true);
  const [isIntermediateEnabled, setIsIntermediateEnabled] = useState<boolean>(true);
  const [isAdvancedEnabled, setIsAdvancedEnabled] = useState<boolean>(true);
  const { userPrivateGroupInfo } = useUserContext();

  const getEnabledLevelPrivate = async () => {
    if (userPrivateGroupInfo) {
      if (userPrivateGroupInfo.GSELevels) {
        const GSELevelsIds = userPrivateGroupInfo.GSELevels.map((level) => level.id);
        if (!GSELevelsIds.includes(1) && !GSELevelsIds.includes(2) && !GSELevelsIds.includes(3)) {
          setIsBeginnerEnabled(false);
        }
        if (
          !GSELevelsIds.includes(4) &&
          !GSELevelsIds.includes(5) &&
          !GSELevelsIds.includes(6) &&
          !GSELevelsIds.includes(7)
        ) {
          setIsIntermediateEnabled(false);
        }
        if (!GSELevelsIds.includes(8) && !GSELevelsIds.includes(9)) {
          setIsAdvancedEnabled(false);
        }
      }
    }
  };

  useEffect(() => {
    getEnabledLevelPrivate();
  }, []);

  const updateLevelSelection = (val: number) => {
    if (levelQuery.includes(val)) {
      const updatedSelection = levelQuery.filter((level) => level !== val);
      setLevelQuery(updatedSelection);
    } else {
      const updatedSelection = [...levelQuery, val];
      setLevelQuery(updatedSelection);
    }
  };

  return (
    <section className="levels-filter">
      <div className="difficulty-level flex gap-4 align-center">
        <LevelIcon className="level-icon" />
        <h1>
          <FormattedMessage id="filter.difficultyLevel" />
        </h1>
      </div>
      <div className="levels-filter-form">
        <div className="levels-filter-group">
          {isBeginnerEnabled && (
            <button
              type="button"
              className="level-filter border-bottom"
              onClick={() => updateLevelSelection(1)}
            >
              <div className="level-filter-name">
                {' '}
                <span>
                  <FormattedMessage id="classCard.level.Beginner" />
                </span>
                <span className="level-cefr">&nbsp;&bull; A1/A2/A2+</span>
              </div>
              <FilterCheckbox selected={levelQuery.includes(1)} />
            </button>
          )}

          {isIntermediateEnabled && (
            <button
              type="button"
              className="level-filter border-bottom"
              onClick={() => updateLevelSelection(2)}
            >
              <div className="level-filter-name">
                {' '}
                <span>
                  <FormattedMessage id="classCard.level.Intermediate" />
                </span>
                <span className="level-cefr">&nbsp;&bull; B1/B2/B2+</span>
              </div>
              <FilterCheckbox selected={levelQuery.includes(2)} />
            </button>
          )}

          {isAdvancedEnabled && (
            <button type="button" className="level-filter" onClick={() => updateLevelSelection(3)}>
              <div className="level-filter-name">
                {' '}
                <span>
                  <FormattedMessage id="classCard.level.Advanced" />
                </span>
                <span className="level-cefr">&nbsp;&bull; C1/C2</span>
              </div>
              <FilterCheckbox selected={levelQuery.includes(3)} />
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export default LevelsFilter;
