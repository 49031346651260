import { getContentEnv } from './Network';

export const sendEventToMondly = async (type: number | undefined, payload: object) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (!params.testWeb && getContentEnv() === 'prod') {
    // Send event to Mondly app
    window.mondlyNative
      ?.sendAnalyticsEvent(type, payload)
      .catch((err) => console.log('error sending event to Mondly', err));
  } else {
    console.log('Report Event: ', type, payload);
  }
};
