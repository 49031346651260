import { FormattedMessage } from 'react-intl';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { useUserContext } from '../../Providers/UserContext';
import { getICSFile } from '../../helpers/Network';
import { Session } from '../../helpers/types';

interface CalendarModalProps {
  session: Session;
  lang: string;
  setShowCalendarClientsModal: (status: boolean) => void;
}

function CalendarModal({ session, lang, setShowCalendarClientsModal }: CalendarModalProps) {
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();
  const goToCalendarClient = (calendarClient: string) => {
    // Mixpanel metric:
    mixpanelTrackEvent('addToCalendarWeb', {
      client: calendarClient,
      sessionDate: session.date,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
    const title = encodeURI(`Mondly: ${session.topic.title}`);
    const details = encodeURI(session.topic.description);
    const sessionStart = session.date.replace(/[-:.]/g, '');
    const sessionEndTime = new Date(session.date);
    sessionEndTime.setMinutes(sessionEndTime.getMinutes() + 45);
    const sessionEnd = sessionEndTime.toISOString().replace(/[-:.]/g, '');
    setShowCalendarClientsModal(false);
    switch (calendarClient) {
      case 'google':
        window.open(
          `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${sessionStart}%2f${sessionEnd}&details=${details}&text=${title}`,
          '_blank',
        );
        break;
      case 'apple':
        getICSFile(session.id.toString(), lang);
        break;
      case 'outlook':
        window.open(
          `https://outlook.office.com/calendar/0/action/compose?body=${details}&enddt=${sessionEndTime.toISOString()}&rru=addevent&startdt=${
            session.date
          }&subject=${title}`,
          '_blank',
        );
        break;
      case 'ics':
        getICSFile(session.id.toString(), lang);
        break;
      // case 'yahoo':
      //   console.log('yahoo');
      //   window.open(
      //     `https://calendar.yahoo.com/?desc=${details}&st=${sessionStart}&et=${sessionEnd}&title=${title}&v=60`,
      //     '_blank',
      //   );
      //   break;
      default:
        break;
    }
  };

  return (
    <div className="calenders-list" dir="ltr">
      <div className="calendar-item" onClick={() => goToCalendarClient('google')} aria-hidden>
        <div className="calendar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.4427 8.60048C14.5615 7.77667 13.3855 7.32788 12.1688 7.34632C9.94224 7.34632 8.05127 8.81871 7.37703 10.8014C7.01953 11.8404 7.01953 12.9654 7.37703 14.0044H7.38016C8.05752 15.984 9.94537 17.4564 12.1719 17.4564C13.3212 17.4564 14.3079 17.1683 15.0727 16.6593V16.6572C15.9727 16.0732 16.5873 15.1541 16.7786 14.1151H12.1688V10.8936H20.2188C20.3191 11.4531 20.3661 12.0248 20.3661 12.5935C20.3661 15.138 19.4385 17.2892 17.8243 18.7462L17.826 18.7475C16.4117 20.0262 14.4705 20.767 12.1688 20.767C8.94186 20.767 5.99093 18.9842 4.54213 16.1592C3.33166 13.7954 3.33166 11.0104 4.54213 8.64661C5.99094 5.81861 8.94186 4.03572 12.1688 4.03572C14.2887 4.01113 16.3364 4.7919 17.8793 6.21205L15.4427 8.60048Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="calendar-text">Google Calendar</div>
      </div>
      <div className="calendar-divider" />
      <div className="calendar-item" onClick={() => goToCalendarClient('apple')} aria-hidden>
        <div className="calendar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.9259 16.3391C20.6247 16.9985 20.2681 17.6054 19.855 18.1635C19.292 18.9242 18.8309 19.4508 18.4756 19.7432C17.9249 20.2232 17.3348 20.469 16.7028 20.483C16.2492 20.483 15.7021 20.3607 15.0653 20.1125C14.4263 19.8655 13.8392 19.7432 13.3023 19.7432C12.7392 19.7432 12.1353 19.8655 11.4894 20.1125C10.8425 20.3607 10.3214 20.49 9.92291 20.5028C9.31693 20.5273 8.71293 20.2745 8.11002 19.7432C7.72522 19.4252 7.24391 18.8799 6.66731 18.1076C6.04868 17.2827 5.54007 16.3263 5.14162 15.2358C4.71489 14.058 4.50098 12.9175 4.50098 11.8133C4.50098 10.5485 4.7894 9.45756 5.36709 8.54338C5.82111 7.80908 6.42512 7.22985 7.18108 6.80462C7.93705 6.3794 8.75386 6.16271 9.63351 6.14884C10.1148 6.14884 10.746 6.28992 11.5304 6.56719C12.3125 6.8454 12.8147 6.98648 13.0349 6.98648C13.1995 6.98648 13.7574 6.82151 14.7032 6.49263C15.5976 6.18764 16.3525 6.06135 16.9709 6.1111C18.6465 6.23925 19.9054 6.8652 20.7427 7.99292C19.244 8.85339 18.5027 10.0586 18.5174 11.6046C18.531 12.8089 18.992 13.811 19.8981 14.6067C20.3087 14.976 20.7673 15.2615 21.2775 15.4642C21.1668 15.7682 21.05 16.0595 20.9259 16.3391ZM17.0827 2.00844C17.0827 2.95233 16.7188 3.83364 15.9935 4.64937C15.1181 5.61912 14.0594 6.17948 12.9112 6.09106C12.8966 5.97782 12.8881 5.85864 12.8881 5.7334C12.8881 4.82727 13.3044 3.85752 14.0436 3.06463C14.4127 2.66317 14.8821 2.32936 15.4513 2.06308C16.0193 1.80076 16.5565 1.6557 17.0618 1.63086C17.0766 1.75704 17.0827 1.88323 17.0827 2.00843V2.00844Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="calendar-text">Apple Calendar</div>
      </div>
      <div className="calendar-divider" />
      <div className="calendar-item" onClick={() => goToCalendarClient('outlook')} aria-hidden>
        <div className="calendar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.6128 5.95312V10.0609L16.0498 10.9646C16.1017 10.976 16.1554 10.976 16.2073 10.9646L22.3858 6.79912C22.3813 6.59696 22.3073 6.40251 22.1763 6.24845C22.0454 6.09439 21.8653 5.99011 21.6665 5.95312H14.6128Z"
              fill="white"
            />
            <path
              d="M14.6131 11.5925L15.9233 12.4925C15.9847 12.5298 16.0551 12.5496 16.1269 12.5496C16.1988 12.5496 16.2692 12.5298 16.3306 12.4925C16.1056 12.6282 22.3853 8.45898 22.3853 8.45898V16.0085C22.4017 16.1615 22.3844 16.3162 22.3348 16.4618C22.2851 16.6074 22.2042 16.7404 22.0978 16.8515C21.9913 16.9626 21.8619 17.0491 21.7185 17.1049C21.5752 17.1607 21.4213 17.1846 21.2678 17.1747H14.6123L14.6131 11.5925Z"
              fill="white"
            />
            <path
              d="M7.83016 9.69926C7.61246 9.69821 7.39853 9.75608 7.21103 9.86672C7.02354 9.97737 6.86948 10.1367 6.76516 10.3278C6.48025 10.8343 6.34354 11.4107 6.37066 11.9913C6.34105 12.5706 6.47795 13.1463 6.76516 13.6503C6.86904 13.8335 7.01931 13.9861 7.20088 14.0929C7.38245 14.1996 7.58891 14.2567 7.79952 14.2584C8.01013 14.26 8.21748 14.2062 8.40072 14.1024C8.58396 13.9986 8.73663 13.8483 8.84341 13.6668C9.12749 13.1651 9.26156 12.5924 9.22966 12.0168C9.26219 11.423 9.13256 10.8317 8.85466 10.306C8.75535 10.1212 8.60743 9.96694 8.42688 9.86001C8.24633 9.75308 8.03999 9.6975 7.83016 9.69926Z"
              fill="white"
            />
            <path
              d="M1.61475 3.86625V19.9365L13.8397 22.5V1.5L1.61475 3.86625ZM9.79575 14.6182C9.56706 14.9406 9.26298 15.2022 8.91002 15.3801C8.55706 15.558 8.16593 15.6469 7.77075 15.639C7.38551 15.6458 7.00431 15.5595 6.65958 15.3874C6.31486 15.2153 6.01679 14.9625 5.79075 14.6505C5.25464 13.902 4.98645 12.9947 5.0295 12.075C4.98389 11.1102 5.25692 10.1572 5.8065 9.363C6.03807 9.03445 6.34692 8.76791 6.70582 8.5869C7.06472 8.40588 7.46264 8.31594 7.8645 8.325C8.24695 8.31733 8.62543 8.40365 8.96673 8.57639C9.30803 8.74913 9.60172 9.00302 9.822 9.31575C10.3527 10.0805 10.6164 10.9989 10.572 11.9288C10.6188 12.8865 10.3457 13.8327 9.79575 14.6182Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="calendar-text">Outlook</div>
      </div>
      <div className="calendar-divider" />
      {/* <div className="calendar-item" onClick={() => goToCalendarClient('yahoo')} aria-hidden>
        <div className="calendar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
          >
            <path
              d="M21.8906 3.85625C21.9 3.85625 21.9094 3.85625 21.9094 3.85625L22.575 2.89062C22.5656 2.89062 22.5469 2.89062 22.5375 2.89062L22.6031 2.7875H13.2375L13.6031 4.25938H16.1625L11.85 8.15C10.9687 6.93125 8.90625 4.40937 7.47187 2.30937H10.6969V1.18437L10.7344 0.940626C10.725 0.940626 10.7063 0.931251 10.6969 0.931251V0.828125H0V2.30937H3.16875C4.39687 3.275 9.1125 9.15312 9.3 9.73438C9.375 10.2875 9.45 13.5219 9.21562 13.7656C8.75625 14.2531 6.6375 14.1219 6.13125 14.1594L5.95312 15.1625C6.88125 15.1906 9.90937 15.0875 10.8469 15.0875C12.7031 15.0875 15.9469 15.0781 16.4062 15.1156L16.4625 14.0656C15.9937 13.9906 13.4344 14.0563 13.0875 13.9719C13.0125 13.4656 12.9281 10.0531 13.0031 9.69688C13.3687 8.675 18.7406 4.53125 19.3312 4.37187C19.4625 4.34375 19.6594 4.29688 19.8844 4.25H21.6187L21.8906 3.85625Z"
              fill="white"
            />
            <path
              d="M20.8876 12.4342L22.322 12.5467L24.0001 6.07793C23.7188 6.06855 21.1782 5.8248 20.8501 5.76855L20.8876 12.4342Z"
              fill="white"
            />
            <path
              d="M20.6436 13.4746L20.6529 15.0496L21.3748 15.1152L22.1623 15.1715L22.3967 13.6246L21.5623 13.5871L20.6436 13.4746Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="calendar-text">Yahoo</div>
      </div>
      <div className="calendar-divider" /> */}
      <div className="calendar-item" onClick={() => goToCalendarClient('ics')} aria-hidden>
        <div className="calendar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 14C4.55228 14 5 14.4477 5 15V19H19V15C19 14.4477 19.4477 14 20 14C20.5523 14 21 14.4477 21 15V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V15C3 14.4477 3.44772 14 4 14Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 15.5C12.2652 15.5 12.5196 15.3946 12.7071 15.2071L16.2071 11.7071C16.5976 11.3166 16.5976 10.6834 16.2071 10.2929C15.8166 9.90237 15.1834 9.90237 14.7929 10.2929L13 12.0858V4C13 3.44771 12.5523 3 12 3C11.4477 3 11 3.44771 11 4V12.0858L9.20711 10.2929C8.81658 9.90237 8.18342 9.90237 7.79289 10.2929C7.40237 10.6834 7.40237 11.3166 7.79289 11.7071L11.2929 15.2071C11.4804 15.3946 11.7348 15.5 12 15.5Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="calendar-text">
          <FormattedMessage id="classCard.downloadics" />
        </div>
      </div>
    </div>
  );
}
export default CalendarModal;
