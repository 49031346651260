import { useEffect, useState } from 'react';

interface YellowProgressBarProps {
  maxVal: number;
  currVal: number;
}

function YellowProgressBar({ maxVal, currVal }: YellowProgressBarProps) {
  const [progress, setProgress] = useState((currVal / maxVal) * 100);
  useEffect(() => {
    if (currVal <= maxVal) {
      setProgress((currVal / maxVal) * 100);
    }
  }, [currVal, maxVal, setProgress]);
  return (
    <section className="yellow-progress-bar-container">
      <div className="progress-bar">
        <div className="emptyProgressBar" style={{ width: '100%' }}>
          <div
            className="progress-fill"
            style={{
              left: `${progress - 100}%`,
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default YellowProgressBar;
