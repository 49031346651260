import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getLangDir, toCamelCase } from '../../../helpers/utils';

interface FeedbackOptionProps {
  optionText: string;
  questionNum: 1 | 2 | 3 | 4;
  onFeedbackOptionSelect: (val: string) => void;
  lang: string;
  isDisabled?: boolean;
  isMarginTop?: boolean;
  fullFeedback: {
    [key: number]: {
      rate: number | null;
      response: {
        userSelection: string[];
        userFreeText: string;
      };
    };
  };
}

function FeedbackOption({
  lang,
  optionText,
  questionNum,
  onFeedbackOptionSelect,
  isDisabled = false,
  isMarginTop = false,
  fullFeedback,
}: FeedbackOptionProps) {
  // const onChooseRate = () => {
  //   console.log('FeedbackOption question no: ', questionNum);

  //   onResponse(starVal);
  // };
  const getIsOptionSelected = () => {
    return fullFeedback[questionNum].response.userSelection.includes(optionText);
  };
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(getIsOptionSelected());
  }, [fullFeedback, optionText, questionNum]);

  return (
    <section
      className={`feedback-option-container ${isSelected ? 'selected' : ''} ${
        isDisabled ? 'disabled' : ''
      } ${isMarginTop ? 'margin-top' : ''}`}
      dir={getLangDir(lang)}
    >
      <button
        className="option-btn"
        type="button"
        onClick={() => onFeedbackOptionSelect(optionText)}
      >
        <FormattedMessage id={`feedbackOption.${toCamelCase(optionText)}`} />
      </button>
    </section>
  );
}

export default FeedbackOption;
