import { FormattedMessage } from 'react-intl';

function TxtNotificationModal() {
  return (
    <section className="pre-class-screen holding-screen" dir="auto">
      <div className="pre-class-title">
        <FormattedMessage id="txtNotificationModal.sessionOpenedInNewTab" />
      </div>
      <div className="holding-screen-description txt-align-start">
        <p>
          <FormattedMessage id="txtNotificationModal.sessionOpenedInNewTabParagraph1" />
        </p>
        <p>
          <FormattedMessage id="txtNotificationModal.sessionOpenedInNewTabParagraph2" />
        </p>
      </div>
    </section>
  );
}

export default TxtNotificationModal;
