import { useEffect } from 'react';

function CloseTab() {
  useEffect(() => {
    window.close();
  }, []);

  return <> </>;
}

export default CloseTab;
