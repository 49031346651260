import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { singleFeedback, multipleSelectFeedback, getSessionInfo } from '../../../helpers/Network';
import { useUserContext } from '../../../Providers/UserContext';
import { useMixpanelContext } from '../../../Providers/MixpanelContext';
import FeedbackStarRate from './FeedbackStarRateJun24';
import FeedbackOption from './FeedbackOption';
import { sendEventToMondly } from '../../../helpers/Event';
import { getLangDir } from '../../../helpers/utils';
import caretRight from '../../../assets/image/caret-right-24.png';
import caretLeft from '../../../assets/image/caret-left-24.png';
import { Session } from '../../../helpers/types';

// screens:
// 1: 1st question rating
// 2: 1st question positive feedback
// 3: 1st question neutral-negative feedback
// 4: 2nd question rating
// 5: 2nd question positive feedback
// 6: 2nd question neutral - negative feedback
// 7: 3rd question rating
// 8: 3rd question positive feedback
// 9: 3rd question neutral-negative feedback
// 10: 4th question rating
// 11: Thank you screen

interface FeedbackProps {
  resetKeyboardScroll: () => void;
  lang: string;
  setYellowProgressBarMaxVal: (val: number) => void;
  setYellowProgressBarCurrVal: (val: number) => void;
  setIsFeedbackScreen?: (a: boolean) => void;
}

function Feedback({
  resetKeyboardScroll,
  lang,
  setYellowProgressBarMaxVal,
  setYellowProgressBarCurrVal,
  setIsFeedbackScreen = undefined,
}: FeedbackProps) {
  const { sessionId } = useParams();
  const [questionNum, setQuestionNum] = useState<1 | 2 | 3 | 4>(1);
  const [currScreen, setCurrScreen] = useState(1);
  const [isThankYouScreen, setIsThankYouScreen] = useState(false);
  const QUESTION_MAX_VAL = 4;
  const navigate = useNavigate();
  const intl = useIntl();
  const { token, user } = useUserContext();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const refCompletedBtn = useRef<HTMLButtonElement>(null);
  const isMobile = !(document.body.offsetWidth > 500);
  const [sessionInfo, setSessionInfo] = useState<Session | null>(null);

  useEffect(() => {
    const fetchSessionInfo = async () => {
      if (sessionId && token) {
        try {
          const fetchedSessionInfo = await getSessionInfo(token, sessionId);
          setSessionInfo(fetchedSessionInfo);
        } catch (error) {
          console.error('Error fetching session info:', error);
        }
      }
    };

    fetchSessionInfo();
  }, [sessionId, token]);

  const initialFullFeedback: {
    [key: number]: {
      rate: number | null;
      response: {
        userSelection: string[];
        userFreeText: string;
      };
    };
  } = {
    1: {
      rate: null,
      response: {
        userSelection: [],
        userFreeText: '',
      },
    },
    2: {
      rate: null,
      response: {
        userSelection: [],
        userFreeText: '',
      },
    },
    3: {
      rate: null,
      response: {
        userSelection: [],
        userFreeText: '',
      },
    },
    4: {
      rate: null,
      response: {
        userSelection: [],
        userFreeText: '',
      },
    },
  };
  const [fullFeedback, setFullFeedback] = useState(initialFullFeedback);

  const questionText = () => {
    switch (currScreen) {
      case 1:
        return (
          <>
            {lang !== 'ja' && lang !== 'zh' && (
              <>
                <FormattedMessage id="feedback.howWouldYouRateThe" />

                {lang !== 'es' && lang !== 'ja' && (
                  <>
                    &nbsp;
                    <span style={{ textDecoration: 'underline' }}>
                      <FormattedMessage id="feedback.tutor" />
                    </span>
                  </>
                )}
                {sessionInfo?.tutor.firstName && <span>{` (${sessionInfo.tutor.firstName})`}</span>}
                {lang !== 'ko' && <span>&nbsp;</span>}
                <FormattedMessage id="feedback.InTheClassYouveJustAttended" />
              </>
            )}

            {lang === 'ja' && (
              <>
                {sessionInfo?.tutor.firstName && <span>{`(${sessionInfo.tutor.firstName})`}</span>}
                <span style={{ textDecoration: 'underline' }}>
                  <FormattedMessage id="feedback.tutor" />
                </span>
                <FormattedMessage id="feedback.InTheClassYouveJustAttended" />
              </>
            )}
            {lang === 'zh' && (
              <>
                <FormattedMessage id="feedback.howWouldYouRateThe" />
                <span style={{ textDecoration: 'underline' }}>
                  <FormattedMessage id="feedback.tutor" />
                </span>
                {sessionInfo?.tutor.firstName && <span>{`(${sessionInfo.tutor.firstName})`}</span>}
              </>
            )}
          </>
        );

      case 2:
        return <FormattedMessage id="feedback.whatDidYouLike" />;
      case 3:
        return <FormattedMessage id="feedback.whatCouldBeBetter" />;
      case 4:
        return (
          <>
            <FormattedMessage id="feedback.howWouldYouRateTheContent" />
            {lang !== 'ja' && lang !== 'zh' && <span>&nbsp;</span>}
            <span style={{ textDecoration: 'underline' }}>
              <FormattedMessage id="feedback.content" />
            </span>
            {lang === 'ko' && <span>을</span>}
            {lang !== 'ja' && <span>&nbsp;</span>}
            {lang !== 'zh' && <FormattedMessage id="feedback.ofTheClassYouveJustAttended" />}
          </>
        );
      case 5:
        return <FormattedMessage id="feedback.whatDidYouLike" />;
      case 6:
        return <FormattedMessage id="feedback.whatCouldBeBetter" />;
      case 7:
        return (
          <>
            {lang !== 'ja' && lang !== 'ko' && lang !== 'tr' && (
              <>
                <FormattedMessage id="feedback.howWouldYouRateTheExperience" />
                {lang !== 'zh' && <span>&nbsp;</span>}
                <span style={{ textDecoration: 'underline' }}>
                  <FormattedMessage id="feedback.overallClassExperience" />
                </span>
                {lang === 'de' && (
                  <span>
                    <span>&nbsp;</span>
                    <FormattedMessage id="feedback.InTheClassYouveJustAttended" />
                  </span>
                )}
                {lang === 'ar' && <span>؟</span>}
                {lang === 'fr' && <span>&nbsp;</span>}
                {lang !== 'ar' && lang !== 'de' && <span>?</span>}
              </>
            )}
            {(lang === 'ja' || lang === 'ko' || lang === 'tr') && (
              <>
                <span style={{ textDecoration: 'underline' }}>
                  <FormattedMessage id="feedback.overallClassExperience" />
                </span>
                {(lang === 'ko' || lang === 'tr') && <span>&nbsp;</span>}
                <span>
                  <FormattedMessage id="feedback.ofTheClassYouveJustAttended" />
                </span>
              </>
            )}
          </>
        );
      case 8:
        return <FormattedMessage id="feedback.whatDidYouLike" />;
      case 9:
        return <FormattedMessage id="feedback.whatCouldBeBetter" />;
      case 10:
        return <FormattedMessage id="feedback.didYouExperienceAnyIssuesDuringTheClass" />;
      default:
        return <FormattedMessage id="feedback.feedback" />;
    }
  };

  const responsesText = () => {
    switch (currScreen) {
      case 2:
        return [
          'Feedback was useful',
          'They were friendly',
          'Everyone was encouraged to speak',
          'Enjoyed the teaching style',
          'Made the lesson fun',
        ];
      case 3:
        return [
          'Feedback was not useful',
          'They were unfriendly',
          'More encouragement to speak',
          'Did not enjoy the teaching style',
          'Made the lesson boring',
        ];

      case 5:
        return [
          'Difficulty level was as expected',
          'Content was useful',
          'Exercises were fun',
          'Good amount of material',
          'Slides were engaging',
        ];
      case 6:
        return [
          'It was too easy',
          'It was too difficult',
          'Content was not useful',
          'Exercises were boring',
          'Not enough material',
          'Slides were boring',
        ];

      case 8:
        return [
          'Class size was just right',
          'Good amount of speaking practice',
          'I could ask all my questions',
          'Class duration was just right',
          'Enough support before class',
          'Enough support after class',
        ];

      case 9:
        return [
          'Smaller group of students',
          'Larger group of students',
          'More speaking practice',
          'Class was too long',
          'Class was too short',
          'Not enough support before class',
          'Not enough support after class',
        ];

      case 10:
        return [
          'Joining the class',
          'Poor audio quality',
          'Poor video quality',
          'Lost connection to the class',
          'Poor visibility of content on my device',
          'Problem in a breakout room',
          'I had a different issue',
          // 'What was the issue?',
          // "I didn't have any issues",
        ];
      default:
        return [];
    }
  };

  const getCurrScreenFeedbackOptions = () => {
    return responsesText();
  };

  const scrollToBottom = () => {
    if (refCompletedBtn.current) {
      refCompletedBtn.current.scrollIntoView({
        // behavior: 'instant',
        block: 'end',
        inline: 'nearest',
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Ref not set or element not found.');
    }
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const feedbackContainerRef = useRef<HTMLDivElement>(null);
  const optionsSectionRef = useRef<HTMLDivElement>(null);

  const handleFocus = () => {
    if (isMobile) {
      setTimeout(() => {
        textareaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // resetKeyboardScroll();
      }, 300); // Allow the keyboard animation to complete
    }
  };

  const handleBlur = () => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      resetKeyboardScroll();
      if (optionsSectionRef.current) {
        optionsSectionRef.current.scrollTo(0, 0);
      }
      if (feedbackContainerRef.current) {
        feedbackContainerRef.current.scrollTo(0, 0);
      }
    }
  };

  useEffect(() => {
    setYellowProgressBarMaxVal(QUESTION_MAX_VAL);
  }, [setYellowProgressBarMaxVal]);

  useEffect(() => {
    if (questionNum <= QUESTION_MAX_VAL) {
      resetKeyboardScroll();
    } else {
      scrollToBottom();
    }
  }, [questionNum, resetKeyboardScroll]);

  useEffect(() => {
    // Report lesson ends
    sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_END, {
      lessonID: sessionId?.toString(),
      lessonStartTimestamp: Math.floor(Date.now() / 1000),
    });
    // Report feedback screen opened
    sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_SCREEN_OPEN, {
      screenID: window.mondlyNative?.screenIds.FEEDBACK,
      lessonID: sessionId?.toString(),
    });
    // Mixpanel metric:
    // mixpanelTrackEvent('Dec2023FeedbackOpen', {
    //   SessionId: sessionId,
    // });
  }, [mixpanelTrackEvent, sessionId]);

  const getSingleFeedbackId = () => {
    switch (questionNum) {
      case 1:
        return 'a';
      case 2:
        return 'b';
      case 3:
        return 'c';
      case 4:
        return 'd';
      default:
        return 'c';
    }
  };

  const updateQuestionRate = (newRate: number, keyNum: number) => {
    setFullFeedback((prevState) => ({
      ...prevState,
      [keyNum]: {
        ...prevState[keyNum],
        rate: newRate,
      },
    }));
  };

  const resetQuestionUserSelection = (keyNum: number) => {
    setFullFeedback((prevState) => ({
      ...prevState,
      [keyNum]: {
        ...prevState[keyNum],
        response: {
          userSelection: [],
          userFreeText: '',
        },
      },
    }));
  };

  const updateMultipleChoice = (newElement: string, keyNum: 1 | 2 | 3 | 4) => {
    setFullFeedback((prevState) => ({
      ...prevState,
      [keyNum]: {
        ...prevState[keyNum],
        response: {
          ...prevState[keyNum].response,
          userSelection: prevState[keyNum].response.userSelection.includes(newElement)
            ? prevState[keyNum].response.userSelection.filter(
                (item) => item !== newElement, // Remove element
              )
            : [...prevState[keyNum].response.userSelection, newElement], // Add element,
        },
      },
    }));
  };

  const handleFreeTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedFreeText = e.target.value;
    setFullFeedback((prevState) => ({
      ...prevState,
      [questionNum]: {
        ...prevState[questionNum],
        response: {
          ...prevState[questionNum].response,
          userFreeText: updatedFreeText,
        },
      },
    }));
  };

  const onResponse = async (val: number) => {
    setTimeout(() => {
      // navigate screen:
      // Handle "next" behavior based on currScreen and val from star selected
      if (currScreen === 1 || currScreen === 4 || currScreen === 7) {
        const nextScreen = val >= 4 ? currScreen + 1 : currScreen + 2;
        setCurrScreen(nextScreen);
      }
    }, 200);
    // update fullResponse:
    updateQuestionRate(val, questionNum);
    resetQuestionUserSelection(questionNum);
    // Mixpanel metric:
    mixpanelTrackEvent('SingleFeedbackSubmit', {
      SessionId: sessionId,
      QuestionId: questionNum,
      rating: val,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
    try {
      if (token && sessionId) {
        singleFeedback(token, sessionId, getSingleFeedbackId(), val).catch((err) => {
          console.log(err);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFeedbackOptionSelect = (option: string) => {
    updateMultipleChoice(option, questionNum);
    resetKeyboardScroll();
  };

  useMemo(() => {
    setYellowProgressBarCurrVal(questionNum);
  }, [questionNum, setYellowProgressBarCurrVal]);

  const getQuestionNumByScreen = (screenNum: number): 1 | 2 | 3 | 4 => {
    if (screenNum === 1 || screenNum === 2 || screenNum === 3) {
      return 1;
    }
    if (screenNum === 4 || screenNum === 5 || screenNum === 6) {
      return 2;
    }
    if (screenNum === 7 || screenNum === 8 || screenNum === 9) {
      return 3;
    }
    return 4;
  };

  const isResponseFilled = (screenNum: number) => {
    const questionNumFromScreen = getQuestionNumByScreen(screenNum);
    // check if response field of question has any response:
    return (
      (fullFeedback[questionNumFromScreen].response.userSelection &&
        fullFeedback[questionNumFromScreen].response.userSelection.length > 0) ||
      (fullFeedback[questionNumFromScreen].response.userFreeText.length &&
        fullFeedback[questionNumFromScreen].response.userFreeText.length > 0)
    );
  };

  const isCurrResponseFilled = (screen: number) => {
    // screen 1:
    if (screen === 1 && fullFeedback[1].rate) {
      return true;
    }
    // screen 2:
    if (screen === 2 && isResponseFilled(2)) {
      return true;
    }
    // screen 3:
    if (screen === 3 && isResponseFilled(3)) {
      return true;
    }
    // screen 4:
    if (screen === 4 && fullFeedback[2].rate) {
      return true;
    }
    // screen 5:
    if (screen === 5 && isResponseFilled(5)) {
      return true;
    }
    // screen 6:
    if (screen === 6 && isResponseFilled(6)) {
      return true;
    }
    // screen 7:
    if (screen === 7 && fullFeedback[3].rate) {
      return true;
    }
    // screen 8:
    if (screen === 8 && isResponseFilled(8)) {
      return true;
    }
    // screen 9:
    if (screen === 9 && isResponseFilled(9)) {
      return true;
    }
    if (screen === 10 && isResponseFilled(10)) {
      return true;
    }
    return false;
  };

  const navigateFeedback = (actionType: string) => {
    setCurrScreen(() => {
      // Guard checks for boundaries
      if (actionType === 'previous' && currScreen === 1) return currScreen;
      if ((actionType === 'next' || actionType === 'skip') && currScreen === 10) return currScreen;

      // Handle each action type
      switch (actionType) {
        case 'previous':
          if (currScreen === 3 || currScreen === 6 || currScreen === 9) {
            return currScreen - 2;
          }
          if (currScreen === 4 || currScreen === 7) {
            const questionNumFromScreen = getQuestionNumByScreen(currScreen);
            const { rate } = fullFeedback[questionNumFromScreen - 1]; // previous should refer to previous question
            return rate && rate >= 4 ? currScreen - 2 : currScreen - 1;
          }
          return currScreen - 1;

        case 'next':
          // Handle "next" behavior based on currScreen and rate values in fullFeedback
          if (currScreen === 1 || currScreen === 4 || currScreen === 7) {
            const questionNumFromScreen = getQuestionNumByScreen(currScreen);
            const { rate } = fullFeedback[questionNumFromScreen];
            return rate && rate >= 4 ? currScreen + 1 : currScreen + 2;
          }
          if (currScreen === 2 || currScreen === 3) {
            return 4;
          }
          if (currScreen === 5 || currScreen === 6) {
            return 7;
          }
          if (currScreen === 8 || currScreen === 9) {
            return 10;
          }
          return currScreen + 1;

        case 'skip':
          // Handle "skip" based on currScreen
          if (currScreen === 1 || currScreen === 4 || currScreen === 7) {
            return currScreen + 3;
          }
          if (currScreen === 2 || currScreen === 3) {
            return 4;
          }
          if (currScreen === 5 || currScreen === 6) {
            return 7;
          }
          if (currScreen === 8 || currScreen === 9) {
            return 10;
          }
          return currScreen + 1;

        default:
          return currScreen;
      }
    });
  };

  const handleNext = async () => {
    // send feedback response:
    try {
      if (token && sessionId) {
        const userResponse: {
          sessionId: number;
          feedbackId: number;
          response: {
            userSelection: string[];
            userFreeText: string;
          };
        } = {
          sessionId: parseInt(sessionId, 10),
          feedbackId: questionNum,
          response: fullFeedback[questionNum].response,
        };
        multipleSelectFeedback(token, userResponse);
      }
    } catch (err) {
      console.log(err);
    }
    // mixpanel:
    mixpanelTrackEvent('QuestionUserResponseFeedback', {
      SessionId: sessionId,
      QuestionId: questionNum,
      rating: fullFeedback[questionNum].response,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
    // navigate next:
    navigateFeedback('next');
    if (isMobile) {
      resetKeyboardScroll();
      if (optionsSectionRef.current) {
        optionsSectionRef.current.scrollTo(0, 0);
      }
      if (feedbackContainerRef.current) {
        feedbackContainerRef.current.scrollTo(0, 0);
      }
    }
  };

  const onSubmit = async () => {
    // send 4th q response
    try {
      if (token && sessionId) {
        const userResponse: {
          sessionId: number;
          feedbackId: number;
          response: {
            userSelection: string[];
            userFreeText: string;
          };
        } = {
          sessionId: parseInt(sessionId, 10),
          feedbackId: 4,
          response: fullFeedback[4].response,
        };
        multipleSelectFeedback(token, userResponse);
      }
    } catch (err) {
      console.log(err);
    }
    // navigate to Thank you screen:
    setIsThankYouScreen(true);

    // mixpanel:
    mixpanelTrackEvent('QuestionUserResponseFeedback', {
      SessionId: sessionId,
      QuestionId: 4,
      rating: fullFeedback[4].response,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
    // reset fullFeedback:
    setFullFeedback(initialFullFeedback);
  };

  useEffect(() => {
    // update questionNum by currScreen:
    setQuestionNum(getQuestionNumByScreen(currScreen));
    if (isMobile) {
      resetKeyboardScroll();
      if (optionsSectionRef.current) {
        optionsSectionRef.current.scrollTo(0, 0);
      }
      if (feedbackContainerRef.current) {
        feedbackContainerRef.current.scrollTo(0, 0);
      }
    }
  }, [currScreen]);

  useEffect(() => {
    mixpanelTrackEvent('PageView', {
      PageName: 'Feedback',
      userId: user?.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
  }, []);

  // useEffect(() => {
  //   // print fullFeedback on update:
  //   console.log('fullFeedback: ', fullFeedback);
  // }, [fullFeedback]);

  return (
    <section className="sequential-feedback-container">
      {!isThankYouScreen ? (
        <div className="question-mode" ref={feedbackContainerRef}>
          <div className="question-content">
            <div className="question-title">
              <p className="feedback-question" dir="auto">
                {questionText()}
              </p>
              {(currScreen === 2 ||
                currScreen === 3 ||
                currScreen === 5 ||
                currScreen === 6 ||
                currScreen === 8 ||
                currScreen === 9) && (
                <p className="subtitle">
                  <FormattedMessage id="feedback.selectAllThatApply" />
                </p>
              )}
            </div>
            {currScreen === 1 || currScreen === 4 || currScreen === 7 ? (
              <div className="rate">
                <FeedbackStarRate
                  key={`rate-btn-${5}`}
                  starVal={5}
                  onResponse={onResponse}
                  lang={lang}
                  fullFeedback={fullFeedback}
                  questionNum={questionNum}
                />
                <FeedbackStarRate
                  starVal={4}
                  onResponse={onResponse}
                  lang={lang}
                  fullFeedback={fullFeedback}
                  questionNum={questionNum}
                />
                <FeedbackStarRate
                  starVal={3}
                  onResponse={onResponse}
                  lang={lang}
                  fullFeedback={fullFeedback}
                  questionNum={questionNum}
                />
                <FeedbackStarRate
                  starVal={2}
                  onResponse={onResponse}
                  lang={lang}
                  fullFeedback={fullFeedback}
                  questionNum={questionNum}
                />
                <FeedbackStarRate
                  starVal={1}
                  onResponse={onResponse}
                  lang={lang}
                  fullFeedback={fullFeedback}
                  questionNum={questionNum}
                />
              </div>
            ) : (
              <div className="rate" ref={optionsSectionRef}>
                {getCurrScreenFeedbackOptions().map((responseTxt) => {
                  return (
                    <FeedbackOption
                      key={responseTxt}
                      lang={lang}
                      isDisabled={fullFeedback[4].response.userSelection.includes(
                        "I didn't have any issues",
                      )}
                      optionText={responseTxt}
                      onFeedbackOptionSelect={onFeedbackOptionSelect}
                      // isSelected={getIsOptionSelected(responseTxt, questionNum)}
                      fullFeedback={fullFeedback}
                      questionNum={questionNum}
                    />
                  );
                })}
                {currScreen !== 10 && (
                  <textarea
                    ref={textareaRef}
                    className="feedback-textarea"
                    name="feedback-textarea"
                    id="feedback"
                    rows={5}
                    value={fullFeedback[questionNum].response.userFreeText} // Bind the state to the textarea
                    onChange={handleFreeTextChange} // Update the state on change
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={
                      currScreen === 2 || currScreen === 5 || currScreen === 8
                        ? intl.formatMessage({ id: 'feedbackOption.anythingElseThatYouLiked' })
                        : intl.formatMessage({ id: 'feedbackOption.anythingElseThatCouldBeBetter' })
                    }
                  />
                )}
                {currScreen === 10 &&
                  fullFeedback[4].response.userSelection.includes('I had a different issue') && (
                    <textarea
                      className="feedback-textarea"
                      name="feedback-textarea"
                      id="feedback"
                      rows={5}
                      value={fullFeedback[questionNum].response.userFreeText} // Bind the state to the textarea
                      onChange={handleFreeTextChange} // Update the state on change
                      placeholder={intl.formatMessage({ id: 'feedbackOption.whatWasTheIssue' })}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  )}
                {currScreen === 10 && (
                  <FeedbackOption
                    key="iDidntHaveAnyIssues"
                    lang={lang}
                    optionText={"I didn't have any issues"}
                    isMarginTop
                    onFeedbackOptionSelect={onFeedbackOptionSelect}
                    isDisabled={fullFeedback[4].response.userSelection.includes(
                      'I had a different issue',
                    )}
                    fullFeedback={fullFeedback}
                    questionNum={4}
                  />
                )}
              </div>
            )}
          </div>
          <div
            className={`nav-btns full flex ${lang === 'ar' ? 'row-reverse' : ''}  justify-end`}
            dir="auto"
          >
            {/* {currScreen !== 1 && (
              <button
                type="button"
                className="secondary-btn smaller-iframe-font fit-content"
                onClick={() => {
                  navigateFeedback('previous');
                }}
              >
                {lang !== 'ar' && (
                  <img className="caret-img" src={caretLeft} alt="caret-left-icon" />
                )}

                <FormattedMessage id="feedback.previous" />
                {lang === 'ar' && (
                  <img className="caret-img" src={caretRight} alt="caret-right-icon" />
                )}
              </button>
            )} */}
            {isCurrResponseFilled(currScreen) ? (
              <button
                type="button"
                className="cta-btn smaller-iframe-font fit-content feedback-nav-pink-btn"
                onClick={currScreen === 10 ? () => onSubmit() : () => handleNext()}
              >
                {lang === 'ar' && (
                  <img className="caret-img" src={caretLeft} alt="caret-left-icon" />
                )}
                {currScreen === 10 ? (
                  <FormattedMessage id="feedback.submit" />
                ) : (
                  <FormattedMessage id="feedback.next" />
                )}
                {lang !== 'ar' && (
                  <img className="caret-img" src={caretRight} alt="caret-right-icon" />
                )}
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn smaller-iframe-font fit-content"
                onClick={
                  currScreen === 10
                    ? () => setIsThankYouScreen(true)
                    : () => navigateFeedback('skip')
                }
              >
                <p className="skip-btn-content">
                  <FormattedMessage id="feedback.skip" />
                </p>
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="complete-mode flex column full" dir={getLangDir(lang)}>
          <div className="text">
            <p className="title">
              <FormattedMessage id="common.thankYou" />!
            </p>
            <p className="txt">
              <FormattedMessage id="feedback.weValueYourFeedback" />
            </p>
          </div>
          <button
            type="button"
            className="cta-btn"
            onClick={() => {
              if (setIsFeedbackScreen) {
                setIsFeedbackScreen(false);
              }
              navigate('/');
            }}
            ref={refCompletedBtn}
          >
            <FormattedMessage id="common.close" />
          </button>
        </div>
      )}
    </section>
  );
}
export default Feedback;
