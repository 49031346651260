import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { useUserContext } from '../../Providers/UserContext';
import { areLevelArraysEqual } from '../../helpers/utils';
import LevelFilter from './LevelFilter';
import TimeFilter from './TimeFilter';

function Filters() {
  const { filterQuery, updateFilterQuery, updateIsFiltersDrawerOpen } = useSessionsContext();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();
  const [levelQuery, setLevelQuery] = useState(filterQuery.filterLevel || []);
  const [dateQuery, setDateQuery] = useState(filterQuery.filterDate || '');
  const [timeQuery, setTimeQuery] = useState(filterQuery.filterTime || []);
  const isCalendarMobileOpen = false;
  const isApplyBtnDisabled =
    areLevelArraysEqual(levelQuery, filterQuery.filterLevel) &&
    areLevelArraysEqual(timeQuery, filterQuery.filterTime);
  const isInitialMount = useRef(true);
  const isResetBtnDisabled =
    levelQuery && levelQuery.length === 0 && timeQuery && timeQuery.length === 0;
  const filtersContainerRef = useRef<HTMLDivElement>(null);
  const [startY, setStartY] = useState(null);

  // detect and close on swipe down:
  const handleTouchStart = (e: any) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e: any) => {
    if (startY && e.changedTouches[0].clientY - startY > 150) {
      // close drawer:
      updateIsFiltersDrawerOpen(false);
    }

    setStartY(null);
  };

  useEffect(() => {
    const swipeEl = filtersContainerRef.current;
    swipeEl!.addEventListener('touchstart', handleTouchStart);
    swipeEl!.addEventListener('touchend', handleTouchEnd);
    return () => {
      swipeEl!.removeEventListener('touchstart', handleTouchStart);
      swipeEl!.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startY]);

  // pull values from store on mount:
  useEffect(() => {
    if (isInitialMount.current) {
      if (filterQuery.filterLevel && filterQuery.filterDate) {
        setLevelQuery(filterQuery.filterLevel);
        setDateQuery(filterQuery.filterDate);
        setTimeQuery(filterQuery.filterTime);
      }
      isInitialMount.current = false;
    }
  }, [filterQuery.filterDate, filterQuery.filterLevel, filterQuery.filterTime]);

  const onApplyFilters = () => {
    updateFilterQuery({
      filterLevel: levelQuery,
      filterDate: dateQuery,
      filterTime: timeQuery || filterQuery.filterTime,
    });
    updateIsFiltersDrawerOpen(false);

    mixpanelTrackEvent('FiltersApplied', {
      filterQuery: {
        level: levelQuery,
        date: dateQuery,
        time: timeQuery,
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      },
    });
  };

  const onResetFilters = () => {
    setLevelQuery([]);
    setTimeQuery([]);
    setDateQuery('');
  };

  return (
    <section className="filter" ref={filtersContainerRef} dir="auto">
      <div className="puller" />
      <div className="filters-content">
        <h1>
          <FormattedMessage id="filter.filter" />
        </h1>

        <div className="filters">
          <LevelFilter levelQuery={levelQuery} setLevelQuery={setLevelQuery} />
          <TimeFilter timeQuery={timeQuery} setTimeQuery={setTimeQuery} />
        </div>
        <div className="filters-btns">
          <button
            type="button"
            className={`cta-btn filter-cta reset-filters ${isResetBtnDisabled ? `disabled` : ``} ${
              isCalendarMobileOpen ? 'no-margin-top' : 'with-margin-top'
            }`}
            onClick={onResetFilters}
          >
            <FormattedMessage id="filter.resetFilters" />
          </button>
          <button
            type="button"
            className={`cta-btn filter-cta ${isApplyBtnDisabled ? `disabled` : ``} ${
              isCalendarMobileOpen ? 'no-margin-top' : 'with-margin-top'
            }`}
            onClick={onApplyFilters}
          >
            <p>
              <FormattedMessage id="filter.applyFilters" />
            </p>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Filters;
