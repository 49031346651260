import { useState, useEffect, useRef, KeyboardEvent, TouchEvent, ReactElement } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/ar';
import 'dayjs/locale/tr';
import 'dayjs/locale/ko';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh';
import 'dayjs/locale/vi';
import 'dayjs/locale/id';
import { FormattedMessage } from 'react-intl';
import caretRight from '../../assets/image/caret-right-24.png';
import caretLeft from '../../assets/image/caret-left-24.png';
import { ReactComponent as FiltersIcon } from '../../assets/image/filters-icon.svg';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useDeviceContext } from '../../Providers/DeviceContext';
import { getFormattedLangForLocale } from '../../helpers/utils';

interface DatePickerBarProps {
  lang: string;
  currentDate: dayjs.Dayjs;
  setCurrentDate: (date: dayjs.Dayjs) => void;
  exclusionDates: string[];
  nonTeachingDates: string[];
  isSticky?: boolean;
  onOpenFilters: () => void;
}

function DatePickerBar({
  lang,
  exclusionDates,
  nonTeachingDates,
  currentDate,
  setCurrentDate,
  isSticky = false,
  onOpenFilters,
}: DatePickerBarProps) {
  const language = useRef(getFormattedLangForLocale(lang));
  const [isNextArrowDisabled, setIsNextArrowDisabled] = useState<boolean>(false);
  const [isPrevArrowDisabled, setIsPrevArrowDisabled] = useState<boolean>(true);
  const [visibleMonths, setVisibleMonths] = useState<ReactElement | null>(null);

  const datePickerRef = useRef<HTMLDivElement>(null);
  const { sessions, filterQuery } = useSessionsContext();
  const { platform } = useDeviceContext();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const todayBtnRef = useRef<HTMLDivElement>(null);
  const startOfWeekDay = 1;

  const getNextMonday = (today: dayjs.Dayjs) => {
    const dayOfWeek = today.day();
    // TODO: activate when startOfWeekDay is dynamic:
    // if (startOfWeekDay === 0) {
    //   // Calculate days until next Sunday
    //   const daysUntilNextSunday = (7 - dayOfWeek) % 7 || 7;
    //   return today.add(daysUntilNextSunday, 'day');
    // } else {
    // Calculate days until next Monday
    const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
    return today.add(daysUntilNextMonday, 'day');
    // }
  };

  function getPreviousMonday(date: dayjs.Dayjs) {
    const today = dayjs(date);
    const dayOfWeek = today.day();
    if (dayOfWeek === startOfWeekDay) {
      return today.subtract(7, 'day');
    }
    const daysSinceLastMonday = (dayOfWeek + 6) % 7;
    return today.subtract(daysSinceLastMonday, 'day');
  }

  const getVisibleDates = () => {
    const start = dayjs();
    // for mobile/tablet:
    if (platform !== 'web') {
      const dates = Array.from({ length: 15 }, (_, i) => start.add(i, 'day'));
      return dates;
    }
    const addDayNumber = () => {
      const dayNumber = dayjs().day();
      if (dayNumber === 0) {
        return 1;
      }
      if (dayNumber === 1) {
        return 0;
      }
      return 8 - dayNumber;
    };
    const schedulingWindowDays = 14 + addDayNumber();
    const dates = Array.from({ length: schedulingWindowDays }, (_, i) => start.add(i, 'day'));
    return dates;
  };
  const [visibleDates, setVisibleDates] = useState<dayjs.Dayjs[]>(getVisibleDates());

  const generateVisibleDates = () => {
    const start = dayjs();
    // for mobile/tablet:
    if (platform !== 'web') {
      const dates = Array.from({ length: 15 }, (_, i) => start.add(i, 'day'));
      setVisibleDates(dates);
    } else {
      const addDayNumber = () => {
        const dayNumber = dayjs().day();
        if (dayNumber === 0) {
          return 1;
        }
        if (dayNumber === 1) {
          return 0;
        }
        return 8 - dayNumber;
      };
      const schedulingWindowDays = 14 + addDayNumber();
      const dates = Array.from({ length: schedulingWindowDays }, (_, i) => start.add(i, 'day'));
      setVisibleDates(dates);
    }
  };

  const getVisibleMonths = () => {
    const startOfCurrWeek = getPreviousMonday(currentDate);

    if (platform === 'web' && visibleDates && visibleDates.length > 0) {
      const visibleWeekMonthStart =
        currentDate.day() === startOfWeekDay ? currentDate.month() : startOfCurrWeek.month();
      // if it's a startOfWeekDay
      if (currentDate.day() === startOfWeekDay) {
        const endOfWeekMonth = currentDate.add(6, 'day').month();
        if (visibleWeekMonthStart === endOfWeekMonth) {
          setVisibleMonths(
            <FormattedMessage id={`common.${currentDate.format('MMMM').toLowerCase()}`} />,
          );
        } else {
          setVisibleMonths(
            <>
              <FormattedMessage id={`common.${currentDate.format('MMMM').toLowerCase()}`} /> ·{' '}
              <FormattedMessage
                id={`common.${currentDate.add(6, 'day').format('MMMM').toLowerCase()}`}
              />
            </>,
          );
        }
      } else {
        const endOfWeekMonth = startOfCurrWeek.add(6, 'day').month();
        if (visibleWeekMonthStart === endOfWeekMonth) {
          setVisibleMonths(
            <FormattedMessage id={`common.${currentDate.format('MMMM').toLowerCase()}`} />,
          );
        } else {
          setVisibleMonths(
            <>
              <FormattedMessage id={`common.${currentDate.format('MMMM').toLowerCase()}`} /> ·{' '}
              <FormattedMessage
                id={`common.${currentDate.add(6, 'day').format('MMMM').toLowerCase()}`}
              />
            </>,
          );
        }
      }
    } else {
      // mobile:
      const visibleMonthMobile = visibleDates
        .map((item) => item.format('MMMM'))
        .reduce<string[]>((acc, month) => {
          if (!acc.includes(month)) {
            acc.push(month);
          }
          return acc;
        }, []);
      if (visibleMonthMobile.length > 1) {
        setVisibleMonths(
          <>
            <FormattedMessage id={`common.${visibleMonthMobile[0].toLowerCase()}`} /> ·{' '}
            <FormattedMessage id={`common.${visibleMonthMobile[1].toLowerCase()}`} />
          </>,
        );
      } else {
        setVisibleMonths(
          <FormattedMessage id={`common.${currentDate.format('MMMM').toLowerCase()}`} />,
        );
      }
    }
  };

  useEffect(() => {
    generateVisibleDates();
  }, []);

  useEffect(() => {
    getVisibleMonths();
  }, [currentDate, visibleDates]);

  const getIsNextArrowDisabled = (): boolean => {
    if (platform === 'web') {
      return currentDate.isAfter(visibleDates[visibleDates.length - 8], 'day');
    }
    // mobile:
    return (
      currentDate.isAfter(visibleDates[visibleDates.length - 1], 'day') ||
      (scrollableRef.current &&
        60 * visibleDates.length <= 60 * 6 + scrollableRef.current.scrollLeft) ||
      false
    );
  };

  const getIsPrevArrowDisabled = (): boolean => {
    if (scrollableRef.current) {
      if (platform !== 'web') {
        return scrollableRef.current.scrollLeft < 60;
      }
    }
    return currentDate.isSame(dayjs(), 'day');
  };

  const handleArrowsStatus = () => {
    const getIsNextArrowDisabledRes = getIsNextArrowDisabled();
    setIsNextArrowDisabled(getIsNextArrowDisabledRes);
    const getIsPrevArrowDisabledRes = getIsPrevArrowDisabled();
    setIsPrevArrowDisabled(getIsPrevArrowDisabledRes);
  };

  const scrollByItemIndex = (scrollPosition: string) => {
    if (scrollableRef.current) {
      const { scrollWidth, clientWidth } = scrollableRef.current;
      const middlePosition = (scrollWidth - clientWidth) / 2;
      const endPosition = scrollWidth;
      if (scrollPosition === 'start') {
        scrollableRef.current.scrollTo({
          left: 0,
        });
      }
      if (scrollPosition === 'middle') {
        scrollableRef.current.scrollTo({
          left: scrollPosition === 'middle' ? middlePosition : endPosition,
        });
      }
      if (scrollPosition === 'end') {
        scrollableRef.current.scrollTo({
          left: scrollWidth,
        });
      }
    }
  };

  const getCurrStartWeekDate = (date: dayjs.Dayjs) => {
    const prevMonday = getPreviousMonday(date);
    if (date.day() === startOfWeekDay || prevMonday.isBefore(visibleDates[0], 'day')) {
      return date;
    }
    return prevMonday;
  };

  const scrollByItems = (itemsNumber: number, smooth: boolean = false) => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        left: itemsNumber * 76,
        behavior: smooth ? 'smooth' : 'auto',
      });
    }
  };

  useEffect(() => {
    // mobile: 15 days=> 3 views of 5 days (start/middle/end)
    const currentDayIdx = visibleDates.findIndex((day) => day.isSame(currentDate, 'day'));
    if (platform !== 'web') {
      if (currentDayIdx < 5) {
        scrollByItemIndex('start');
      }
      if (currentDayIdx >= 5 && currentDayIdx < 10) {
        scrollByItemIndex('middle');
      }
      if (currentDayIdx >= 10) {
        scrollByItemIndex('end');
      }
    } else {
      const currDateStartWeekDate = getCurrStartWeekDate(currentDate);
      const prevMondayIdx = visibleDates.findIndex((day) =>
        day.isSame(currDateStartWeekDate, 'day'),
      );
      if (prevMondayIdx >= 0) {
        scrollByItems(prevMondayIdx);
      }
    }
    handleArrowsStatus();
  }, [isSticky]);

  const handlePrev = () => {
    if (!visibleDates[0]?.isSame(currentDate) && platform !== 'web') {
      // mobile:
      if (scrollableRef.current) {
        scrollableRef.current.scrollTo({
          left: scrollableRef.current.scrollLeft - 60,
          behavior: 'smooth',
        });
      }
    } else {
      // desktop:
      const prevMonday = getPreviousMonday(
        currentDate.day() === startOfWeekDay ? currentDate : currentDate.subtract(7, 'day'),
      );
      if (prevMonday.isBefore(dayjs(), 'day')) {
        scrollByItemIndex('start');
        setCurrentDate(visibleDates[0]);
      } else {
        const prevMondayIdx = visibleDates.findIndex((day) => day.isSame(prevMonday, 'day'));
        if (prevMondayIdx >= 0 && prevMondayIdx < visibleDates.length - 7) {
          scrollByItems(prevMondayIdx);
        }
        setCurrentDate(prevMonday);
      }
    }
    handleArrowsStatus();
  };

  const handleNext = () => {
    if (!visibleDates[visibleDates.length - 1]?.isSame(currentDate) && platform !== 'web') {
      // mobile:
      if (scrollableRef.current) {
        scrollableRef.current.scrollTo({
          left: scrollableRef.current.scrollLeft + 60,
          behavior: 'smooth',
        });
      }
    } else {
      // desktop:
      let nextMonday = dayjs();
      if (
        // case: first day isn't startOfWeekDay AND currentDate is before first Monday:
        visibleDates[0].day() !== startOfWeekDay &&
        currentDate.isBefore(getNextMonday(visibleDates[0]), 'day')
      ) {
        nextMonday = getNextMonday(visibleDates[0]); // first visible Monday
      } else {
        // case: first day is startOfWeekDay OR currentDate is after first Monday:
        nextMonday = getNextMonday(currentDate);
      }
      if (nextMonday.isBefore(visibleDates[visibleDates.length - 1], 'day')) {
        if (!nextMonday.isAfter(visibleDates[visibleDates.length - 1], 'day')) {
          const nextMondayIdx = visibleDates.findIndex((day) => day.isSame(nextMonday, 'day'));
          if (nextMondayIdx) {
            scrollByItems(nextMondayIdx);
          }
        }
        setCurrentDate(nextMonday);
      }
    }
    handleArrowsStatus();
  };

  const isDateNoSessions = (date: string) => {
    // return true if more than 13 days from today (including):
    if (dayjs(date).isAfter(dayjs().add(13, 'day'))) {
      return true;
    }
    return sessions
      ? !sessions
          .filter((el) => !el.isEnroll)
          .some((el) => new Date(el.date).toDateString() === new Date(date).toDateString())
      : true;
  };

  const isDisabled = (date: string): boolean => {
    if (
      exclusionDates.some((d) => dayjs(d).isSame(date, 'day')) ||
      nonTeachingDates.some((d) => dayjs(d).isSame(date, 'day')) ||
      isDateNoSessions(date)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    handleArrowsStatus();
  }, [currentDate, scrollableRef?.current?.scrollLeft]);

  const handleInteraction = (
    e: KeyboardEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>,
    date: dayjs.Dayjs,
  ) => {
    if (e.type === 'keydown' && (e as KeyboardEvent<HTMLDivElement>).key === 'Enter') {
      e.preventDefault(); // Prevent default behavior for Enter key
      setCurrentDate(date);
    } else if (e.type === 'touchend') {
      setCurrentDate(date);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>, date: dayjs.Dayjs) =>
    handleInteraction(e, date);
  const onTouchEnd = (e: TouchEvent<HTMLDivElement>, date: dayjs.Dayjs) => {
    handleInteraction(e, date);
  };

  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);

  useEffect(() => {
    if (filterQuery.filterLevel && filterQuery.filterTime) {
      const updatedCount = filterQuery.filterLevel.length + filterQuery.filterTime.length;
      setActiveFiltersCount(updatedCount);
    }
  }, [filterQuery.filterLevel.length, filterQuery.filterTime.length]);

  return (
    <section
      className={`date-picker-bar-section flex column ${isSticky ? 'isSticky' : ''}`}
      // dir={lang === 'ar' ? 'rtl' : 'ltr'}
    >
      <div className="months-filters-row flex space-between align-center">
        <div className="month-p">{visibleMonths}</div>
        <button
          type="button"
          className="filters flex align-center gap-4"
          onClick={() => onOpenFilters()}
        >
          <span className="filters-icon">
            <FiltersIcon style={{ height: '100%', width: '100%' }} />
          </span>
          <p>
            <FormattedMessage id="filter.filter" />
          </p>
          {activeFiltersCount > 0 && <div className="filters-indicator">{activeFiltersCount}</div>}
        </button>
      </div>
      <div className="date-picker-slider" ref={datePickerRef}>
        <button
          type="button"
          style={{ padding: '5px' }}
          onClick={handlePrev}
          disabled={isPrevArrowDisabled}
        >
          <img
            className={`${isPrevArrowDisabled ? 'caret-img disable' : 'caret-img'}`}
            src={caretLeft}
            alt="caret-left-icon"
          />
        </button>
        <div className={`dates ${platform === 'web' ? 'web' : ''}`} ref={scrollableRef}>
          {visibleDates.map((date) => (
            <div
              key={`${date.format('YYYY-MM-DD')} ${isSticky ? 'isSticky' : ''}`}
              className={`date flex column align-center justify-center ${
                isDisabled(date.format('YYYY-MM-DD')) ? 'disabled' : ''
              } ${date.isSame(currentDate, 'day') ? 'selected' : ''}`}
              onClick={() => setCurrentDate(date)}
              onKeyDown={(e) => onKeyDown(e, date)}
              onTouchEnd={(e) => onTouchEnd(e, date)}
              role="button" // Indicate that this element acts like a button
              tabIndex={0} // Make the element focusable
            >
              {date.isSame(dayjs(), 'day') ? (
                <>
                  <p
                    ref={todayBtnRef}
                    className={`today-btn ${
                      (todayBtnRef.current && todayBtnRef.current?.innerText.length > 7) ||
                      lang === 'vi'
                        ? 'smaller-font'
                        : ''
                    }`}
                  >
                    <FormattedMessage id="common.today" />
                  </p>
                  <p>{date.locale(language.current).format('D')}</p>
                </>
              ) : (
                <>
                  <p>{date.locale(language.current).format('ddd')}</p>
                  <p>{date.locale(language.current).format('D')}</p>
                </>
              )}
            </div>
          ))}
        </div>
        <button
          type="button"
          style={{ padding: '5px' }}
          onClick={handleNext}
          disabled={isNextArrowDisabled}
        >
          {' '}
          <img
            className={`${isNextArrowDisabled ? 'caret-img disable' : 'caret-img'}`}
            src={caretRight}
            alt="caret-right-icon"
          />
        </button>
      </div>
    </section>
  );
}

export default DatePickerBar;
