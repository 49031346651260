// import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import FilterCheckbox from './FilterCheckbox';
import { ReactComponent as ClockIcon } from '../../assets/image/clock-icon.svg';

interface TimeFilterProps {
  timeQuery: number[];
  setTimeQuery: (value: number[]) => void;
}

function TimeFilter({ timeQuery, setTimeQuery }: TimeFilterProps) {
  const updateLevelSelection = (val: number) => {
    if (timeQuery.includes(val)) {
      const updatedSelection = timeQuery.filter((level) => level !== val);
      setTimeQuery(updatedSelection);
    } else {
      const updatedSelection = [...timeQuery, val];
      setTimeQuery(updatedSelection);
    }
  };

  // useMemo(() => {
  //   setTimeQuery(timeQuery);
  // }, [timeQuery]);

  return (
    <section className="levels-filter">
      <div className="difficulty-level flex gap-4 align-center">
        <ClockIcon className="level-icon" />
        <h1>
          <FormattedMessage id="filter.classTime" />
        </h1>
      </div>
      <div className="levels-filter-form">
        <div className="levels-filter-group">
          <button
            type="button"
            className="level-filter border-bottom"
            onClick={() => updateLevelSelection(1)}
          >
            <div className="level-filter-name">
              {' '}
              <span>
                <FormattedMessage id="filter.earlyMorning" />
              </span>
              <span className="level-cefr">&nbsp;&bull; 00:00 - 05:59</span>
            </div>
            <FilterCheckbox selected={timeQuery.includes(1)} />
          </button>
          <button
            type="button"
            className="level-filter border-bottom"
            onClick={() => updateLevelSelection(2)}
          >
            <div className="level-filter-name">
              {' '}
              <span>
                <FormattedMessage id="filter.morning" />
              </span>
              <span className="level-cefr">&nbsp;&bull; 06:00 - 11:59</span>
            </div>
            <FilterCheckbox selected={timeQuery.includes(2)} />
          </button>
          <button
            type="button"
            className="level-filter border-bottom"
            onClick={() => updateLevelSelection(3)}
          >
            <div className="level-filter-name">
              {' '}
              <span>
                <FormattedMessage id="filter.afternoon" />
              </span>
              <span className="level-cefr">&nbsp;&bull; 12:00 - 17:59</span>
            </div>
            <FilterCheckbox selected={timeQuery.includes(3)} />
          </button>
          <button type="button" className="level-filter" onClick={() => updateLevelSelection(4)}>
            <div className="level-filter-name">
              {' '}
              <span>
                <FormattedMessage id="filter.evening" />
              </span>
              <span className="level-cefr">&nbsp;&bull; 18:00 - 23:59</span>
            </div>
            <FilterCheckbox selected={timeQuery.includes(4)} />
          </button>
        </div>
      </div>
    </section>
  );
}

export default TimeFilter;
