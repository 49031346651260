import { useEffect, useState } from 'react';
import redCheckCircle from '../../assets/image/red-check-circle.png';
import greenCheckCircle from '../../assets/image/green-check-circle.png';

interface BottomConfirmationPopupProps {
  confirmationType: string;
  msgTxt: string;
  msgSubHTxt?: string;
}

function BottomConfirmationPopup({
  confirmationType,
  msgTxt,
  msgSubHTxt,
}: BottomConfirmationPopupProps) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (confirmationType) {
      setTimeout(() => {
        setActive(true);
      }, 1);
      setTimeout(() => {
        setActive(true);
        setActive(false);
      }, 3001);
    }
  }, [confirmationType]);

  return (
    <section className={`bottom-confirmation-popup ${active ? 'active' : ''}`} dir="auto">
      <div className="bottom-confirmation-popup-content">
        <img
          className="popup-icon"
          src={confirmationType === 'booked' ? greenCheckCircle : redCheckCircle}
          alt=""
        />
        <div className="msg-container">
          <p className={`popup-msg ${msgSubHTxt ? 'bold' : ''}`}>{msgTxt}</p>
          <p className="popup-msg">{msgSubHTxt}</p>
        </div>
      </div>
    </section>
  );
}

export default BottomConfirmationPopup;
