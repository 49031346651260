import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import checkImage from '../../assets/image/check-circle-icon-24-filled.png';
import { UserWeeklyStatus } from '../../helpers/types';

interface WeeklyLimitBannerProps {
  lang: string;
  currentDate: dayjs.Dayjs;
  weeklyLimitRes: { groupWeeklyLimit: number; userWeeklyStatus: UserWeeklyStatus };
}

const getCurrWeekStartDate = (date: dayjs.Dayjs) => {
  const dayOfWeek = date.day(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  // If it's Monday, return the date itself
  if (dayOfWeek === 1) {
    return date;
  }
  // Otherwise, subtract the necessary number of days to get to the previous Monday
  return date.subtract(dayOfWeek === 0 ? 6 : dayOfWeek - 1, 'day');
};

function WeeklyLimitBanner({ lang, currentDate, weeklyLimitRes }: WeeklyLimitBannerProps) {
  const intl = useIntl();

  function getCurrWeekStatus(date: dayjs.Dayjs) {
    const formattedDate = date.format('YYYY-MM-DD');
    const weekStartDate = getCurrWeekStartDate(date).format('YYYY-MM-DD');
    if (
      weeklyLimitRes &&
      weeklyLimitRes.userWeeklyStatus &&
      weeklyLimitRes.userWeeklyStatus[weekStartDate] &&
      formattedDate in weeklyLimitRes.userWeeklyStatus
    ) {
      return weeklyLimitRes.userWeeklyStatus[formattedDate];
    }
    return { attended: 0, booked: 0 };
  }

  const [startOfCurrWeekDate, setStartOfCurrWeekDate] = useState<dayjs.Dayjs>(
    getCurrWeekStartDate(currentDate),
  );

  const [currWeekstatus, setCurrWeekstatus] = useState(getCurrWeekStatus(startOfCurrWeekDate));

  useEffect(() => {
    setStartOfCurrWeekDate(getCurrWeekStartDate(currentDate));
    setCurrWeekstatus(getCurrWeekStatus(startOfCurrWeekDate));
  }, [currentDate, weeklyLimitRes]);

  useEffect(() => {
    setCurrWeekstatus(getCurrWeekStatus(startOfCurrWeekDate));
  }, [startOfCurrWeekDate]);

  const getIsDifferentMonths = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    return start.month() !== end.month();
  };

  const currWeekDates = (
    <span className="week-dates">
      {`(${startOfCurrWeekDate.format('D')}
      ${
        getIsDifferentMonths(startOfCurrWeekDate, startOfCurrWeekDate.add(6, 'day'))
          ? intl
              .formatMessage({
                id: `common.${dayjs(startOfCurrWeekDate).format('MMMM').toLowerCase()}`,
              })
              .slice(0, 3)
          : ''
      }
      - ${startOfCurrWeekDate.add(6, 'day').format('D')}
      ${intl
        .formatMessage({
          id: `common.${dayjs(startOfCurrWeekDate.add(6, 'day')).format('MMMM').toLowerCase()}`,
        })
        .slice(0, 3)})`}
      {/* ${startOfCurrWeekDate.add(6, 'day').format('MMM')})`} */}
    </span>
  );

  return (
    <section className="weekly-limit-banner-section" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <img className="checkImage" src={checkImage} alt="check-icon" />

      {lang !== 'ko' && lang !== 'tr' && (
        <p className="banner-text flex wrap justify-center">
          {lang === 'ar' && ' تم حضور'}
          {lang === 'de' && 'An '}
          {lang === 'es' && 'Esta semana has atendido a '}
          {lang === 'fr' && 'Cette semaine, vous avez suivi '}
          {lang === 'ja' && '今週'}
          {lang === 'ro' && 'Ai participat la '}
          {currWeekstatus.attended} <FormattedMessage id="weeklyLimit.of" />{' '}
          {weeklyLimitRes.groupWeeklyLimit}{' '}
          <FormattedMessage id="weeklyLimit.classesAttendedThisWeek" />
          &nbsp;
          {currWeekDates}
        </p>
      )}

      {(lang === 'ko' || lang === 'tr') && (
        <p className="banner-text flex wrap justify-center">
          {lang === 'ko' && '이번 주 수업'}
          {lang === 'tr' && 'Bu hafta katıldığınız'}&nbsp;{weeklyLimitRes.groupWeeklyLimit}
          <FormattedMessage id="weeklyLimit.of" /> {currWeekstatus.attended}
          <FormattedMessage id="weeklyLimit.classesAttendedThisWeek" />
          &nbsp;{currWeekDates}
        </p>
      )}
    </section>
  );
}

export default WeeklyLimitBanner;
