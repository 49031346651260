import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useUserContext } from '../../Providers/UserContext';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useModalsContext } from '../../Providers/ModalsContext';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { User } from '../../helpers/types';
import closeImage from '../../assets/image/close-modal-icon.png';
import { ReactComponent as StartingSoon } from '../../assets/image/starting-soon.svg';
import { ReactComponent as StartingSoonBeta } from '../../assets/image/starting-soon-beta.svg';
import { checkIfMeetingStarted, joinNewSession } from '../../helpers/Network';
import { sendEventToMondly } from '../../helpers/Event';
import {
  differenceInMinutes,
  getIsUserLiveTutoring,
  getLangTimeFormat,
  getMinSymbolByLang,
  saveToLocalStorageWithExpiry,
} from '../../helpers/utils';
import TxtNotificationModal from './TxtNotificationModal';

interface PreClassScreenProps {
  user: User;
  onShowBottomPopup: (a: string, b: string) => void;
  usersBlockList: string[];
  deviceId: string;
  lang: string;
  platform: string;
}
function PreClassScreen({
  user,
  onShowBottomPopup,
  usersBlockList,
  deviceId,
  lang,
  platform,
}: PreClassScreenProps) {
  const { updateModalContent, updateStrictModalContent } = useModalsContext();
  const { currentSession, updateCurrentSession } = useSessionsContext();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { testWeb, hash } = params;
  const is12hFormat = getLangTimeFormat(lang);
  const [timeTillLessonInMinutes, setTimeTillLessonInMinutes] = useState(
    currentSession?.date
      ? Math.floor(differenceInMinutes(new Date(currentSession?.date), Date.now()))
      : -1,
  );

  const getModalHeaderImgByUser = () => {
    return user && !getIsUserLiveTutoring(user.roles) ? <StartingSoonBeta /> : <StartingSoon />;
  };

  const [isMeetingStarted, setIsMeetingStarted] = useState(false);
  const { token } = useUserContext();
  const clickCount = useRef(0);
  const navigate = useNavigate();
  const intl = useIntl();
  const popupTimer = useRef<NodeJS.Timeout>();

  const sessionOpenInNewTabModalContent = useMemo(() => {
    return (
      <div className="pre-class no-action-msg-screen">
        <div className="pre-class-header">
          <button
            type="submit"
            className="header-btn-close"
            onClick={() => {
              clearTimeout(popupTimer.current);
              updateModalContent(undefined);
              updateStrictModalContent(undefined);
            }}
          >
            <img src={closeImage} alt="" />
          </button>
        </div>
        <TxtNotificationModal />
      </div>
    );
  }, [updateModalContent, updateStrictModalContent]);

  const redirectToSession = useCallback(
    (cSessionUrl: string) => {
      sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_START, {
        lessonID: currentSession?.id.toString(),
        lessonStartTimestamp: Math.floor(Date.now() / 1000),
      });

      // Mixpanel metric:
      mixpanelTrackEvent('SessionStart', {
        SessionId: currentSession?.id,
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      });

      // Save user join in sessionStorage
      if (currentSession?.id) {
        saveToLocalStorageWithExpiry('isJoin', currentSession.id, 60); // Expires in 60 minutes
      }

      // Open temp modal for 45 sec
      if (platform === 'web') {
        updateModalContent(undefined);
        updateStrictModalContent(sessionOpenInNewTabModalContent);
        window.open(cSessionUrl, '_blank');
        // Timer to navigate to Feedback:
        popupTimer.current = setTimeout(() => {
          updateStrictModalContent(undefined);
          navigate(`/sessionend/${currentSession?.id}`);
        }, 45 * 1000);
      } else if (testWeb || hash) {
        window.location.replace(cSessionUrl);
      } else {
        // Open the session in web browser
        window.mondlyNative?.startLiveSession(cSessionUrl).catch((err) => console.log(err));
      }
    },
    [currentSession?.id, user?.id],
  );

  const joinSessionUrl = useCallback(
    async (force = false) => {
      if (token && currentSession) {
        try {
          const sessionLink = await joinNewSession(
            currentSession.id,
            token,
            user.name,
            platform,
            force,
          );
          if (sessionLink) {
            // Check if the user is in the blockList
            if (
              sessionLink &&
              [deviceId, user?.id].some((val) => val && !usersBlockList.includes(val))
            ) {
              setTimeout(() => {
                mixpanelTrackEvent('RedirectToSession', {
                  SessionId: currentSession.id,
                  companyId: user?.b2b.companyId,
                  groupId: user?.b2b.groupId,
                  groupName: user?.b2b.groupName,
                  companyName: user?.b2b.companyName,
                  userRoles: user?.roles,
                });
                redirectToSession(sessionLink);
              }, 200);
            } else {
              onShowBottomPopup(
                'error',
                intl.formatMessage({
                  id: 'bottomConfirmationPopup.ThereWasAnErrorLoadingYourSession',
                }),
              );
            }
          }
        } catch (err) {
          console.log(err);
          updateCurrentSession(null);
          // Mixpanel metric:
          mixpanelTrackEvent('SessionStartError', {
            SessionId: currentSession?.id,
            companyId: user?.b2b.companyId,
            groupId: user?.b2b.groupId,
            groupName: user?.b2b.groupName,
            companyName: user?.b2b.companyName,
            userRoles: user?.roles,
            userId: user?.id,
          });
        }
      }
    },
    [
      currentSession,
      deviceId,
      intl,
      onShowBottomPopup,
      platform,
      redirectToSession,
      token,
      updateCurrentSession,
      user?.id,
      user.name,
      usersBlockList,
    ],
  );

  const handleClickOnCard = () => {
    if (process.env.REACT_APP_CONTENT_ENV === 'prod') {
      if (user?.id) {
        if (user.id === '116045799' || user.id === '116046062') {
          const newValue = clickCount.current + 1;
          clickCount.current = newValue;
          if (clickCount.current === 10) {
            clickCount.current = 0;
            // Mixpanel metric:
            mixpanelTrackEvent('ForceSessionStarts', {
              SessionId: currentSession?.id,
              UserId: user?.id,
              companyId: user?.b2b.companyId,
              groupId: user?.b2b.groupId,
              groupName: user?.b2b.groupName,
              companyName: user?.b2b.companyName,
              userRoles: user?.roles,
            });
            joinSessionUrl(true);
          }
        }
      }
    } else {
      const newValue = clickCount.current + 1;
      clickCount.current = newValue;
      if (clickCount.current === 10) {
        clickCount.current = 0;
        joinSessionUrl(true);
      }
    }
  };

  useEffect(() => {
    // Report preclass screen open
    sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_SCREEN_OPEN, {
      screenID: window.mondlyNative?.screenIds.WAITING,
      lessonID: currentSession?.id.toString(),
    });
    mixpanelTrackEvent('PageView', {
      PageName: 'Pre-class screen',
      sessionId: currentSession?.id,
      userId: user?.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
  }, [currentSession?.id]);

  useEffect(() => {
    if (currentSession) {
      checkIfMeetingStarted(currentSession?.id).then(setIsMeetingStarted);
      const isMeetingStartedInterval = setInterval(async () => {
        const started = await checkIfMeetingStarted(currentSession?.id);
        setIsMeetingStarted(started);
        if (started) {
          clearInterval(isMeetingStartedInterval);
        }
      }, 1000 * 30);
      return () => {
        clearInterval(isMeetingStartedInterval);
      };
    }
    return undefined;
  }, [currentSession, currentSession?.id]);

  useEffect(() => {
    let timeTillSessionInterval: number;
    const updateTimer = () => {
      if (currentSession?.date) {
        const timeDifference = differenceInMinutes(new Date(currentSession.date), Date.now());
        setTimeTillLessonInMinutes(Math.floor(timeDifference));

        if (isMeetingStarted && timeDifference <= 0) {
          clearInterval(timeTillSessionInterval);
          joinSessionUrl();
        }
      }
    };
    // Update immediately when the component mounts
    updateTimer();

    timeTillSessionInterval = setInterval(updateTimer, 1000 * 10) as unknown as number;

    return () => {
      clearInterval(timeTillSessionInterval);
    };
  }, [
    navigate,
    currentSession?.date,
    currentSession?.id,
    setTimeTillLessonInMinutes,
    isMeetingStarted,
    testWeb,
    joinSessionUrl,
  ]);

  return (
    <section className="pre-class-screen holding-screen">
      {/* {user && !getIsUserLiveTutoring(user.roles) && <BetaTag />} */}
      <div className="holding-screen-content" onClick={() => handleClickOnCard()} aria-hidden>
        {!user?.isWizard && getModalHeaderImgByUser()}
        <div className="holding-screen-description">
          <div className="title">
            <FormattedMessage id="preClassScreen.classStartingSoon" />
          </div>
          <FormattedMessage id="preClassScreen.YouWillBeAdmitted" />
          <br /> <br />
          <FormattedMessage id="preClassScreen.PleaseMakeSure" />
        </div>
      </div>
      {currentSession &&
        (timeTillLessonInMinutes > 0 ? (
          <div className="start-time-countdown">
            {lang !== 'ja' ? (
              <div>
                {lang !== 'ko' && (
                  <>
                    {' '}
                    <FormattedMessage id="preClassScreen.classStartsAt" />
                    &nbsp;
                  </>
                )}
                <span>
                  <FormattedDate
                    value={new Date(currentSession?.date)}
                    hour={is12hFormat ? 'numeric' : '2-digit'}
                    minute={is12hFormat ? 'numeric' : '2-digit'}
                  />
                </span>
                {lang === 'ko' && <span>에 수업이 시작됩니다</span>}
                {lang === 'tr' && <span>'da başlıyor</span>}
              </div>
            ) : (
              <span>
                クラスは
                <FormattedDate
                  value={new Date(currentSession?.date)}
                  hour={is12hFormat ? 'numeric' : '2-digit'}
                  minute={is12hFormat ? 'numeric' : '2-digit'}
                />
                に始まります
              </span>
            )}
          </div>
        ) : (
          <div className={`start-time-countdown ${timeTillLessonInMinutes < 0 ? 'hide' : ''}`}>
            <div>
              <FormattedMessage id="preClassScreen.classStartsIn" />
              <span>{`< 1`}</span>&nbsp;
              {getMinSymbolByLang(lang)}
            </div>
          </div>
        ))}
    </section>
  );
}

export default PreClassScreen;
