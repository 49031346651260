import { ReactComponent as Checkmark } from '../../assets/image/filter-checkmark.svg';

interface LevelsFilterProps {
  selected: boolean;
}

function FilterCheckbox({ selected }: LevelsFilterProps) {
  return (
    <section className={`filter-checkbox ${selected ? 'selected' : ''}`}>
      {selected && <Checkmark />}
    </section>
  );
}

export default FilterCheckbox;
