import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useUserContext } from './UserContext';
import {
  cancelUserToSession,
  enrollUserToSession,
  getSessions,
  getSession,
  getPrivateUserWeeklyLimit,
} from '../helpers/Network';
import { Session, FilterQuery, NotifyMeNotification, UserWeeklyStatus } from '../helpers/types';
import {
  getFromSessionStorage,
  setArrayWithExpiry,
  setToSessionStorage,
  getMobileOperatingSystem,
  getSimplifiedLevelNumeric,
} from '../helpers/utils';
import { ClassLevelsEnum } from '../helpers/Enums';

dayjs.extend(utc);
dayjs.extend(timezone);

interface SessionsContextType {
  sessions?: Session[];
  upcomingSessions?: Session[];
  bookedSessions?: Session[];
  notifyMeSessions?: Session[];
  weeklyLimitRes: { groupWeeklyLimit: number; userWeeklyStatus: UserWeeklyStatus } | undefined;
  currentSession: Session | undefined;
  isBookedSessions: boolean;
  isFiltersDrawerOpen: boolean;
  filterQuery: FilterQuery;
  getSessionsD: (token: string, lang: string) => void;
  enrollSessionD: (
    sessionId: number,
    token: string,
    mother_lang: string,
  ) => Promise<{ userEnrolled: boolean }>;
  cancelSessionD: (
    sessionId: number,
    tenant: string,
    userId: string,
    token: string,
  ) => Promise<{ userCancel: boolean }>;
  updateCurrentSession: (sessionId: string | null, token?: string) => void;
  updateIsBookedSessions: (val: boolean) => void;
  updateIsFiltersDrawerOpen: (val: boolean) => void;
  updateFilterQuery: (filterQuery: FilterQuery) => void;
  setNotifyMeSessions: (val: any) => void;
  updateNotifyMeSessions: (session: Session, method: string, lang: string) => void;
  updateUserWeeklyLimitStatus: (token: string, offset: number) => void;
  isNotifyMeSent: String;
}

const initContext: SessionsContextType = {
  sessions: undefined,
  upcomingSessions: [],
  bookedSessions: [],
  notifyMeSessions: [],
  weeklyLimitRes: undefined,
  currentSession: undefined,
  isBookedSessions: false,
  isFiltersDrawerOpen: false,
  filterQuery: getFromSessionStorage('sessionStorageFilterQuery', {
    filterDate: '',
    filterLevel: [],
    filterTime: [],
  }),
  getSessionsD: () => {},
  enrollSessionD: async (sessionId: number, token: string, mother_lang: string) => {
    let result = null;
    result = await enrollUserToSession(token, sessionId, mother_lang);
    return result;
  },
  cancelSessionD: async (sessionId: number, tenant: string, userId: string, token: string) => {
    let result = null;
    result = await cancelUserToSession(tenant, userId, token, sessionId);
    return result;
  },
  updateCurrentSession: () => {},
  updateIsBookedSessions: () => {},
  updateIsFiltersDrawerOpen: () => {},
  updateFilterQuery: () => {},
  setNotifyMeSessions: () => {},
  updateNotifyMeSessions: () => {},
  updateUserWeeklyLimitStatus: () => {},
  isNotifyMeSent: 'false',
};
const SessionsContext = createContext(initContext);

type SessionsProviderProps = {
  children: ReactNode;
  level: number;
};

export const SessionsProvider = memo(({ children, level }: SessionsProviderProps) => {
  const [sessions, setSessions] = useState(initContext.sessions);
  const [upcomingSessions, setUpcomingSessions] = useState(initContext.upcomingSessions);
  const [bookedSessions, setBookedSessions] = useState(initContext.bookedSessions);
  const [notifyMeSessions, setNotifyMeSessions] = useState(initContext.notifyMeSessions);
  const [currentSession, setCurrentSession] = useState(initContext.currentSession);
  const [isBookedSessions, setIsBookedSessions] = useState(initContext.isBookedSessions);
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(initContext.isFiltersDrawerOpen);
  const [filterQuery, setFilterQuery] = useState(initContext.filterQuery);
  const [isNotifyMeSent, setIsNotifyMeSent] = useState(initContext.isNotifyMeSent);
  const { user, fetchUser } = useUserContext();
  const [weeklyLimitRes, setWeeklyLimitRes] = useState(initContext.weeklyLimitRes);
  const intl = useIntl();
  const userTimezone = dayjs.tz.guess();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // const { isNotifyMe } = useFlags();
  const os = getMobileOperatingSystem();

  // time filter logic:
  const timeSlotRanges = {
    1: [0, 5], // 00:00 - 05:59
    2: [6, 11], // 06:00 - 11:59
    3: [12, 17], // 12:00 - 17:59
    4: [18, 23], // 18:00 - 23:59
  };

  function getLocalTimeSlot(sessionTime: string, tz: string): number | null {
    const localHour = dayjs.utc(sessionTime).tz(tz).hour();

    const slot = Object.entries(timeSlotRanges).find(([, range]) => {
      const [start, end] = range;
      return localHour >= start && localHour <= end;
    });

    return slot ? parseInt(slot[0], 10) : null;
  }

  function filterSessionsByTimeSlots(
    sessionsToFilter: Session[],
    selectedSlots: number[],
    tz: string,
  ): Session[] {
    return sessionsToFilter.filter((session) => {
      const slot = getLocalTimeSlot(session.date, tz);
      return slot && selectedSlots.includes(slot);
    });
  }

  const sortSessionsByUserLevel = (filteredSessions: Session[]) => {
    const levelToCardLevels: { [key: number]: number[] } = {
      1: [1, 2, 3],
      2: [4, 5, 6, 7],
      3: [8, 9],
    };
    const userLevelCards = filteredSessions.filter((session) =>
      levelToCardLevels[level].includes(session.gseLevelId),
    );
    const otherCards = filteredSessions.filter(
      (session) => !levelToCardLevels[level].includes(session.gseLevelId),
    );

    return [...userLevelCards, ...otherCards];
  };
  // Apply filters if store filterQuery updates:
  const applyFilters = useCallback(() => {
    if (sessions && (filterQuery.filterDate || filterQuery.filterLevel || filterQuery.filterTime)) {
      const selectedLevelSessions: Session[] = [];
      const restSessions: Session[] = [];
      sessions
        .filter((item) => !item.isEnroll)
        .filter((a: Session) => new Date(a.date).getTime() > new Date().getTime())
        .forEach((a: Session) => {
          if (
            ClassLevelsEnum[a.topic.level as 'Beginner' | 'Intermediate' | 'Advanced'] === level &&
            level !== 1
          ) {
            selectedLevelSessions.push(a);
          } else {
            restSessions.push(a);
          }
        });
      let filteredUpcomingSessions = selectedLevelSessions.concat(restSessions);

      // console.log(
      //   'filterQuery.filterLevel: ',
      //   filterQuery.filterLevel,
      //   'filterQuery.filterTime: ',
      //   filterQuery.filterTime,
      // );

      if (filterQuery.filterLevel.length) {
        // filter level case 1: reset to show all levels
        if (filterQuery.filterLevel.length > 0) {
          // filter level case 2: filter to specific level
          filteredUpcomingSessions = filteredUpcomingSessions.filter((session) =>
            filterQuery.filterLevel.includes(getSimplifiedLevelNumeric(session.gseLevelId)),
          );
        }
      }
      if (filterQuery.filterTime) {
        if (filterQuery.filterTime.length > 0) {
          const filteredSessions = filterSessionsByTimeSlots(
            filteredUpcomingSessions,
            filterQuery.filterTime,
            userTimezone,
          );
          filteredUpcomingSessions = filteredSessions;
        }
      }
      if (filterQuery.filterDate) {
        // filter date case 1: reset to show all dates
        if (filterQuery.filterDate === '') return;
        // filter date case 2: filter to specific date
        filteredUpcomingSessions = filteredUpcomingSessions.filter(
          (el) =>
            new Date(el.date).toLocaleDateString() ===
            new Date(filterQuery.filterDate).toLocaleDateString(),
        );
      }
      // sort by user level:
      filteredUpcomingSessions = sortSessionsByUserLevel(filteredUpcomingSessions);
      // console.log('filteredUpcomingSessions: ', filteredUpcomingSessions);
      setUpcomingSessions(filteredUpcomingSessions);
    }
  }, [filterQuery.filterDate, filterQuery.filterLevel, filterQuery.filterTime, level, sessions]);

  const updateUserWeeklyLimitStatus = useCallback(async (token: string, offset: number) => {
    const userWeeklyLimitRes = await getPrivateUserWeeklyLimit(token, offset * -1);
    if (userWeeklyLimitRes) {
      setWeeklyLimitRes(userWeeklyLimitRes);
    }
  }, []);

  const getSessionsD = useCallback(async (token: string, lang: string) => {
    const sessionsDataResp = await getSessions(token, lang);
    if (sessionsDataResp) {
      setSessions(sessionsDataResp.sessions);
    }
    updateUserWeeklyLimitStatus(token, new Date().getTimezoneOffset() / 60);
  }, []);

  const enrollSessionD = useCallback(
    async (sessionId: number, token: string, mother_lang: string) => {
      let result = null;
      result = await enrollUserToSession(token, sessionId, mother_lang);
      if (token.includes('wizard')) {
        fetchUser(!!params.testWeb, params.hash);
      }
      updateUserWeeklyLimitStatus(token, new Date().getTimezoneOffset() / 60);
      return result;
    },
    [],
  );

  const cancelSessionD = useCallback(
    async (sessionId: number, tenant: string, userId: string, token: string) => {
      let result = null;
      result = await cancelUserToSession(tenant, userId, token, sessionId);
      if (token.includes('wizard')) {
        fetchUser(!!params.testWeb, params.hash);
      }
      updateUserWeeklyLimitStatus(token, new Date().getTimezoneOffset() / 60);
      return result;
    },
    [],
  );

  const updateCurrentSession = useCallback(async (sessionId: string | null, token?: string) => {
    const updatedCurrentSession: Session = sessionId ? await getSession(sessionId, token!) : null;
    setCurrentSession(updatedCurrentSession);
  }, []);

  const updateIsBookedSessions = useCallback(
    (val: boolean) => {
      setIsBookedSessions(val);
      applyFilters();
    },
    [applyFilters],
  );

  const updateIsFiltersDrawerOpen = useCallback((val: boolean) => {
    setIsFiltersDrawerOpen(val);
  }, []);

  const updateFilterQuery = useCallback(
    (updatedFilterQuery: FilterQuery) => {
      // console.log('booom', updatedFilterQuery);
      setFilterQuery(updatedFilterQuery);
      setToSessionStorage('sessionStorageFilterQuery', updatedFilterQuery);
      applyFilters();
    },
    [applyFilters],
  );

  // Notify me notification 8h prior to session:
  // For synchronization reasons, all booked sessions should first be reported cancelled, than (re-)booked.
  const manageNotifyMeNotifications = useCallback(
    async (sessionId: number, method: string, lang: string): Promise<void> => {
      const notifyMeNotification: Record<number, NotifyMeNotification> = {};
      if (user?.id && notifyMeSessions && sessions) {
        // cancel all to reset:
        try {
          await Promise.all(
            notifyMeSessions.map((session) => {
              return window.mondlyNative
                ?.lessonCanceled(`${user.id}${session.id}`)
                .then((result) => {
                  console.log(result, 'lessonCanceled', `${user.id}${session.id}`);
                });
            }),
          );
        } catch (err: any) {
          console.log(err.message);
        }

        const copyNotifyMeSessions = [...(notifyMeSessions || [])];
        if (method === 'add') {
          const sessionInput = sessions.filter((item) => item.id === sessionId)?.[0];
          copyNotifyMeSessions?.push(sessionInput);
        } else if (method === 'remove') {
          const index = notifyMeSessions?.findIndex((item) => item.id === sessionId);
          copyNotifyMeSessions?.splice(index, 1);
        }

        copyNotifyMeSessions?.forEach((bSession) => {
          Object.assign(notifyMeNotification, {
            [bSession.id]: {
              id: bSession.id,
              time: new Date(bSession.date).getTime() - 8 * 60 * 60 * 1000,
              date: bSession.date,
              tutor: bSession.tutor.firstName,
              title: bSession.topic.title,
            },
          });

          if (os === 'android') {
            // send notificaiton 2min before session ONLY FOR ANDROID:
            window.mondlyNative
              ?.lessonBooked(`${user.id}${bSession.id}`, new Date(bSession.date).getTime(), {
                firstNoticeMinutes: 2,
                secondNoticeMinutes: 2,
                firstNoticeBodyText: intl.formatMessage({
                  id: 'appNotifications.reserveYourSpotNow',
                }),
              })
              .then((result) => {
                console.log(result, 'lessonBooked', new Date(bSession.date).getTime());
              })
              .catch((err) => {
                console.log(err.message);
              });
          }
        });

        // send notificaiton 8h prior to session:
        Object.values(notifyMeNotification).forEach((item) => {
          const localeLang = lang === 'br' ? 'pt-BR' : lang;
          const dayOfWeek = new Date(item.date).toLocaleString(localeLang, { weekday: 'long' });
          const formattedDate = `${dayOfWeek}, ${new Date(item.date).toLocaleString(localeLang, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}, ${new Date(item.date).toLocaleTimeString(localeLang, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })}`;

          const getOnFormatted = () => {
            if (lang === 'pl') {
              // Polish lang adjustments: requires different 'on' for Tuesday ('we') than other weekdays ('w')
              if (new Date(item.date).getDay() === 2) {
                return ' we';
              }
              return ` ${intl.formatMessage({
                id: 'appNotifications.on',
              })}`;
            }
            return ` ${intl.formatMessage({
              id: 'appNotifications.on',
            })}`;
          };

          const notifyMeAppNotificationFormatted = () => {
            if (lang !== 'zh' && lang !== 'ko' && lang !== 'ja' && lang !== 'ro' && lang !== 'br') {
              return `${intl.formatMessage({
                id: 'appNotifications.youCanNowBook',
              })} “${item.title}” ${intl.formatMessage({
                id: 'appNotifications.with',
              })} ${item.tutor}${getOnFormatted()} ${formattedDate}${
                lang !== 'de' && lang !== 'tr' ? '.' : ''
              } ${intl.formatMessage({
                id: 'appNotifications.reserveYourSpotNow',
              })}`;
            }
            // ro or br:
            if (lang === 'ro' || lang === 'br') {
              return `${intl.formatMessage({
                id: 'appNotifications.youCanNowBook',
              })} “${item.title}” ${intl.formatMessage({
                id: 'appNotifications.with',
              })} ${item.tutor} ${formattedDate}. ${intl.formatMessage({
                id: 'appNotifications.reserveYourSpotNow',
              })}`;
            }
            // Chinese, Korean and Japanese require phrasing with variables in reverse
            return `${
              lang !== 'ja'
                ? intl.formatMessage({
                    id: 'appNotifications.youCanNowBook',
                  })
                : ''
            }${formattedDate} ${intl.formatMessage({
              id: 'appNotifications.with',
            })} ${item.tutor} ${intl.formatMessage({
              id: 'appNotifications.on',
            })}${lang !== 'ja' ? ' "' : ''}${item.title}${
              lang !== 'ja' ? '" ' : ''
            }${intl.formatMessage({
              id: 'appNotifications.reserveYourSpotNow',
            })}`;
          };

          window.mondlyNative
            ?.scheduleLocalNotification(
              `${user.id}${item.id}`,
              item.time,
              notifyMeAppNotificationFormatted(),
            )
            .then((result) => {
              setIsNotifyMeSent(notifyMeAppNotificationFormatted());
              console.log(
                result,
                'scheduleLocalNotifyMeNotification',
                notifyMeAppNotificationFormatted(),
                item.time,
                `${user.id}${item.id}`,
              );
            })
            .catch((err) => {
              setIsNotifyMeSent(err.message);
              console.log(err.message);
            });
        });
      }
    },
    [user?.id, notifyMeSessions, sessions, os, intl],
  );

  const updateNotifyMeSessions = useCallback(
    (session: Session, method: string, lang: string) => {
      if (method === 'add' && notifyMeSessions) {
        // verify not already in array:
        if (!notifyMeSessions.some((el) => el.id === session.id)) {
          manageNotifyMeNotifications(session.id, method, lang);
          setNotifyMeSessions([...notifyMeSessions, session]);
        }
      }
      // method 'remove':
      if (method === 'remove' && notifyMeSessions) {
        // verify is in array:
        if (notifyMeSessions.some((el) => el.id === session.id)) {
          manageNotifyMeNotifications(session.id, method, lang);
          const updatedNotifyMeSessions = notifyMeSessions.filter((el) => el.id !== session.id);
          setNotifyMeSessions(updatedNotifyMeSessions);
          if (!updatedNotifyMeSessions.length) {
            setArrayWithExpiry('notifyMeSessionsLocalStorage', []);
          }
        }
      }
    },
    [manageNotifyMeNotifications, notifyMeSessions],
  );

  // set notifyMeSessions to LocalStorage:
  useMemo(() => {
    // create minified array:
    if (notifyMeSessions) {
      if (notifyMeSessions.length) {
        const localStorageNotifyMeSessions = notifyMeSessions.map((item) => ({
          id: item.id,
          expiry: new Date(item.date).getTime() - 8 * 60 * 60 * 1000,
        }));
        setArrayWithExpiry('notifyMeSessionsLocalStorage', localStorageNotifyMeSessions);
      }
    }
  }, [notifyMeSessions]);

  // const filterOutdatedNotifyMe = useCallback(() => {
  //   if (notifyMeSessions?.length) {
  //     const now = Date.now();
  //     const filteredNotifyMe = notifyMeSessions.filter((item) => {
  //       const itemDate = new Date(item.date).getTime();
  //       const timeDifference = now - itemDate;
  //       const hoursDifference = timeDifference / (1000 * 60 * 60);
  //       return hoursDifference >= 8;
  //     });
  //     setNotifyMeSessions(filteredNotifyMe);
  //   }
  //   setNotifyMeSessions([]);
  // }, [notifyMeSessions]);

  // get notifyMeSessions from LocalStorage:
  // useEffect(() => {
  //   const notifyMeSessionsLocalStorage: ItemIdWithExpiry[] = getArrayWithExpiry(
  //     'notifyMeSessionsLocalStorage',
  //   );
  //   if (isNotifyMe && user?.state !== 0) {
  //     // from main sessions, filter those who match notifyMeLocalStorage sessionId:
  //     if (sessions) {
  //       if (notifyMeSessionsLocalStorage.length) {
  //         const filteredElements = sessions.filter((session) =>
  //           notifyMeSessionsLocalStorage.some((item) => item.id === session.id),
  //         );
  //         setNotifyMeSessions(filteredElements);
  //       }
  //     }
  //   }
  //   if (os === 'android') {
  //     const intervalFilterOutdatedNotifyMe = setInterval(filterOutdatedNotifyMe, 60000);
  //     // Cleanup the interval when the component unmounts
  //     return () => clearInterval(intervalFilterOutdatedNotifyMe);
  //   }
  //   return undefined;
  // }, [filterOutdatedNotifyMe, isNotifyMe, os, sessions, user?.state]);

  // Sort sessions chronologically
  // useEffect(() => {
  //   setSessions(
  //     sessions?.sort(
  //       (a: Session, b: Session) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  //     ),
  //   );
  // }, [sessions]);

  // Set upcoming and booked sessions. updates on sessions change:

  useEffect(() => {
    if (Array.isArray(sessions)) {
      // Filter upcoming sessions (not booked) AND sort by level
      // Define the mapping of levels to session.gseLevelId:
      const levelToCardLevels: { [key: number]: number[] } = {
        1: [1, 2, 3],
        2: [4, 5, 6, 7],
        3: [8, 9],
      };

      // filter out booked sessions:
      const sessionsWithoutBooked = sessions
        .filter((item) => !item.isEnroll)
        .sort((a, b) => (new Date(a.date) as any) - (new Date(b.date) as any));
      // Filter out cards matching the user's level and sort them first
      const userLevelCards = sessionsWithoutBooked.filter((session) =>
        levelToCardLevels[level].includes(session.gseLevelId),
      );

      const otherCards = sessionsWithoutBooked.filter(
        (session) => !levelToCardLevels[level].includes(session.gseLevelId),
      );

      const updatedUpcomingSessions = [...userLevelCards, ...otherCards];

      setUpcomingSessions(updatedUpcomingSessions);
      const bookedClasses = sessions.filter((item) => item.isEnroll);
      setBookedSessions(bookedClasses);
    }
    applyFilters();
  }, [sessions, level, applyFilters]);

  const value = useMemo(
    () => ({
      sessions,
      upcomingSessions,
      bookedSessions,
      notifyMeSessions,
      updateNotifyMeSessions,
      currentSession,
      isBookedSessions,
      isFiltersDrawerOpen,
      filterQuery,
      getSessionsD,
      enrollSessionD,
      cancelSessionD,
      updateCurrentSession,
      updateIsBookedSessions,
      updateIsFiltersDrawerOpen,
      updateFilterQuery,
      applyFilters,
      isNotifyMeSent,
      setNotifyMeSessions,
      weeklyLimitRes,
      updateUserWeeklyLimitStatus,
    }),
    [
      sessions,
      upcomingSessions,
      bookedSessions,
      notifyMeSessions,
      updateNotifyMeSessions,
      currentSession,
      isBookedSessions,
      isFiltersDrawerOpen,
      filterQuery,
      getSessionsD,
      enrollSessionD,
      cancelSessionD,
      updateCurrentSession,
      updateIsBookedSessions,
      updateIsFiltersDrawerOpen,
      updateFilterQuery,
      applyFilters,
      isNotifyMeSent,
      setNotifyMeSessions,
      weeklyLimitRes,
      updateUserWeeklyLimitStatus,
    ],
  );

  return <SessionsContext.Provider value={value}>{children}</SessionsContext.Provider>;
});

export const useSessionsContext = () => useContext(SessionsContext);
