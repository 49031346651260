import { createContext, useContext, useCallback, useMemo, memo, ReactNode } from 'react';
import mixpanel from 'mixpanel-browser';

interface State {
  mixpanelTrackEvent: (eventName: string, payload?: any) => void;
  mixpanelTimeEvent: (eventName: string) => void;
}

const initContext: State = {
  mixpanelTrackEvent: () => {},
  mixpanelTimeEvent: () => {},
};

const MixpanelContext = createContext(initContext);

type MixpanelProviderProps = {
  children: ReactNode;
};

export const MixpanelProvider = memo(({ children }: MixpanelProviderProps) => {
  const mixpanelTrackEvent = useCallback((eventName: string, payload?: any) => {
    if (process.env.REACT_APP_CONTENT_ENV === 'prod') {
      try {
        mixpanel.track(eventName, payload);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const mixpanelTimeEvent = useCallback((eventName: string) => {
    if (process.env.REACT_APP_CONTENT_ENV === 'prod') {
      try {
        mixpanel.time_event(eventName);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const value = useMemo(
    () => ({
      mixpanelTrackEvent,
      mixpanelTimeEvent,
    }),
    [mixpanelTimeEvent, mixpanelTrackEvent],
  );

  return <MixpanelContext.Provider value={value}>{children}</MixpanelContext.Provider>;
});

export const useMixpanelContext = () => useContext(MixpanelContext);
