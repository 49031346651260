import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Session } from '../../helpers/types';
import ClassCard from '../Classes/ClassCard/ClassCard';
import { useSessionsContext } from '../../Providers/SessionsContext';

interface WeeklyLimitBookedTabProps {
  lang: string;
  isBooked: boolean;
  // bookedSessions: Session[];
  cancelSession: (sessionId: number) => void;
  joinSession: (sessionId: number) => void;
  onSessionBook: (sessionId: number) => void;
  recentEnrolledSessionId: number | null;
  setRecentEnrolledSessionId: (sessionId: number | null) => void;
  checkIfBlockedUser: () => void;
  platform: string;
}

function WeeklyLimitBookedTab({
  lang,
  isBooked,
  cancelSession,
  joinSession,
  onSessionBook,
  recentEnrolledSessionId,
  setRecentEnrolledSessionId,
  checkIfBlockedUser,
  platform,
}: WeeklyLimitBookedTabProps) {
  const intl = useIntl();
  const startOfWeekDay = 1;
  const { bookedSessions, weeklyLimitRes } = useSessionsContext();

  function getPreviousMonday(date: dayjs.Dayjs) {
    const today = dayjs(date);
    const dayOfWeek = today.day();
    if (dayOfWeek === startOfWeekDay) {
      return today.subtract(7, 'day');
    }
    const daysSinceLastMonday = (dayOfWeek + 6) % 7;
    return today.subtract(daysSinceLastMonday, 'day');
  }

  const getNextStartOfWeekDay = (today: dayjs.Dayjs) => {
    const dayOfWeek = today.day();
    // TODO: activate when startOfWeekDay is dynamic:
    // if (startOfWeekDay === 0) {
    //   // Calculate days until next Sunday
    //   const daysUntilNextSunday = (7 - dayOfWeek) % 7 || 7;
    //   return today.add(daysUntilNextSunday, 'day');
    // } else {
    // Calculate days until next Monday
    const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
    return today.add(daysUntilNextMonday, 'day');
    // }
  };

  const firstWeekStartDate =
    dayjs().day() === startOfWeekDay ? dayjs() : getPreviousMonday(dayjs());
  const firstWeekStartDateString = firstWeekStartDate.format('YYYY-MM-DD');

  const firstWeekLastDay =
    // TODO: if (startOfWeekDay === 0) {
    // }
    dayjs().day() === startOfWeekDay - 1
      ? dayjs()
      : getNextStartOfWeekDay(dayjs()).subtract(1, 'day');

  const isDateWithinFirstWeek = (date: dayjs.Dayjs) => {
    if (
      date.isSame(firstWeekStartDate, 'day') ||
      date.isSame(firstWeekLastDay, 'day') ||
      (date.isAfter(firstWeekStartDate, 'day') && date.isBefore(firstWeekLastDay, 'day'))
    ) {
      return true;
    }
    return false;
  };

  const nextWeekStartDate = firstWeekStartDate.add(7, 'day');
  const nextWeekLastDay = firstWeekLastDay.add(7, 'day');

  // const nextWeekStartDateString = nextWeekStartDate.format('YYYY-MM-DD');

  const isDateWithinNextWeek = (date: dayjs.Dayjs) => {
    if (
      date.isSame(nextWeekStartDate, 'day') ||
      date.isSame(nextWeekLastDay, 'day') ||
      (date.isAfter(nextWeekStartDate, 'day') && date.isBefore(nextWeekLastDay, 'day'))
    ) {
      return true;
    }
    return false;
  };

  const afterNextWeekStartDate = firstWeekStartDate.add(14, 'day');
  const afterNextWeekLastDay = firstWeekLastDay.add(14, 'day');

  const isDateAfterNextWeek = (date: dayjs.Dayjs) => {
    if (
      date.isSame(afterNextWeekStartDate, 'day') ||
      date.isSame(afterNextWeekLastDay, 'day') ||
      (date.isAfter(afterNextWeekStartDate, 'day') && date.isBefore(afterNextWeekLastDay, 'day'))
    ) {
      return true;
    }
    return false;
  };

  const getIsDifferentMonths = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    return start.month() !== end.month();
  };

  const [bookedSessionsFirstWeekNumber, setBookedSessionsFirstWeekNumber] = useState(0);
  const [bookedSessionsNextWeekNumber, setBookedSessionsNextWeekNumber] = useState(0);
  const [bookedSessionsAfterNextWeekNumber, setBookedSessionsAfterNextWeekNumber] = useState(0);

  const [bookedSessionsFirstWeek, setBookedSessionsFirstWeek] = useState<Session[]>([]);
  const [bookedSessionsNextWeek, setBookedSessionsNextWeek] = useState<Session[]>([]);
  const [bookedSessionsAfterNextWeek, setBookedSessionsAfterNextWeek] = useState<Session[]>([]);

  useEffect(() => {
    if (bookedSessions) {
      const firstWeekSessions = bookedSessions.filter((session) =>
        isDateWithinFirstWeek(dayjs(session.date)),
      );
      const nextWeekSessions = bookedSessions.filter((session) =>
        isDateWithinNextWeek(dayjs(session.date)),
      );
      const afterNextWeekSessions = bookedSessions.filter((session) =>
        isDateAfterNextWeek(dayjs(session.date)),
      );

      setBookedSessionsFirstWeek(firstWeekSessions);
      setBookedSessionsNextWeek(nextWeekSessions);
      setBookedSessionsAfterNextWeek(afterNextWeekSessions);

      setBookedSessionsFirstWeekNumber(firstWeekSessions.length);
      setBookedSessionsNextWeekNumber(nextWeekSessions.length);
      setBookedSessionsAfterNextWeekNumber(afterNextWeekSessions.length);
    } else {
      setBookedSessionsFirstWeek([]);
      setBookedSessionsNextWeek([]);
      setBookedSessionsAfterNextWeek([]);
      setBookedSessionsFirstWeekNumber(0);
      setBookedSessionsNextWeekNumber(0);
      setBookedSessionsAfterNextWeekNumber(0);
    }
  }, [bookedSessions]);

  const getTranslatedMonth = (date: dayjs.Dayjs) => {
    return intl.formatMessage({
      id: `common.${dayjs(date).format('MMMM').toLowerCase()}`,
    });
  };

  return (
    <section className="weekly-limit-booked-tab-section" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="week-container">
        <p className="uppercase">
          <FormattedMessage id="sessions.thisWeek" />
        </p>
        <div
          className={`week-data flex space-between ${
            getIsDifferentMonths(firstWeekStartDate, firstWeekLastDay) && lang === 'vi'
              ? 'column'
              : ''
          }`}
        >
          <p className="week-dates">
            {dayjs(firstWeekStartDate).format('D')}{' '}
            {getIsDifferentMonths(firstWeekStartDate, firstWeekLastDay)
              ? getTranslatedMonth(firstWeekStartDate)
              : ''}{' '}
            - {dayjs(firstWeekLastDay).format('D')} {getTranslatedMonth(firstWeekLastDay)}
          </p>
          {weeklyLimitRes &&
            weeklyLimitRes.userWeeklyStatus &&
            (weeklyLimitRes?.userWeeklyStatus?.[firstWeekStartDateString]?.attended || 0) <
              weeklyLimitRes?.groupWeeklyLimit && (
              <p
                className={`classes-booked ${
                  getIsDifferentMonths(firstWeekStartDate, firstWeekLastDay) && lang === 'vi'
                    ? 'align-end'
                    : ''
                }`}
              >
                {lang === 'ko' && '예약된 수업 '}
                {lang === 'ar' && ' تم حجز '}
                {bookedSessionsFirstWeekNumber}{' '}
                {lang !== 'pl' && bookedSessionsFirstWeekNumber === 1 && (
                  <FormattedMessage id="sessions.classBooked" />
                )}
                {lang !== 'pl' && bookedSessionsFirstWeekNumber !== 1 && (
                  <FormattedMessage id="sessions.classesBooked" />
                )}
                {lang === 'pl' && bookedSessionsFirstWeekNumber === 0 && 'zarezerwowanych zajęć'}
                {lang === 'pl' && bookedSessionsFirstWeekNumber !== 0 && (
                  <FormattedMessage id="sessions.classesBooked" />
                )}
              </p>
            )}
        </div>
        <div className="week-sessions">
          {weeklyLimitRes &&
          weeklyLimitRes.userWeeklyStatus &&
          (weeklyLimitRes?.userWeeklyStatus?.[firstWeekStartDateString]?.attended || 0) <
            weeklyLimitRes?.groupWeeklyLimit ? (
            bookedSessionsFirstWeek.map((session) => (
              <ClassCard
                isBooked={isBooked}
                session={session}
                key={session.id}
                cancelSession={cancelSession}
                joinSession={joinSession}
                onSessionBook={onSessionBook}
                recentEnrolledSessionId={recentEnrolledSessionId}
                setRecentEnrolledSessionId={setRecentEnrolledSessionId}
                lang={lang}
                checkIfBlockedUser={checkIfBlockedUser}
                platform={platform}
              />
            ))
          ) : (
            <div className="no-items-txt">
              <p className="max-weekly-limit-classes text-white">
                <FormattedMessage id="weeklyLimit.youCannotBookAnyMoreClasses" />{' '}
                {lang === 'tr' && ' daha fazla ders için yer ayıramazsınız.'}
                {lang === 'ja' && ' これ以上クラスを予約できません'}
                {lang === 'ko' && ' 에는 더 이상 수업을 예약할 수 없습니다.'}
                {lang === 'zh' && ' 无法预订更多课程'}
              </p>
            </div>
          )}
        </div>
      </div>

      {(bookedSessionsNextWeekNumber > 0 || bookedSessionsAfterNextWeekNumber > 0) && (
        <div className="week-container">
          <p className="uppercase">
            <FormattedMessage id="sessions.nextWeek" />
          </p>
          <div
            className={`week-data flex space-between ${
              getIsDifferentMonths(nextWeekStartDate, nextWeekLastDay) && lang === 'vi'
                ? 'column'
                : ''
            }`}
          >
            <p className="week-dates">
              {nextWeekStartDate.format('D')}{' '}
              {getIsDifferentMonths(nextWeekStartDate, nextWeekLastDay)
                ? getTranslatedMonth(nextWeekStartDate)
                : ''}{' '}
              - {nextWeekLastDay.format('D')} {getTranslatedMonth(nextWeekLastDay)}
            </p>
            <p
              className={`classes-booked ${
                getIsDifferentMonths(nextWeekStartDate, nextWeekLastDay) && lang === 'vi'
                  ? 'align-end'
                  : ''
              }`}
            >
              {lang === 'ar' && ' تم حجز '}
              {lang === 'ko' && '예약된 수업 '}
              {bookedSessionsNextWeekNumber}{' '}
              {bookedSessionsNextWeekNumber === 1 ? (
                <FormattedMessage id="sessions.classBooked" />
              ) : (
                <FormattedMessage id="sessions.classesBooked" />
              )}
            </p>
          </div>

          <div className="week-sessions">
            {bookedSessionsNextWeek && bookedSessionsNextWeekNumber > 0 ? (
              bookedSessionsNextWeek.map((session) => (
                <ClassCard
                  isBooked={isBooked}
                  session={session}
                  key={session.id}
                  cancelSession={cancelSession}
                  joinSession={joinSession}
                  onSessionBook={onSessionBook}
                  recentEnrolledSessionId={recentEnrolledSessionId}
                  setRecentEnrolledSessionId={setRecentEnrolledSessionId}
                  lang={lang}
                  checkIfBlockedUser={checkIfBlockedUser}
                  platform={platform}
                />
              ))
            ) : (
              <div className="full-width">
                <FormattedMessage id="sessions.noClassesBookedNextWeek" />
              </div>
            )}
          </div>
        </div>
      )}
      {/* case: continues to 3rd full week (happens if current date is not a startOfWeekDay) */}
      {bookedSessionsAfterNextWeekNumber > 0 && (
        <div className="week-container">
          <div
            className={`week-data flex space-between ${
              getIsDifferentMonths(afterNextWeekStartDate, afterNextWeekLastDay) && lang === 'vi'
                ? 'column'
                : ''
            }`}
          >
            <p className="week-dates">
              {afterNextWeekStartDate.format('D')}{' '}
              {getIsDifferentMonths(afterNextWeekStartDate, afterNextWeekLastDay)
                ? getTranslatedMonth(afterNextWeekStartDate)
                : ''}{' '}
              - {afterNextWeekLastDay.format('D')} {getTranslatedMonth(afterNextWeekLastDay)}
            </p>
            <p
              className={`classes-booked ${
                getIsDifferentMonths(afterNextWeekStartDate, afterNextWeekLastDay) && lang === 'vi'
                  ? 'align-end'
                  : ''
              }`}
            >
              {lang === 'ar' && ' تم حجز '}
              {lang === 'ko' && '예약된 수업 '}
              {bookedSessionsAfterNextWeekNumber}{' '}
              {bookedSessionsAfterNextWeekNumber === 1 ? (
                <FormattedMessage id="sessions.classBooked" />
              ) : (
                <FormattedMessage id="sessions.classesBooked" />
              )}
            </p>
          </div>

          <div className="week-sessions">
            {bookedSessionsAfterNextWeek &&
              bookedSessionsAfterNextWeek.map((session) => (
                <ClassCard
                  isBooked={isBooked}
                  session={session}
                  key={session.id}
                  cancelSession={cancelSession}
                  joinSession={joinSession}
                  onSessionBook={onSessionBook}
                  recentEnrolledSessionId={recentEnrolledSessionId}
                  setRecentEnrolledSessionId={setRecentEnrolledSessionId}
                  lang={lang}
                  checkIfBlockedUser={checkIfBlockedUser}
                  platform={platform}
                />
              ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default WeeklyLimitBookedTab;
