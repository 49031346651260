import { FormattedMessage } from 'react-intl';

interface RestrictionAlertProps {
  restrictionMsgType: string;
}

function RestrictionAlert({ restrictionMsgType }: RestrictionAlertProps) {
  const getRestrictionMsg = () => {
    switch (restrictionMsgType) {
      case 'max3BookedSessions':
        return <FormattedMessage id="restrictionAlert.youCanOnlyHave3Classes" />;
      case 'max1BookedSessions':
        return <FormattedMessage id="restrictionAlert.youCanOnlyHave1Classes" />;
      case 'noDoubleBooking':
        return <FormattedMessage id="restrictionAlert.youAlreadyHaveAClassBooked" />;
      case 'youHaveBeenBlocked':
        return <FormattedMessage id="restrictionAlert.youHaveBeenBlocked" />;
      default:
        return 'You cannot book this class at the moment';
    }
  };

  return (
    <section className="restriction-alert">
      <p>{getRestrictionMsg()}</p>
    </section>
  );
}

export default RestrictionAlert;
