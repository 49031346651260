import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import calendarImage from '../../assets/image/calendar-icon.png';
import chatImage from '../../assets/image/chat-icon.png';
import welcomeScreenBeta from '../../assets/image/welcome-screen-beta.png';
import checkCircleImage from '../../assets/image/check-circle-icon.png';
import clockImage from '../../assets/image/clock-icon-24-3.png';
import hatImage from '../../assets/image/hat-icon.png';
import messagesImage from '../../assets/image/messages-icon.png';
import { useUserContext } from '../../Providers/UserContext';
import { getIsUserLiveTutoring } from '../../helpers/utils';

function WelcomeModal() {
  const { user } = useUserContext();
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getWelcomeModalHeaderImgByUser = () => {
    return user && !getIsUserLiveTutoring(user.roles) ? welcomeScreenBeta : messagesImage;
  };

  return (
    <section className={`welcome-modal-container ${isLandscape ? 'landscape' : ''}`} dir="auto">
      <div className="welcome-modal-header">
        <div className="welcome__imageMessages" style={{ position: 'relative' }}>
          <img src={getWelcomeModalHeaderImgByUser()} alt="Messages-icon" />
        </div>
        <div className="welcome__title" dir="auto">
          <FormattedMessage id="welcomeModal.greetings" />
          <div className="welcome__subtitle" dir="auto">
            <FormattedMessage id="welcomeModal.letsGetStarted" />
          </div>
        </div>
      </div>
      <ul className="welcome__list" dir="auto">
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={checkCircleImage} alt="checkCircle-icon" />
            <FormattedMessage id="welcomeModal.specialistTeachers" />
          </div>
        </li>
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={hatImage} alt="hat-icon" />
            <FormattedMessage id="welcomeModal.studentsAtYourLevel" />
          </div>
        </li>
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={chatImage} alt="chat-icon" />
            <FormattedMessage id="welcomeModal.customizedFeedback" />
          </div>
        </li>
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={calendarImage} alt="calendar-icon" />
            <FormattedMessage id="welcomeModal.findFavoriteTime" />
          </div>
        </li>
        <li className="welcome__list-item">
          <div className="list-info-item class-level flex justify-start align-center">
            <img className="list-icon-img" src={clockImage} alt="Level-icon" />
            <FormattedMessage id="welcomeModal.cancelUpToOneHourBefore" />
          </div>
        </li>
      </ul>
    </section>
  );
}

export default WelcomeModal;
