/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useUserContext } from '../../Providers/UserContext';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useModalsContext } from '../../Providers/ModalsContext';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import TabSwitcher from '../Classes/TabSwitcher';
import ClassCard from '../Classes/ClassCard/ClassCard';
import BottomConfirmationPopup from '../Shared/BottomConfirmationPopup';
import closeImage from '../../assets/image/close.png';
import {
  differenceInMinutes,
  getCurrWeekBookedSessionsStatus,
  getCurrWeekStartDate,
  getCurrWeekStatus,
  getIsDifferentMonths,
} from '../../helpers/utils';
import OvalLoader from '../../assets/loader/oval';
import AgreeAndBook from '../Modals/AgreeAndBook';
import AccountRequired from '../Modals/AccountRequired';
import { sendEventToMondly } from '../../helpers/Event';
import { fetchMondlyUserToken } from '../../helpers/Network';
import AlertModal from '../Modals/AlertModal';
import DatePickerBar from '../DatePickerBar/DatePickerBar';
import WeeklyLimitBookedTab from './WeeklyLimitBookedTab';
import WeeklyLimitBanner from './WeeklyLimitBanner';
import JoiningClassModal from '../Modals/JoiningClassModal';
import UserGuideBanner from '../UserGuide/UserGuideBanner';
import ClassGuideBanner from '../ClassGuide/ClassGuideBanner';

interface SessionsProps {
  bodyScrollHeight: number;
  setTermsAndConditionsModal: (a: boolean) => void | undefined;
  lang: string;
  level: number;
  setIsUserGuidePage: (val: boolean) => void;
  setIsClassGuidePage: (val: boolean) => void;
  resetKeyboardScroll: () => void;
  onOpenFilters: () => void;
  usersBlockList: string[];
  deviceId: string;
  platform: string;
  isSticky: boolean;
}

interface DailyNotification {
  time: number;
  id: number;
}

enum ErrorTypes {
  INVALID_TOKEN = 'Token not valid',
  INVALID_SUBSCRIPTION = 'No subscription',
}

function Sessions({
  bodyScrollHeight,
  setTermsAndConditionsModal,
  lang,
  level,
  resetKeyboardScroll,
  onOpenFilters,
  usersBlockList,
  deviceId,
  platform,
  isSticky,
  setIsUserGuidePage,
  setIsClassGuidePage,
}: SessionsProps) {
  const [queryParams] = useSearchParams();
  const [appGetSessionsStartSent, setAppGetSessionsStartSent] = useState(false);
  const [appGetSessionsTrackSent, setAppGetSessionsTrackSent] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const testWeb = queryParams.get('testWeb');
  const hash = queryParams.get('hash');
  const [isBooked, setIsBooked] = useState(!!queryParams.get('showBooked'));
  const [isClassStarting, setIsClassStarting] = useState(false);
  const [openBookedClassPopover, setOpenBookedClassPopover] = useState(false);
  const [openCancelledClassPopover, setOpenCancelledClassPopover] = useState(false);
  const [enrollSessionErrorModal, setEnrollSessionErrorModal] = useState({
    visible: false,
    title: 'Token not valid',
  });
  // const { showAgreeScreen } = useFlags();
  const { sessionbook } = useParams();
  const currentPageHeight = useRef(0);
  const isInitialMount = useRef(true);
  const tenantId = queryParams.get('tenandId') || 'test';
  const { user, token, updateUserName } = useUserContext();
  const { updateModalContent, updateStrictModalContent } = useModalsContext();
  const { mixpanelTrackEvent, mixpanelTimeEvent } = useMixpanelContext();
  const isMobile = useRef(true);
  const refSessionsWrap = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const [recentEnrolledSessionId, setRecentEnrolledSessionId] = useState<number | null>(null);
  const navigate = useNavigate();
  const ldClient = useLDClient();
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [isFullWeeklyLimit, setIsFullWeeklyLimit] = useState<boolean>(false);
  const [currWeekStartDate, setCurrWeekStartDate] = useState(getCurrWeekStartDate(currentDate));
  const [currWeekJoin, setCurrWeekJoin] = useState(0);
  const [isCurrentWeek, setIsCurrentWeek] = useState(
    getCurrWeekStartDate(currentDate).format('YYYY-MM-DD') ===
      getCurrWeekStartDate(dayjs()).format('YYYY-MM-DD'),
  );
  const [showJoiningClassModal, setShowJoiningClassModal] = useState(false);

  const handleNewBooking = () => {
    const bookingCount = parseInt(localStorage.getItem('joiningClassModalCount') || '0', 10);

    if (bookingCount < 5) {
      setTimeout(() => setShowJoiningClassModal(true), 3000);
      localStorage.setItem('joiningClassModalCount', (bookingCount + 1).toString());
    }
  };

  const closeJoiningClassModal = () => {
    setShowJoiningClassModal(false);
  };

  // Providers
  const {
    sessions,
    upcomingSessions,
    bookedSessions,
    currentSession,
    cancelSessionD,
    enrollSessionD,
    getSessionsD,
    updateCurrentSession,
    updateIsBookedSessions,
    weeklyLimitRes,
    filterQuery,
  } = useSessionsContext();

  const dailyUpcomingSessions = dayjs(currentDate).isAfter(dayjs().add(13, 'day'))
    ? []
    : upcomingSessions?.filter((session) => dayjs(session.date).isSame(currentDate, 'day'));

  // MSG TEXT: no sessions due to filters vs no sessions today:
  // check:
  const [reviewFiltersMsg, setReviewFiltersMsg] = useState<Boolean>(false);

  const isReviewFiltersMsg = () => {
    const dailySessionsFromSessionsLength = sessions?.filter((session) =>
      dayjs(session.date).isSame(currentDate, 'day'),
    ).length;
    if (dailySessionsFromSessionsLength === 0) {
      setReviewFiltersMsg(false);
    } else {
      const dailyUpcomingSessionsLength = upcomingSessions?.filter((session) =>
        dayjs(session.date).isSame(currentDate, 'day'),
      ).length;
      if (dailySessionsFromSessionsLength !== dailyUpcomingSessionsLength) {
        if (filterQuery.filterTime.length > 0 || filterQuery.filterLevel.length > 0) {
          setReviewFiltersMsg(true);
        }
      }
    }
  };

  useEffect(() => {
    isReviewFiltersMsg();
  }, [sessions, upcomingSessions, filterQuery, currentDate]);

  const checkIfBlockedUser = useCallback(() => {
    if ([deviceId, user?.id].some((val) => val && usersBlockList.includes(val))) {
      updateStrictModalContent(<AlertModal restrictionMsgType="youHaveBeenBlocked" action />);
      mixpanelTrackEvent('UserBlocked', {
        deviceId,
        userId: user?.id,
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      });
      navigate('/block');
    }
  }, [deviceId, mixpanelTrackEvent, navigate, updateStrictModalContent, user?.id, usersBlockList]);

  useEffect(() => {
    checkIfBlockedUser();
  }, [checkIfBlockedUser, deviceId, level, navigate, user?.id, usersBlockList]);

  const buildUserData = useCallback(
    (userName: string, userId: string, localStorageName: string | null) => {
      if (user && token && localStorageName !== userName) {
        updateUserName(userName);
        try {
          localStorage.setItem(`${userId}-username`, userName);
        } catch (e: any) {
          console.log('error', e.message);
        }
      }
    },
    [token, updateUserName, user],
  );

  const openModal = useCallback(
    (modalComponent: JSX.Element) => {
      updateModalContent(modalComponent);
      // dispatch(setModalContent(modalComponent));
    },
    [updateModalContent],
  );

  // Add push notifications and red dot badge on Mondly side- for booked sessions:
  // For synchronization reasons, all booked sessions should first be reported cancelled, than (re-)booked.
  const manageNotifications = useCallback(
    async (sessionId: number, method?: string): Promise<void> => {
      const dailyNotification: Record<number, DailyNotification> = {};
      if (user?.id && bookedSessions && sessions) {
        // Cancel notification for all booked sessions
        try {
          await Promise.all(
            bookedSessions.map((session) => {
              return window.mondlyNative
                ?.lessonCanceled(`${user.id}${session.id}`)
                .then((result) => {
                  console.log(result, 'lessonCanceled', `${user.id}${session.id}`);
                });
            }),
          );
        } catch (err: any) {
          console.log(err.message);
        }

        // Rebook all sessions
        const copyBookedSessions = [...(bookedSessions || [])];
        if (method === 'add') {
          const sessionInput = sessions.filter((item) => item.id === sessionId)?.[0];
          copyBookedSessions?.push(sessionInput);
        } else if (method === 'remove') {
          const index = bookedSessions?.findIndex((item) => item.id === sessionId);
          copyBookedSessions?.splice(index, 1);
        }

        copyBookedSessions?.forEach((bSession) => {
          if (
            new Date(bSession.date).setHours(8, 30, 0) > new Date().getTime() &&
            // Check if already exist
            (!dailyNotification[new Date(bSession.date).getDate()] ||
              // Add only if the time of the session is 9
              new Date(bSession.date).getHours() === 9 ||
              // Check if the existing time in hours is not 8 (8:30)
              new Date(dailyNotification[new Date(bSession.date).getDate()].time).getHours() !== 8)
          ) {
            Object.assign(dailyNotification, {
              [new Date(bSession.date).getDate()]: {
                id: bSession.id,
                time:
                  new Date(bSession.date).getHours() === 9
                    ? new Date(bSession.date).setHours(8, 30, 0)
                    : new Date(bSession.date).setHours(9, 0, 0),
              },
            });
          }

          // send notificaiton 2min before session
          window.mondlyNative
            ?.lessonBooked(`${user.id}${bSession.id}`, new Date(bSession.date).getTime(), {
              firstNoticeMinutes: 2,
              secondNoticeMinutes: 2,
              firstNoticeBodyText: intl.formatMessage({
                id: 'sessions.firstNoticeBodyText',
              }),
            })
            .then((result) => {
              console.log(result, 'lessonBooked', new Date(bSession.date).getTime());
            })
            .catch((err) => console.log(err.message));
        });

        // send morning notificaiton local 9:00am
        Object.values(dailyNotification).forEach((item) => {
          window.mondlyNative
            ?.scheduleLocalNotification(
              `${user.id}${item.id}`,
              item.time,
              intl.formatMessage({
                id: 'sessions.scheduleLocalNotification',
              }),
            )
            .then((result) => {
              console.log(result, 'scheduleLocalNotification', item.time, `${user.id}${item.id}`);
            })
            .catch((err) => console.log(err.message));
        });
      }
    },
    [bookedSessions, intl, sessions, user?.id],
  );

  const cancelSession = async (sessionId: number) => {
    try {
      if (sessionId && user?.id && token) {
        // Report cancel session
        sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_ACTION, {
          lessonID: sessionId.toString(),
          actionID: window.mondlyNative?.lessonActionIds.CANCEL,
          lessonStartTimestamp: Math.floor(Date.now() / 1000),
        });
        // Mixpanel metric:
        mixpanelTrackEvent('SessionCancel', {
          SessionId: sessionId,
          sessionDate: currentSession?.date,
          companyId: user?.b2b.companyId,
          groupId: user?.b2b.groupId,
          groupName: user?.b2b.groupName,
          companyName: user?.b2b.companyName,
          userRoles: user?.roles,
        });
        const userEnroll = await cancelSessionD(sessionId, tenantId, user.id, token);
        // const userEnroll = await dispatch(
        //   cancelSessionD(sessionId, tenantId, user.id, token) as any,
        // );
        if (userEnroll.userCancel) {
          //
          // const updatedSessionData = sessionsData.map((innerSession) => {
          //   if (innerSession.id === sessionId) {
          //     // eslint-disable-next-line no-param-reassign
          //     delete innerSession.enrollDate;
          //   }
          //   return innerSession;
          // });
          // setSessionsData(updatedSessionData);
          //
          // Refresh sessions when cancelling session:
          if (token) {
            getSessionsD(token, lang);
            // dispatch(getSessionsD(token, lang));
          }
          if (!testWeb) {
            manageNotifications(sessionId, 'remove');
          }
        } else {
          console.log('missing params to cancel session: ', sessionId);
        }
        setOpenCancelledClassPopover(true);
        setTimeout(() => {
          setOpenCancelledClassPopover(false);
        }, 3000);
      }
    } catch (e: any) {
      console.log('error:', e.message);
      throw e;
    }
    // clean modal in reducer
    updateModalContent(undefined);
    // dispatch(setModalContent(undefined));
    // clean currentSession in reducer
    // dispatch(setCurrentSessionAC(null));
    updateCurrentSession(null);
  };

  const enrollSession = useCallback(
    async (sessionId: number) => {
      if ([deviceId, user?.id].some((val) => val && usersBlockList.includes(val))) {
        updateStrictModalContent(<AlertModal restrictionMsgType="youHaveBeenBlocked" action />);
        // dispatch(
        //   setStrictModalContent(<AlertModal restrictionMsgType="youHaveBeenBlocked" action />),
        // );
        navigate('/block');
      } else {
        try {
          const fetchedToken: string | undefined =
            params.testWeb || params.hash ? token : await fetchMondlyUserToken();
          // const fetchedToken: any = await fetchMondlyUserToken();
          if (sessionId && user?.id && fetchedToken) {
            const userEnroll = await enrollSessionD(sessionId, fetchedToken, lang);
            // const userEnroll = await dispatch(enrollSessionD(sessionId, fetchedToken, lang));
            if (userEnroll.userEnrolled) {
              // Mixpanel metric:
              mixpanelTrackEvent('SessionBook', {
                SessionId: currentSession?.id,
                sessionDate: currentSession?.date,
                companyId: user?.b2b.companyId,
                groupId: user?.b2b.groupId,
                groupName: user?.b2b.groupName,
                companyName: user?.b2b.companyName,
                userRoles: user?.roles,
              });
              sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_ACTION, {
                lessonID: sessionId.toString(),
                actionID: window.mondlyNative?.lessonActionIds.BOOK,
                lessonStartTimestamp: Math.floor(Date.now() / 1000),
              });
              setRecentEnrolledSessionId(sessionId);
              setOpenBookedClassPopover(true);
              setIsBooked(true);
              handleNewBooking();
              setTimeout(() => {
                setOpenBookedClassPopover(false);
              }, 3000);
              await getSessionsD(fetchedToken, lang);
              // await dispatch(getSessionsD(fetchedToken, lang));

              manageNotifications(sessionId, 'add');
            } else {
              if (!userEnroll.userEnrolled) {
                // if (userEnroll.reason && userEnroll.reason === ErrorTypes.INVALID_SUBSCRIPTION) {
                if (!testWeb) {
                  window.mondlyNative
                    ?.showPremiumPopup({
                      sourceID: window.mondlyNative.buttonIds.TOPBAR_PREMIUM_BUTTON,
                    })
                    .catch((err) => console.log(err.message));
                }
                setEnrollSessionErrorModal({
                  visible: true,
                  title: 'You have no free session and no credit. You need to purchase a package',
                });
                console.log({
                  msg: 'You have no free session and no credit. You need to purchase a package',
                  errorType: ErrorTypes.INVALID_TOKEN,
                });
              }
              //  else if (userEnroll.reason && userEnroll.reason === ErrorTypes.INVALID_TOKEN) {
              //   setEnrollSessionErrorModal({
              //     visible: true,
              //     title: 'Mondly Session Expired, please sign in again',
              //   });
              //   console.log({
              //     msg: 'Mondly Session Expired, please sign in again',
              //     errorType: ErrorTypes.INVALID_TOKEN,
              //   });
              // }
              console.log('failed to enroll session, ', sessionId);
            }
          }
        } catch (e: any) {
          console.log('error:', e.message);
          throw e;
        }
      }
    },
    [
      deviceId,
      enrollSessionD,
      getSessionsD,
      lang,
      manageNotifications,
      navigate,
      params.testWeb,
      testWeb,
      token,
      updateStrictModalContent,
      user?.id,
      usersBlockList,
    ],
  );

  const agreeAndBookModalContent = useMemo(() => {
    return (
      <AgreeAndBook
        buildUserData={buildUserData}
        setTermsAndConditionsModal={setTermsAndConditionsModal}
        enrollSession={enrollSession}
        resetKeyboardScroll={resetKeyboardScroll}
        lang={lang}
      />
    );
  }, [buildUserData, enrollSession, lang, resetKeyboardScroll, setTermsAndConditionsModal]);

  const onCloseAccountRequiredModal = () => {
    updateModalContent(undefined);
  };

  const actionRequiredModalContent = useMemo(() => {
    return (
      <div className="action-required-modal pre-class-action-required modal">
        <div className="pre-class-header">
          <button type="submit" className="header-btn-close" onClick={onCloseAccountRequiredModal}>
            <img src={closeImage} alt="" />
          </button>
        </div>
        <AccountRequired platform={platform} agreeAndBookModalContent={agreeAndBookModalContent} />
      </div>
    );
  }, [agreeAndBookModalContent, onCloseAccountRequiredModal, platform]);

  const ErrorModalContent = useMemo(() => {
    return (
      <div
        className="pre-class pre-class-action-required modal"
        style={{ display: 'flex', padding: '20px', gap: '10px', flexDirection: 'column' }}
      >
        <div className="pre-class-header">
          <button type="submit" className="header-btn-close" onClick={onCloseAccountRequiredModal}>
            <img src={closeImage} alt="" />
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            padding: '40px',
            alignItems: 'center',
          }}
        >
          <div className="pre-class-title">Error</div>
          <div className="pre-class-description">Token not valid</div>
        </div>
      </div>
    );
  }, [onCloseAccountRequiredModal]);

  useEffect(() => {
    if (enrollSessionErrorModal.visible) {
      updateModalContent(ErrorModalContent);
    }
  }, [enrollSessionErrorModal.visible]);

  const joinSession = useCallback(
    (sessionId: number) => {
      try {
        // const currentSessionData = sessionsData.find((session) => {
        //   return session.id === sessionId;
        // });
        // setCurrentSession(currentSessionData);
        // Send LD event for clicking the JOIN button
        ldClient?.track('join-session-click');
        // Mixpanel metric:
        mixpanelTrackEvent('JoinSession', {
          SessionId: sessionId,
          sessionDate: currentSession?.date,
          companyId: user?.b2b.companyId,
          groupId: user?.b2b.groupId,
          groupName: user?.b2b.groupName,
          companyName: user?.b2b.companyName,
          userRoles: user?.roles,
        });

        if (sessionId && user?.id) {
          let sessionUrl = `/session/${sessionId}`;
          if (testWeb) {
            sessionUrl += '?testWeb=true';
          }
          if (hash) {
            sessionUrl += `?hash=${hash}`;
          }
          navigate(sessionUrl);

          currentPageHeight.current = window.pageYOffset;

          // Report click on join session
          sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_ACTION, {
            lessonID: sessionId.toString(),
            actionID: window.mondlyNative?.lessonActionIds.JOIN,
            lessonStartTimestamp: Math.floor(Date.now() / 1000),
          });
        } else {
          console.log('missing params to join session');
        }
      } catch (e: any) {
        console.log('error:', e.message);
        throw e;
      }
    },
    [
      ldClient,
      user?.id,
      user?.name,
      // showAgreeScreen,
      testWeb,
      navigate,
      openModal,
      agreeAndBookModalContent,
    ],
  );

  const onSessionBook = useCallback(
    (sessionId: number) => {
      if (sessionId && user?.id) {
        // Guest user
        if (testWeb || user?.state !== 0) {
          updateModalContent(agreeAndBookModalContent);
        } else if (user?.state === 0) {
          updateModalContent(actionRequiredModalContent);
          // Mixpanel metric:
          // mixpanelTrackEvent('AccountRequiredModalOpen', {
          //   SessionId: sessionId,
          // });
        }
        currentPageHeight.current = window.pageYOffset;
      }
    },
    [
      actionRequiredModalContent,
      agreeAndBookModalContent,
      testWeb,
      updateModalContent,
      user?.id,
      user?.state,
    ],
  );

  // Navigate to Agree&Book if coming from iframe book session route:
  useEffect(() => {
    if (sessionbook && platform === 'web') {
      // dispatch(setCurrentSessionAC(sessionbook));
      updateCurrentSession(sessionbook);
    }
  }, []);

  useMemo(() => {
    if (sessionbook && platform === 'web' && currentSession) {
      onSessionBook(currentSession.id);
    }
  }, [sessionbook, platform, currentSession, onSessionBook]);

  //

  // isMobile
  useEffect(() => {
    isMobile.current = !(document.body.offsetWidth > 500);
  }, []);

  // Initial getSessions:
  useEffect(() => {
    function getSessionInit() {
      if (user?.id && token) {
        if (!appGetSessionsStartSent) {
          // Mixpanel for performance:
          mixpanelTimeEvent('AppInitialGetSessions'); // start the timer when initial getSessions called
          setAppGetSessionsStartSent(true);
        }
        // setIsLoadingInitSessions(true);
        getSessionsD(token, lang);
        // await dispatch(getSessionsD(token, lang));
        if (!appGetSessionsTrackSent) {
          // Mixpanel for performance:
          mixpanelTrackEvent('AppInitialGetSessions', {
            companyId: user?.b2b.companyId,
            groupId: user?.b2b.groupId,
            groupName: user?.b2b.groupName,
            companyName: user?.b2b.companyName,
            userRoles: user?.roles,
          }); // stop the timer when initial getSessions called
          mixpanelTrackEvent('AppGetSessionsFromLaunch', {
            companyId: user?.b2b.companyId,
            groupId: user?.b2b.groupId,
            groupName: user?.b2b.groupName,
            companyName: user?.b2b.companyName,
            userRoles: user?.roles,
          }); // stop the timer from launch when initial getSessions called
          setAppGetSessionsTrackSent(true);
        }
        isInitialMount.current = false;
      }
    }
    getSessionInit();
    // setIsLoadingInitSessions(false);
  }, [token, user?.id]);

  // Report Mondly screen initial tab - upcoming or booked
  useEffect(() => {
    // Report screen open upcoming or booked
    sendEventToMondly(window.mondlyNative?.analyticsTypes.GENERAL_SCREEN_OPEN, {
      screenID: !isBooked
        ? window.mondlyNative?.screenIds.UPCOMING_TAB
        : window.mondlyNative?.screenIds.BOOKED_TAB,
    });
  }, [isBooked]);

  useEffect(() => {
    // check if session is starting in 15min (trigger tabSwitcher red dot badge):
    const sessionStarting = bookedSessions?.filter((item: any) => {
      return differenceInMinutes(new Date(item.date), Date.now()) <= 15;
    });
    if (bookedSessions && sessionStarting && sessionStarting.length > 0) {
      setIsClassStarting(true);
    }
  }, [bookedSessions]);

  // Switch to "Booked" tab if isClassStarting:
  useMemo(() => {
    if (isClassStarting) {
      setIsBooked(true);
    }
  }, [isClassStarting]);

  const getIsFullWeeklyLimit = () => {
    if (weeklyLimitRes && weeklyLimitRes.userWeeklyStatus) {
      const weeklyBookedSessionsByDate: number =
        getCurrWeekBookedSessionsStatus(currentDate, weeklyLimitRes.userWeeklyStatus) || 0;
      // const bookedSessionsLength = bookedSessions ? bookedSessions.length : 0;
      if (weeklyLimitRes.groupWeeklyLimit) {
        setIsFullWeeklyLimit(
          weeklyBookedSessionsByDate > 0 &&
            currWeekJoin + weeklyBookedSessionsByDate === weeklyLimitRes?.groupWeeklyLimit,
        );
      }
    }
  };

  useEffect(() => {
    if (weeklyLimitRes) {
      setCurrWeekJoin(
        weeklyLimitRes.userWeeklyStatus?.[currWeekStartDate.format('YYYY-MM-DD')]?.attended || 0,
      );
    }
  }, [currWeekStartDate, weeklyLimitRes]);

  const currWeekDates = `(${currWeekStartDate.format('D')}
      ${
        getIsDifferentMonths(currWeekStartDate, currWeekStartDate.add(6, 'day'))
          ? dayjs(currWeekStartDate).format('MMMM').slice(0, 3)
          : ''
      }
      - ${currWeekStartDate.add(6, 'day').format('D')}
      ${currWeekStartDate.add(6, 'day').format('MMM')}).`;

  const isLoader = () => {
    const currWeekStartDateString = currWeekStartDate.format('YYYY-MM-DD');
    if (isInitialMount.current) {
      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <OvalLoader />
        </div>
      );
    }
    if (
      !isBooked &&
      weeklyLimitRes &&
      weeklyLimitRes.userWeeklyStatus &&
      weeklyLimitRes.userWeeklyStatus[currWeekStartDateString] &&
      currWeekJoin >= weeklyLimitRes?.groupWeeklyLimit
    ) {
      return '';
    }
    if (!isBooked && upcomingSessions && dailyUpcomingSessions && !dailyUpcomingSessions.length) {
      return (
        <div className="no-items-txt" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
          {reviewFiltersMsg ? (
            <p className="text-white flex justify-center">
              <FormattedMessage id="filter.reviewFiltersNoAvailableClassesForTheCriteriaSelected" />
            </p>
          ) : (
            <>
              <p className="text-white flex justify-center">
                <FormattedMessage id="datePickerBar.noClassesScheduledForThisDay" />
              </p>
              <p className="text-white flex justify-center">
                <FormattedMessage id="datePickerBar.pleaseSelectAnotherDayToBookAClass" />
              </p>
            </>
          )}
        </div>
      );
    }
    if (isBooked && bookedSessions && !bookedSessions.length) {
      return (
        <p className="no-items-txt text-white">
          <FormattedMessage id="sessions.youDontHaveAnyClassesBooked" />
        </p>
      );
    }
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <OvalLoader />
      </div>
    );
  };

  const getTranslatedMonth = (date: dayjs.Dayjs) => {
    return intl.formatMessage({
      id: `common.${dayjs(date).format('MMMM').toLowerCase()}`,
    });
  };

  // update context when tab mode (Booked/Upcoming) changes:
  useEffect(() => {
    updateIsBookedSessions(isBooked);
    mixpanelTrackEvent('PageView', {
      PageName: isBooked ? 'Booked Classes' : 'Upcoming Classes',
      userId: user?.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
  }, [isBooked, updateIsBookedSessions]);

  // refresh sessions every 1min:
  useEffect(() => {
    const refreshSessionsInterval = setInterval(() => {
      if (token) {
        getSessionsD(token, lang);
        // dispatch(getSessionsD(token, lang));
      }
    }, 60 * 1000);

    // Clear the interval when the component unmounts or when token, dispatch, or lang changes
    return () => clearInterval(refreshSessionsInterval);
  }, [getSessionsD, lang, token]);

  useEffect(() => {
    const currStartDate = getCurrWeekStartDate(currentDate);
    setCurrWeekStartDate(currStartDate);
    getIsFullWeeklyLimit();
    setIsCurrentWeek(
      currStartDate.format('YYYY-MM-DD') === getCurrWeekStartDate(dayjs()).format('YYYY-MM-DD'),
    );
    // setIsFullWeeklyLimit(fullWeekLimit);
  }, [currentDate, weeklyLimitRes, currWeekJoin]);

  useEffect(() => {
    getIsFullWeeklyLimit();
  }, [bookedSessions]);

  // should show weekly limit banner: (private group && )
  const [startOfCurrWeekDate, setStartOfCurrWeekDate] = useState<dayjs.Dayjs>(
    getCurrWeekStartDate(currentDate),
  );
  const [currWeekstatus, setCurrWeekstatus] = useState(
    getCurrWeekStatus(startOfCurrWeekDate, weeklyLimitRes),
  );

  useEffect(() => {
    setStartOfCurrWeekDate(getCurrWeekStartDate(currentDate));
    setCurrWeekstatus(getCurrWeekStatus(startOfCurrWeekDate, weeklyLimitRes));
  }, [currentDate, weeklyLimitRes]);

  useEffect(() => {
    setCurrWeekstatus(getCurrWeekStatus(startOfCurrWeekDate, weeklyLimitRes));
  }, [startOfCurrWeekDate]);

  return (
    <section
      className={`sessions__wrap ${
        (!isBooked && !upcomingSessions?.length) || (isBooked && !bookedSessions?.length)
          ? 'no-items'
          : ''
      }`}
    >
      {showJoiningClassModal && (
        <div className="modal-joining-class-wrapper">
          <JoiningClassModal onClose={closeJoiningClassModal} />
        </div>
      )}

      <div
        className={`fixed-area flex column align-center ${isSticky && !isBooked ? 'solid' : ''}`}
      >
        <TabSwitcher
          isBooked={isBooked}
          bodyScrollHeight={bodyScrollHeight}
          setIsBooked={setIsBooked}
          isClassStarting={isClassStarting}
          isSticky={isSticky}
        />
        {!isBooked && isSticky && (
          <div
            style={{
              width: '100%',
            }}
          >
            <DatePickerBar
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              lang={lang}
              exclusionDates={[]}
              nonTeachingDates={[]}
              isSticky
              onOpenFilters={onOpenFilters}
            />{' '}
          </div>
        )}
      </div>
      {/* {((!isBooked ? upcomingSessions : bookedSessions)?.length && ( */}
      <div
        className={`session__itemsWrap ${isBooked ? 'booked' : ''} ${
          isSticky && !isBooked ? 'isSticky' : ''
        }`}
        ref={refSessionsWrap}
      >
        {!isBooked && !isSticky && (
          <div
            style={{
              width: '100%',
            }}
          >
            <DatePickerBar
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              lang={lang}
              exclusionDates={[]}
              nonTeachingDates={[]}
              onOpenFilters={onOpenFilters}
            />{' '}
          </div>
        )}
        {/* case 1: not private group user */}
        {!isBooked && (!user?.b2b.groupId || !user?.isPrivate) && !user?.isWizard && (
          <UserGuideBanner
            setIsUserGuidePage={setIsUserGuidePage}
            platform={platform}
            lang={lang}
          />
        )}
        {isBooked && (
          <ClassGuideBanner
            setIsClassGuidePage={setIsClassGuidePage}
            // platform={platform}
            lang={lang}
          />
        )}

        {(!user?.b2b.groupId || !user?.isPrivate) &&
          (!isBooked ? dailyUpcomingSessions : bookedSessions)?.map((_session) => (
            <ClassCard
              isBooked={isBooked}
              session={_session}
              key={_session.id}
              cancelSession={cancelSession}
              joinSession={joinSession}
              onSessionBook={onSessionBook}
              recentEnrolledSessionId={recentEnrolledSessionId}
              setRecentEnrolledSessionId={setRecentEnrolledSessionId}
              lang={lang}
              checkIfBlockedUser={checkIfBlockedUser}
              platform={platform}
            />
          ))}
        {/* case 2: private group user */}
        {!isBooked &&
          user?.b2b.groupId &&
          user?.isPrivate &&
          isCurrentWeek &&
          weeklyLimitRes &&
          weeklyLimitRes.groupWeeklyLimit &&
          currWeekstatus.attended > 0 && (
            <WeeklyLimitBanner
              lang={lang}
              currentDate={currentDate}
              weeklyLimitRes={weeklyLimitRes}
            />
          )}
        {!isBooked &&
          user?.b2b.groupId &&
          user?.isPrivate &&
          isFullWeeklyLimit &&
          weeklyLimitRes && (
            <p className="max-weekly-limit text-white">
              {isCurrentWeek ? (
                <FormattedMessage id="weeklyLimit.maxNumberOfClassesBookedForThisWeek" />
              ) : (
                <FormattedMessage id="weeklyLimit.maxNumberOfClassesBookedForNextWeek" />
              )}
              &nbsp;{currWeekDates}&nbsp;
              <FormattedMessage id="weeklyLimit.toBookADifferentClassCancelABooking" />
            </p>
          )}

        {!isBooked && user?.b2b.groupId && user.isPrivate && (
          <UserGuideBanner
            setIsUserGuidePage={setIsUserGuidePage}
            platform={platform}
            lang={lang}
          />
        )}
        {!isBooked &&
          user?.b2b.groupId &&
          user.isPrivate &&
          weeklyLimitRes &&
          currWeekJoin < weeklyLimitRes.groupWeeklyLimit &&
          dailyUpcomingSessions?.map((_session) => (
            <ClassCard
              isBooked={isBooked}
              session={_session}
              key={_session.id}
              cancelSession={cancelSession}
              joinSession={joinSession}
              onSessionBook={onSessionBook}
              recentEnrolledSessionId={recentEnrolledSessionId}
              setRecentEnrolledSessionId={setRecentEnrolledSessionId}
              lang={lang}
              checkIfBlockedUser={checkIfBlockedUser}
              platform={platform}
              userWeeklyStatus={weeklyLimitRes.userWeeklyStatus}
              groupWeeklyLimit={weeklyLimitRes.groupWeeklyLimit}
            />
          ))}
        {!isBooked &&
          user?.b2b.groupId &&
          user.isPrivate &&
          weeklyLimitRes &&
          currWeekJoin >= weeklyLimitRes.groupWeeklyLimit && (
            <div className="no-items-txt">
              <p className="max-weekly-limit-classes text-white">
                <FormattedMessage id="weeklyLimit.youCannotBookAnyMoreClasses" />{' '}
                {`(${currWeekStartDate.format('D')}
            ${
              getIsDifferentMonths(currWeekStartDate, currWeekStartDate.add(6, 'day'))
                ? getTranslatedMonth(currWeekStartDate).slice(0, 3)
                : ''
            }
            - ${currWeekStartDate.add(6, 'day').format('D')}  ${getTranslatedMonth(
                  currWeekStartDate.add(6, 'day'),
                ).slice(0, 3)})`}
                &nbsp;
                {lang === 'ja' && 'これ以上クラスを予約できません'}
                {lang === 'ko' && '에는 더 이상 수업을 예약할 수 없습니다.'}
                {lang === 'tr' && 'daha fazla ders için yer ayıramazsınız.'}
                {lang === 'zh' && '无法预订更多课程'}
              </p>
            </div>
          )}

        {isBooked && bookedSessions && bookedSessions.length > 0 && user?.isPrivate && (
          <WeeklyLimitBookedTab
            lang={lang}
            isBooked={isBooked}
            cancelSession={cancelSession}
            joinSession={joinSession}
            onSessionBook={onSessionBook}
            recentEnrolledSessionId={recentEnrolledSessionId}
            setRecentEnrolledSessionId={setRecentEnrolledSessionId}
            checkIfBlockedUser={checkIfBlockedUser}
            platform={platform}
          />
        )}
        {!isBooked && dailyUpcomingSessions && dailyUpcomingSessions.length === 0 && isLoader()}
        {isBooked && bookedSessions && bookedSessions.length === 0 && (
          <div className={`session__noItemsWrap ${isBooked ? 'bookedTab' : ''}`}>{isLoader()}</div>
        )}
        <div className={`bottom-spacer ${isBooked ? 'isBooked' : ''}`}>
          <p>p</p>
        </div>
      </div>

      {openBookedClassPopover && (
        <BottomConfirmationPopup
          confirmationType="booked"
          msgTxt={intl.formatMessage({
            id: 'bottomConfirmationPopup.youAreNowbookedInToThisClass',
          })}
        />
      )}
      {openCancelledClassPopover && (
        <BottomConfirmationPopup
          confirmationType="cancelled"
          msgTxt={intl.formatMessage({
            id: 'bottomConfirmationPopup.yourClassBookingHaseBeenCancelled',
          })}
        />
      )}
    </section>
  );
}

export default Sessions;
