import { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSessionsContext } from '../../Providers/SessionsContext';

interface TabSwitcherProps {
  isBooked: boolean;
  bodyScrollHeight: number;
  setIsBooked: (a: boolean) => void | undefined;
  isClassStarting: boolean;
  isSticky: boolean;
}

function TabSwitcher({
  isBooked,
  bodyScrollHeight,
  setIsBooked,
  isSticky,
  isClassStarting,
}: TabSwitcherProps) {
  const [isScrolled, setIsScrolled] = useState(false);
  const refBookedBtn = useRef<HTMLButtonElement>(null);
  const refUpcomingBtn = useRef<HTMLButtonElement>(null);
  const { updateCurrentSession } = useSessionsContext();

  useEffect(() => {
    if (bodyScrollHeight > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [bodyScrollHeight]);

  const handleTabChange = (val: boolean) => {
    updateCurrentSession(null);
    setIsBooked(val);
  };

  return (
    <div
      className={`tab-switcher ${isScrolled ? 'isScrolled' : ''} ${
        isSticky && !isBooked ? 'isSticky' : ''
      } flex justify-center`}
    >
      <div
        className={`tab-switcher__wrap ${isScrolled ? 'isScrolled' : ''} ${
          isSticky && !isBooked ? 'isSticky' : ''
        }`}
      >
        <button
          type="button"
          onClick={() => handleTabChange(false)}
          className={`tab-switcher__btn ${!isBooked && 'tab-switcher__btn-active'}
          ${
            refUpcomingBtn.current && refUpcomingBtn.current?.innerText.length >= 15
              ? 'smaller-font'
              : ''
          }
          `}
          ref={refUpcomingBtn}
        >
          <FormattedMessage id="tabSwitcher.classes" />
        </button>
        <button
          type="button"
          onClick={() => handleTabChange(true)}
          className={`tab-switcher__btn ${isBooked && 'tab-switcher__btn-active'}
          ${
            refBookedBtn.current && refBookedBtn.current?.innerText.length >= 15
              ? 'smaller-font'
              : ''
          }
          `}
          ref={refBookedBtn}
        >
          <FormattedMessage id="tabSwitcher.myBookings" />
        </button>
        <div className={`active-indicator ${isBooked && 'booked'}`} />
        {isClassStarting && <div className="red-indicator" />}
      </div>
    </div>
  );
}

export default TabSwitcher;
