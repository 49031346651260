import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Session } from '../../../helpers/types';
import levelImage from '../../../assets/image/cards-level.png';
import classSizeImage from '../../../assets/image/class-size-icon-25.png';
import clockImage from '../../../assets/image/clock-icon-25.png';
import { useUserContext } from '../../../Providers/UserContext';
import {
  differenceInMinutes,
  getLangTimeFormat,
  getSimplifiedLevel,
  gseRangeToCEFR,
} from '../../../helpers/utils';

interface ClassCardBodyProps {
  isBooked: boolean;
  session: Session;
  lang: string;
  isSessionBooked: boolean;
}

function ClassCardBody({ isBooked, session, lang, isSessionBooked }: ClassCardBodyProps) {
  const refDescTextHidden = useRef<HTMLDivElement>(null);
  const refDescText = useRef<HTMLDivElement>(null);
  const refDescSeeMore = useRef<HTMLSpanElement>(null);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [descTruncatedLength, setDescTruncatedLength] = useState(0);
  const is12hFormat = getLangTimeFormat(lang);

  const { user } = useUserContext();

  const getTextWidth = (text: string, font: any) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context!.font = font || getComputedStyle(document.body).font;
    return context!.measureText(text).width;
  };

  useEffect(() => {
    // "see more" logic for any screen and font:
    if (refDescText.current !== null && refDescTextHidden.current !== null) {
      const elComputedWidth = refDescText.current.clientWidth;
      const elComputedLength = refDescTextHidden.current.innerText.length;

      const charWidth = getTextWidth('a', null);
      const charsInLine = elComputedWidth / charWidth;
      const numOfLines = elComputedLength / charsInLine;
      let maxCharsWithSeeMore = elComputedLength;

      const entryCarriagePos = refDescTextHidden.current.innerText.indexOf('\n');
      if (numOfLines > 2 || entryCarriagePos !== -1) {
        setShowSeeMore(true);
        // calculate truncation length:
        let seeMoreComputedLength = 0;
        // number of chars in line:
        maxCharsWithSeeMore = Math.floor(charsInLine * 2 - seeMoreComputedLength);
        if (entryCarriagePos !== -1) {
          maxCharsWithSeeMore = Math.min(entryCarriagePos, maxCharsWithSeeMore);
        }
        if (refDescSeeMore.current !== null) {
          seeMoreComputedLength = refDescSeeMore.current.innerText.length;
        }
      }

      //
      setDescTruncatedLength(maxCharsWithSeeMore);
    }
  }, [session.topic.description]);

  const openFullDescription = () => {
    setShowSeeMore(false);
    setDescTruncatedLength(refDescTextHidden.current!.innerText.length);
  };

  function getEnrolledUsersCalc() {
    if (session?.enrolledUsers) {
      if (session.maxParticipants > 6) {
        return session.maxParticipants - session.enrolledUsers <= 1
          ? session.maxParticipants / 2 - 1
          : Math.ceil(session.enrolledUsers / 2);
      }
      return session?.enrolledUsers;
    }
    return 0;
  }

  return (
    <section className="class-card-body-container" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="card-main">
        <div className="class-info-item class-level flex justify-start align-center">
          <img className="card-icon-img" src={levelImage} alt="Level-icon" />
          <FormattedMessage
            id={`classCard.level.${getSimplifiedLevel(session.gseLevelId)}`}
            defaultMessage={getSimplifiedLevel(session.gseLevelId)}
          />{' '}
          &#183; {gseRangeToCEFR(session.topic.minGSE, session.topic.maxGSE)}
        </div>
        <div className="class-info-item flex justify-start align-center">
          <img className="card-icon-img" src={clockImage} alt="clock-icon" />
          {(differenceInMinutes(new Date(session?.date), Date.now()) <= 15 && isBooked) ||
          (differenceInMinutes(new Date(session?.date), Date.now()) <= 15 && isSessionBooked) ? (
            <div className="warning">
              <p>
                {lang !== 'zh' && (
                  <span>
                    <FormattedMessage id="classCard.your" />
                    &nbsp;
                  </span>
                )}
                {session && (
                  <span>
                    <FormattedDate
                      value={new Date(session?.date)}
                      hour={is12hFormat ? 'numeric' : '2-digit'}
                      minute={is12hFormat ? 'numeric' : '2-digit'}
                    />
                  </span>
                )}
                &nbsp;
                <FormattedMessage id="classCard.classIsReady" />
              </p>
            </div>
          ) : (
            <div className="">
              {isBooked ? (
                <div style={{ textTransform: 'capitalize' }}>
                  <FormattedDate
                    weekday="long"
                    day="numeric"
                    month="long"
                    hour={is12hFormat ? 'numeric' : '2-digit'}
                    minute={is12hFormat ? 'numeric' : '2-digit'}
                    value={new Date(session?.date)}
                  />{' '}
                </div>
              ) : (
                <FormattedDate
                  hour={is12hFormat ? 'numeric' : '2-digit'}
                  minute={is12hFormat ? 'numeric' : '2-digit'}
                  value={new Date(session?.date)}
                />
              )}
            </div>
          )}
        </div>

        <div
          className="class-info-item class-size flex justify-start align-center"
          style={{ paddingInlineStart: '1px' }}
        >
          <img className="card-icon-img" src={classSizeImage} alt="call-size-icon" />
          {/* case 1: class is full */}
          {!isBooked && !isSessionBooked && session?.enrolledUsers === session.maxParticipants && (
            <div className="warning">This class is full</div>
          )}
          {/* case 2: yellow (warning) bold text if class is n-1/n full */}

          {!isBooked && !isSessionBooked && session?.enrolledUsers !== session.maxParticipants && (
            <div className={session?.enrolledUsers >= session.maxParticipants - 2 ? 'warning' : ''}>
              {lang === 'zh' && <span>将有 </span>}
              {session.maxParticipants > 6 && !user?.isWizard
                ? `${getEnrolledUsersCalc()}/${session.maxParticipants / 2} `
                : `${getEnrolledUsersCalc()}/${session.maxParticipants} `}
              <FormattedMessage id="classCard.peopleAreAttendingThisClass" />
            </div>
          )}
          {/* case 3: booked */}
          {(isBooked || (!isBooked && isSessionBooked)) && (
            <div className="warning">
              <FormattedMessage id="classCard.youAreBookedIn" />
            </div>
          )}
        </div>

        {session?.topic?.description && (
          <div className="class-description" dir="auto">
            <p className="reference-desc hidden" ref={refDescTextHidden}>
              {session.topic.description}
            </p>
            <p ref={refDescText}>
              {session.topic.description.substring(0, descTruncatedLength)}
              {showSeeMore && <span>... </span>}
              {showSeeMore && (
                <span
                  aria-hidden
                  ref={refDescSeeMore}
                  className="see-more"
                  onClick={() => openFullDescription()}
                >
                  <FormattedMessage id="classCard.seeMore" />
                </span>
              )}
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

export default ClassCardBody;
