import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useModalsContext } from '../../Providers/ModalsContext';
import { useDeviceContext } from '../../Providers/DeviceContext';
import RestricitonAlert from './RestrictionAlert';

interface AlertModalProps {
  restrictionMsgType: string;
  action?: boolean;
}

function AlertModal({ restrictionMsgType, action }: AlertModalProps) {
  const { os } = useDeviceContext();
  const { updateCurrentSession } = useSessionsContext();
  const { updateModalContent, updateStrictModalContent } = useModalsContext();
  // const intl = useIntl();
  const handleOnAlertModalClose = async () => {
    // dispatch(setModalContent(undefined));
    // dispatch(setStrictModalContent(undefined));
    updateModalContent(undefined);
    updateStrictModalContent(undefined);
    if (action) {
      window.mondlyNative?.closeWebView().catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    updateCurrentSession(null);
    // dispatch(setCurrentSessionAC(null));
  }, [updateCurrentSession]);

  return (
    <section className="system-alert-modal">
      <div className={`${os !== 'iOS' ? 'android' : ''} modal-panel`}>
        <div className={`modal-content${os !== 'iOS' ? '-android' : ''}`}>
          <h4 className={`${os !== 'iOS' ? 'title' : 'modal-content__title'}`}>
            <RestricitonAlert restrictionMsgType={restrictionMsgType} />
          </h4>
        </div>
        <div className={`modal-footer${os !== 'iOS' ? '-android' : ''}`}>
          <button
            className={`modal-btn${os !== 'iOS' ? '-android' : ' modal-btn__default'}`}
            type="button"
            onClick={() => handleOnAlertModalClose()}
          >
            <FormattedMessage id="restrictionAlert.ok" />
          </button>
        </div>
      </div>
    </section>
  );
}
export default AlertModal;
