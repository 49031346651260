import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { ThemeProvider } from './Providers/ThemeProvider';
import { UserProvider } from './Providers/UserContext';
import { MixpanelProvider } from './Providers/MixpanelContext';
import App from './App';
import { SessionsProvider } from './Providers/SessionsContext';
import { DeviceProvider } from './Providers/DeviceContext';
import { ModalsProvider } from './Providers/ModalsContext';
import './assets/themes/v1.css';
import './assets/themes/v2.css';
import enDictionary from './translations/en.json';
import esDictionary from './translations/es.json';
import ptDictionary from './translations/br.json';
import frDictionary from './translations/fr.json';
import plDictionary from './translations/pl.json';
import arDictionary from './translations/ar.json';
import roDictionary from './translations/ro.json';
import itDictionary from './translations/it.json';
import deDictionary from './translations/de.json';
import trDictionary from './translations/tr.json';
import koDictionary from './translations/ko.json';
import jaDictionary from './translations/ja.json';
import zhDictionary from './translations/zh.json';
import viDictionary from './translations/vi.json';
import idDictionary from './translations/id.json';

// const NavLocale = navigator.language;
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
let locale;
let dictionary;
let ThemeName;

switch (params.mother_lang) {
  case 'en_us':
    dictionary = enDictionary;
    locale = 'en-US';
    break;
  case 'es':
    dictionary = esDictionary;
    locale = 'es';
    break;
  case 'br':
    dictionary = ptDictionary;
    locale = 'pt-BR';
    break;
  case 'fr':
    dictionary = frDictionary;
    locale = 'fr';
    break;
  case 'pl':
    dictionary = plDictionary;
    locale = 'pl';
    break;
  case 'ar':
    dictionary = arDictionary;
    locale = 'ar';
    break;
  case 'ro':
    dictionary = roDictionary;
    locale = 'ro';
    break;
  case 'it':
    dictionary = itDictionary;
    locale = 'it';
    break;
  case 'de':
    dictionary = deDictionary;
    locale = 'de';
    break;
  case 'tr':
    dictionary = trDictionary;
    locale = 'tr';
    break;
  case 'ko':
    dictionary = koDictionary;
    locale = 'ko';
    break;
  case 'ja':
    dictionary = jaDictionary;
    locale = 'ja';
    break;
  case 'zh':
    dictionary = zhDictionary;
    locale = 'zh';
    break;
  case 'vi':
    dictionary = viDictionary;
    locale = 'vi';
    break;
  case 'id':
    dictionary = idDictionary;
    locale = 'id';
    break;
  default:
    dictionary = enDictionary;
    locale = 'en-US';
    break;
}

switch (params.theme) {
  case 'v1':
    ThemeName = 'v1';
    break;
  case 'v2':
    ThemeName = 'v2';
    break;
  default:
    ThemeName = 'v2';
    break;
}

const level = parseInt(params.difficulty, 10) || 1;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <LDProvider clientSideID={process.env.REACT_APP_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID || '-1'}>
    <MixpanelProvider>
      <UserProvider>
        <IntlProvider locale={locale} messages={dictionary}>
          <SessionsProvider level={level}>
            <ThemeProvider themeName={ThemeName}>
              <ModalsProvider>
                <DeviceProvider>
                  <App rawLang={params.mother_lang} level={level} platform={params.platform} />
                </DeviceProvider>
              </ModalsProvider>
            </ThemeProvider>
          </SessionsProvider>
        </IntlProvider>
      </UserProvider>
    </MixpanelProvider>
  </LDProvider>,
);
