import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../Providers/UserContext';
// import { setModalContent } from '../../redux/actionCreators/ModalsReducerAC';
// import { setCurrentSessionAC } from '../../redux/actionCreators/SessionsReducerAC';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useModalsContext } from '../../Providers/ModalsContext';
import { getSession } from '../../helpers/Network';
import PreClassScreen from '../Modals/PreClassScreen';
import closeImage from '../../assets/image/close.png';
// import { setModalContent } from '../../redux/actionCreators/ModalsReducerAC';
import { sendEventToMondly } from '../../helpers/Event';

// eslint-disable-next-line react/prop-types
function LiveSession({ onShowBottomPopup, usersBlockList, deviceId, lang, platform }) {
  const { sessionId } = useParams();
  const { user, token } = useUserContext();
  // const { modalContent } = useTypedSelector((state) => state.modals);
  // const { user, token } = useTypedSelector((state) => state.user);
  const { updateModalContent } = useModalsContext();
  const { updateCurrentSession } = useSessionsContext();
  const navigate = useNavigate();
  const intl = useIntl();
  const showPreClass = false;

  const setAppCurrentSession = useCallback(async () => {
    try {
      await updateCurrentSession(sessionId, token);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const preClassModalContent = useMemo(() => {
    return (
      <div className="modal pre-class-modal">
        <button
          type="button"
          className="close-button"
          onClick={() => {
            // Report close preclass screen
            sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_SCREEN_QUIT, {
              screenID: user?.name
                ? window.mondlyNative.screenIds.WAITING
                : window.mondlyNative.screenIds.PRE_SESSION,
              lessonID: sessionId.toString(),
            });
            // navigate('/?showBooked=1');
            navigate('/');
            updateModalContent(undefined);
          }}
          aria-label="Close modal"
        >
          <img src={closeImage} alt="close-icon" className="close-icon-img" />
        </button>
        <PreClassScreen
          user={user}
          onShowBottomPopup={onShowBottomPopup}
          usersBlockList={usersBlockList}
          deviceId={deviceId}
          lang={lang}
          platform={platform}
        />
      </div>
    );
  }, [
    deviceId,
    lang,
    navigate,
    onShowBottomPopup,
    platform,
    sessionId,
    updateModalContent,
    user,
    usersBlockList,
  ]);

  const loadSessionData = useCallback(async () => {
    try {
      const currentSessionData = await getSession(sessionId, token);
      setAppCurrentSession(sessionId);

      if (token && user) {
        if (currentSessionData?.isEnroll) {
          // setShowPreClass(true);
          updateModalContent(preClassModalContent);
          // dispatch(setModalContent(preClassModalContent));
        } else {
          onShowBottomPopup(
            'error',
            intl.formatMessage({
              id: 'bottomConfirmationPopup.ThereWasAnErrorLoadingYourSession',
            }),
          );
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error:', e.message);
      throw e;
    }
  }, [
    intl,
    onShowBottomPopup,
    preClassModalContent,
    sessionId,
    setAppCurrentSession,
    token,
    updateModalContent,
    user,
  ]);

  useEffect(() => {
    // Load session data
    loadSessionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, user]);

  return (
    <section
      className={`live-session  sessions__wrap loading-wrapper ${
        showPreClass ? 'pre-class-wrap' : ''
      }`}
    >
      {!showPreClass && (
        <div className="class-loader" style={{ height: '100%', transform: 'translate(0, 40%)' }}>
          <div className="dot-pulse" />
        </div>
      )}
    </section>
  );
}
export default LiveSession;
