import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useModalsContext } from '../../Providers/ModalsContext';
import { useDeviceContext } from '../../Providers/DeviceContext';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import BetaTag from '../Shared/BetaTag';
import { useUserContext } from '../../Providers/UserContext';
import { getIsUserLiveTutoring } from '../../helpers/utils';

interface AccountReqiuredProps {
  platform: string;
  agreeAndBookModalContent: JSX.Element;
}

function AccountReqiured({ platform, agreeAndBookModalContent }: AccountReqiuredProps) {
  const { currentSession } = useSessionsContext();
  const { updateModalContent } = useModalsContext();
  const { user, fetchUser } = useUserContext();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { os } = useDeviceContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const refCreateAccountBtn = useRef<HTMLButtonElement>(null);

  const openMondlySignIn = () => {
    try {
      window.mondlyNative
        ?.showSignInPopup(
          { sourceID: window?.mondlyNative?.buttonIds.LIVE_TUTORING_ITEM },
          `sessionbook/${currentSession?.id}`,
        )
        .then(async (res) => {
          if (res) {
            // Mixpanel metric:
            mixpanelTrackEvent('openMondlySignIn', {
              SessionId: currentSession?.id,
              companyId: user?.b2b.companyId,
              groupId: user?.b2b.groupId,
              groupName: user?.b2b.groupName,
              companyName: user?.b2b.companyName,
              userRoles: user?.roles,
            });

            // update user:
            await fetchUser(!!params.testWeb);
            if (platform !== 'web') {
              // Open agree and book modal
              // dispatch(setModalContent(agreeAndBookModalContent));
              updateModalContent(agreeAndBookModalContent);
            }
          }
        })
        .catch((err) => console.log('BOOM - registration failed', err.message));
    } catch (e) {
      console.log('BOOM - registration failed to trigger', e);
    }
  };

  return (
    <section className="pre-class-screen account-required-container">
      {user && !getIsUserLiveTutoring(user.roles) && <BetaTag />}
      <div className="pre-class-title account-required-title">
        <FormattedMessage id="preClassScreen.accountRequiredTitle" />
      </div>
      <div className="action-required-description">
        <FormattedMessage id="preClassScreen.accountRequired" />
      </div>

      <div
        className={`pre-class-join-wrp account-required ${
          os !== 'iOS' &&
          refCreateAccountBtn.current &&
          refCreateAccountBtn.current?.innerText.length > 15
            ? 'smaller-font'
            : ''
        } `}
      >
        <button
          type="button"
          className="cta-btn pre-class-join-btn account-required"
          onClick={() => openMondlySignIn()}
          ref={refCreateAccountBtn}
        >
          <FormattedMessage id="preClassScreen.createAccount" />
        </button>
        <button
          type="button"
          className="action-required-login-btn account-required"
          onClick={() => openMondlySignIn()}
        >
          <FormattedMessage id="preClassScreen.logIn" />
        </button>
      </div>
    </section>
  );
}
export default AccountReqiured;
