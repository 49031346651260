interface BetaTagProps {
  inline?: boolean;
}
function BetaTag({ inline = false }: BetaTagProps) {
  return (
    <section className={`beta-tag ${inline ? 'inline' : ''}`}>
      <div className="beta-tag-txt">Beta</div>
    </section>
  );
}

export default BetaTag;
