import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
// import { useSearchParams } from 'react-router-dom';
// import { useLDClient } from 'launchdarkly-react-client-sdk';
// import { useUserContext } from '../../../Providers/UserContext';
// import { useSessionsContext } from '../../../Providers/SessionsContext';
// import { useMixpanelContext } from '../../../Providers/MixpanelContext';
import { Session } from '../../../helpers/types';
// import notificationImage from '../../../assets/image/notification-icon-24.png';
// import notificationOffImage from '../../../assets/image/notificationoff-icon-24.png';
import { TIME_TILL_ENABLE_BOOKING, getLangTimeFormat } from '../../../helpers/utils';

interface BookRestrictionBtnProps {
  session: Session;
  lang: string;
  // setShowNotifyMeSuccess: (value: boolean) => void;
  // platform?: string;
}

function BookRestrictionBtn({
  session,
  lang,
}: // setShowNotifyMeSuccess,
// platform,
BookRestrictionBtnProps) {
  // const { user } = useUserContext();
  // const { mixpanelTrackEvent } = useMixpanelContext();
  // const { sessions, notifyMeSessions, updateNotifyMeSessions } = useSessionsContext();
  // const [isNotifyMeClicked, setIsNotifyMeClicked] = useState(false);
  // const [queryParams] = useSearchParams();
  // const testWeb = queryParams.get('testWeb');

  const timeTillSessionHours = (new Date(session.date).getTime() - new Date().getTime()) / 3600000;
  const [timeTillSessionEnable, setTimeTillSessionEnable] = useState(
    timeTillSessionHours - TIME_TILL_ENABLE_BOOKING < 0,
  );
  const is12hFormat = getLangTimeFormat(lang);

  // LAUNCHDARKLY:
  // const ldClient = useLDClient();
  // const { isNotifyMe } = useFlags();

  // useEffect(() => {
  //   if (notifyMeSessions?.length) {
  //     if (notifyMeSessions.some((el) => el.id === session.id)) {
  //       setIsNotifyMeClicked(true);
  //     }
  //   }
  // }, [notifyMeSessions, session.id]);

  useEffect(() => {
    const checkEnableBookButtonInterval = setInterval(() => {
      setTimeTillSessionEnable(
        (new Date(session.date).getTime() - new Date().getTime()) / 3600000 -
          TIME_TILL_ENABLE_BOOKING <
          0,
      );
    }, 60 * 1000);

    return () => clearInterval(checkEnableBookButtonInterval);
  }, [session.date, timeTillSessionEnable]);

  // const sendLdNotifyMeMetric = () => {
  //   try {
  //     ldClient?.track('notify-me-clicked');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const onNotityMe = (method: string) => {
  //   if (sessions && user) {
  //     if (method === 'add') {
  //       try {
  //         updateNotifyMeSessions(session, method, lang);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //       setIsNotifyMeClicked(true);
  //       setShowNotifyMeSuccess(true);
  //       setTimeout(() => {
  //         setShowNotifyMeSuccess(false);
  //       }, 3000);
  //     }
  //     if (method === 'remove') {
  //       try {
  //         updateNotifyMeSessions(session, method, lang);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //       setIsNotifyMeClicked(false);
  //     }
  //     // Mixpanel metric:
  //     mixpanelTrackEvent('NotifyMeClicked', {
  //       method,
  //     });
  //   }
  //   sendLdNotifyMeMetric();
  // };

  return (
    <section className="book-restriction-btn-container" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      {/* {(isNotifyMe && user?.state !== 0 && lang === 'es' && platform !== 'web') ||
      (testWeb && platform !== 'web' && lang === 'es') ? (
        //   case 1: NotifyMe btn
        <div className="notify-me-container">
          <div className="time-till-book-text">
            <FormattedMessage id="classCard.bookOpen" />{' '}
            <FormattedDate
              weekday="long"
              day="numeric"
              month="short"
              hour="2-digit"
              minute="2-digit"
              value={
                new Date(
                  new Date(session?.date).setHours(
                    new Date(session.date).getHours() - TIME_TILL_ENABLE_BOOKING,
                  ),
                )
              }
            />
          </div>
          <button
            type="button"
            className="cta-btn card-cta notify-me secondary-btn"
            // onClick={() => onNotityMe(!isNotifyMeClicked ? 'add' : 'remove')}
            onClick={() => onNotityMe(!isNotifyMeClicked ? 'add' : 'remove')}
          >
            <div className="notify-me-btn-content">
              <img
                className="notification-icon"
                src={!isNotifyMeClicked ? notificationImage : notificationOffImage}
                alt={!isNotifyMeClicked ? 'notification-icon' : 'notification-off-icon'}
              />
              {!isNotifyMeClicked ? (
                <FormattedMessage id="classCard.notifyMe" />
              ) : (
                <FormattedMessage id="classCard.turnOffNotification" />
              )}
            </div>
          </button>
        </div>
      ) : ( */}
      {/* // case 2: book restriction btn */}
      <div className="flex justify-center">
        <button type="button" className="cta-btn card-cta disable-book-session">
          <div>
            <div>
              <FormattedMessage id="classCard.bookOpen" />
            </div>
            <div className="time-till-book-text capitalize">
              <FormattedDate
                weekday="long"
                day="numeric"
                month="short"
                hour={is12hFormat ? 'numeric' : '2-digit'}
                minute={is12hFormat ? 'numeric' : '2-digit'}
                value={
                  new Date(
                    new Date(session?.date).setHours(
                      new Date(session.date).getHours() - TIME_TILL_ENABLE_BOOKING,
                    ),
                  )
                }
              />
            </div>
          </div>
        </button>
      </div>
      {/* )} */}
    </section>
  );
}

export default BookRestrictionBtn;
