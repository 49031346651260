import { createContext, useContext, memo, useEffect } from 'react';

const ThemeNames: string[] = ['v1', 'v2'];
const ThemeContext = createContext(ThemeNames[0]);

interface ThemeProviderProps {
  children: any;
  themeName: string;
}

export const ThemeProvider = memo(({ children, themeName }: ThemeProviderProps) => {
  const getThemeClassName = `${themeName}-theme`;

  useEffect(() => {
    const { classList } = document.body;
    classList.remove(...ThemeNames);
    classList.add(getThemeClassName);
  }, [getThemeClassName, themeName]);

  return <ThemeContext.Provider value={themeName}>{children}</ThemeContext.Provider>;
});

export const useLayoutModeContext = () => ({
  themeName: useContext(ThemeContext),
});
