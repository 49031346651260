import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { useUserContext } from '../../Providers/UserContext';
import { ReactComponent as UserGuideImg } from '../../assets/image/user-guide.svg';
import { ReactComponent as CaretRight } from '../../assets/image/caret-right-light-24.svg';
import { ReactComponent as NewTab } from '../../assets/image/arrow-square-out.svg';

interface UserGuideBannerProps {
  setIsUserGuidePage: (val: boolean) => void;
  platform: string;
  lang: string;
}

function UserGuideBanner({ setIsUserGuidePage, platform, lang }: UserGuideBannerProps) {
  const navigate = useNavigate();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();

  const handleNavigateToLearnerGuide = () => {
    mixpanelTrackEvent('LevelGuideBannerClicked', {
      userId: user?.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
    if (platform === 'web') {
      const learnerGuideUrl = `/learnerGuide/?testWeb=true&platform=web&mother_lang=${lang}`;
      window.open(learnerGuideUrl, '_blank');
    } else {
      setIsUserGuidePage(true);
      navigate('/learnerguide');
    }
  };

  const getProgressImage = () => {
    return platform === 'web' ? <NewTab /> : <CaretRight />;
  };

  return (
    <section
      className="user-guide-banner flex space-between"
      role="button"
      tabIndex={0}
      onClick={() => handleNavigateToLearnerGuide()}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') handleNavigateToLearnerGuide();
      }}
    >
      <div className={`user-guide-banner-image-text flex gap-8 ${lang === 'ar' ? 'rtl' : ''}`}>
        <div className="user-guide-img" style={{ height: '44px' }}>
          <UserGuideImg />
        </div>
        <div>
          <p className="title" dir="auto">
            <FormattedMessage id="userGuideBanner.whichClassIsRightForMe" />
          </p>
          <p className="subtitle" dir="auto">
            <FormattedMessage id="userGuideBanner.clickHereToLearnMore" />
          </p>
        </div>
      </div>
      <div className="user-guide-img" style={{ height: '24px' }}>
        {getProgressImage()}
      </div>
    </section>
  );
}

export default UserGuideBanner;
