import dayjs from 'dayjs';
import { Session, User, ItemIdWithExpiry, UserWeeklyStatus } from './types';

export const TIME_TILL_ENABLE_BOOKING = 8;

export const getMobileOperatingSystem: any = () => {
  const userAgent = navigator && (navigator.userAgent || navigator.vendor);
  if (/iPad|iPhone|iPod|iPadOS|macOS/.test(userAgent)) {
    return 'iOS';
  }
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (userAgent.indexOf('Windows') !== -1) {
    return 'Windows';
  }
  // fallback:
  return 'iOS';
};

export const getWeekDays: any = (locale: string = 'en') => {
  const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  const weekDays = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'long' }));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};

// var weekDays = getWeekDays('nl-NL');

export const getLangDir = (lang: string = 'en') => {
  return lang === 'ar' ? 'rtl' : 'ltr';
};

export const getAvailableSessionsNumber = (sessions: Session[]) => {
  // filter our sessions the are: unavailable for booking (more than restriction hours), already booked or lessons in the past
  return sessions.filter(
    (session) =>
      (new Date(session.date).getTime() - new Date().getTime()) / 3600000 -
        TIME_TILL_ENABLE_BOOKING <
        0 &&
      new Date(session.date).getTime() > new Date().getTime() &&
      !session.isEnroll,
  ).length;
};

export const differenceInMinutes = (timeA: Date, timeB: number) => {
  return (timeA.valueOf() - timeB) / (1000 * 60);
};

export const getIsUserLiveTutoring = (userRoles: User['roles']) => {
  if (userRoles.liveTutoring) {
    return true;
  }
  if (userRoles.premiumLiveTutoring) {
    return true;
  }
  if (userRoles.premium) {
    return true;
  }
  return false;
};

export const getFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(`${key}`);
  } catch (e) {
    console.log(e);
    return '';
  }
};

export function getFromSessionStorage(key: string, defaultValue: any) {
  try {
    const storedData = sessionStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultValue;
  } catch (error) {
    console.error(`Error retrieving data from sessionStorage for key "${key}":`, error);
    return defaultValue;
  }
}

export function setToSessionStorage(key: string, data: any) {
  try {
    const serializedData = JSON.stringify(data);
    sessionStorage.setItem(key, serializedData);
  } catch (error) {
    console.error(`Error storing data in sessionStorage for key "${key}":`, error);
  }
}

export function downloadTextAsFile(blob: Blob, filename: string) {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename;

  // Append the anchor element to the body
  document.body.appendChild(a);

  // Programmatically trigger a click event on the anchor element
  a.click();

  // Remove the temporary anchor element from the document
  document.body.removeChild(a);
}

export function setArrayWithExpiry(key: string, arrayWithExpiry: ItemIdWithExpiry[]) {
  localStorage.setItem(key, JSON.stringify(arrayWithExpiry));
}

export function getArrayWithExpiry(key: string) {
  // get array:
  const itemsArray = localStorage.getItem(key);
  if (!itemsArray) {
    return [];
  }
  // filter expired elements:
  const localStorageNotifyMeSessions = JSON.parse(itemsArray);
  const now = new Date();
  const filteredNotifyMeSessionsIds = localStorageNotifyMeSessions.filter(
    (item: ItemIdWithExpiry) => now.getTime() < item.expiry,
  );
  // return filtered array:
  return filteredNotifyMeSessionsIds;
}

export const getFormattedLangForLocale = (lang: string) => {
  switch (lang) {
    case 'en_us':
      return 'en-US';
    case 'en_gb':
      return 'en-GB';
    case 'es':
      return 'es';
    case 'br':
      return 'pt-BR';
    case 'fr':
      return 'fr';
    case 'pl':
      return 'pl';
    case 'ar':
      return 'ar';
    case 'ro':
      return 'ro';
    case 'it':
      return 'it';
    case 'de':
      return 'de';
    case 'tr':
      return 'tr';
    case 'ko':
      return 'ko';
    case 'ja':
      return 'ja';
    case 'zh':
      return 'zh';
    case 'vi':
      return 'vi';
    case 'id':
      return 'id';
    default:
      return 'en';
  }
};

export const getLangTimeFormat = (lang: string) => {
  const userLocale = lang;
  const is12HourFormat = new Intl.DateTimeFormat(userLocale, { hour: 'numeric' }).resolvedOptions()
    .hour12;
  return is12HourFormat;
};

export const getSimplifiedLevel = (levelId: number) => {
  if (levelId <= 3) {
    return 'Beginner';
  }
  if (levelId > 3 && levelId < 8) {
    return 'Intermediate';
  }
  if (levelId >= 8) {
    return 'Advanced';
  }
  return 'Beginner';
};

export const getSimplifiedLevelNumeric = (levelId: number) => {
  if (levelId <= 3) {
    return 1;
  }
  if (levelId > 3 && levelId < 8) {
    return 2;
  }
  if (levelId >= 8) {
    return 3;
  }
  return 1;
};

export interface GSELevel {
  id: number;
  code: string;
  level: string;
  minGSE: number;
  maxGSE: number;
}

export const allGSELevels: GSELevel[] = [
  {
    id: 1,
    level: 'A1 (22-29)',
    minGSE: 22,
    maxGSE: 29,
    code: 'A1',
  },
  {
    id: 2,
    level: 'A2 (30-35)',
    minGSE: 30,
    maxGSE: 35,
    code: 'A2',
  },
  {
    id: 3,
    level: 'A2+ (36-42)',
    minGSE: 36,
    maxGSE: 42,
    code: 'A2+',
  },
  {
    id: 4,
    level: 'B1 (43-50)',
    minGSE: 43,
    maxGSE: 50,
    code: 'B1',
  },
  {
    id: 5,
    level: 'B1+ (51-58)',
    minGSE: 51,
    maxGSE: 58,
    code: 'B1+',
  },
  {
    id: 6,
    level: 'B2 (59-66)',
    minGSE: 59,
    maxGSE: 66,
    code: 'B2',
  },
  {
    id: 7,
    level: 'B2+ (67-75)',
    minGSE: 67,
    maxGSE: 75,
    code: 'B2+',
  },
  {
    id: 8,
    level: 'C1 (76-84)',
    minGSE: 76,
    maxGSE: 84,
    code: 'C1',
  },
  {
    id: 9,
    level: 'C2 (85-90)',
    minGSE: 85,
    maxGSE: 90,
    code: 'C2',
  },
];

interface Level {
  range: [number, number];
  level: string;
}

const levels: Level[] = [
  { range: [10, 29], level: 'A1' },
  { range: [30, 35], level: 'A2' },
  { range: [36, 42], level: 'A2+' },
  { range: [43, 50], level: 'B1' },
  { range: [51, 58], level: 'B1+' },
  { range: [59, 66], level: 'B2' },
  { range: [67, 75], level: 'B2+' },
  { range: [76, 84], level: 'C1' },
  { range: [85, 90], level: 'C2' },
];

export const gseRangeToCEFR = (min: number, max: number) => {
  const compatibleLevels: string[] = [];
  levels.forEach(({ range, level }) => {
    const [rangeMin, rangeMax] = range;
    if ((min <= rangeMax && max >= rangeMin) || (max >= rangeMin && min <= rangeMax)) {
      compatibleLevels.push(level);
    }
  });

  return compatibleLevels.join('/');
};

export function GSELevelCodeToCEFR(id: number) {
  return allGSELevels.find((level) => level.id === id)?.code || null;
}

export function getPreviousMonday(date: dayjs.Dayjs, startOfWeekDay: number) {
  const today = dayjs(date);
  const dayOfWeek = today.day();
  if (dayOfWeek === startOfWeekDay) {
    return today.subtract(7, 'day');
  }
  const daysSinceLastMonday = (dayOfWeek + 6) % 7;
  return today.subtract(daysSinceLastMonday, 'day');
}

export const getNextStartOfWeekDay = (today: dayjs.Dayjs) => {
  const dayOfWeek = today.day();
  // TODO: activate when startOfWeekDay is dynamic:
  // if (startOfWeekDay === 0) {
  //   // Calculate days until next Sunday
  //   const daysUntilNextSunday = (7 - dayOfWeek) % 7 || 7;
  //   return today.add(daysUntilNextSunday, 'day');
  // } else {
  // Calculate days until next Monday
  const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
  return today.add(daysUntilNextMonday, 'day');
  // }
};

export function getCurrWeekStartDate(date: dayjs.Dayjs) {
  const dayOfWeek = date.day(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  // If it's Monday, return the date itself
  if (dayOfWeek === 1) {
    return date;
  }
  // Otherwise, subtract the necessary number of days to get to the previous Monday
  const startDate = date.subtract(dayOfWeek === 0 ? 6 : dayOfWeek - 1, 'day');
  return dayjs(startDate);
}

export const getIsDifferentMonths = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
  return start.month() !== end.month();
};

export function getCurrWeekBookedSessionsStatus(
  date: dayjs.Dayjs,
  userWeeklyStatus: UserWeeklyStatus,
): number {
  const weekstartDate = getCurrWeekStartDate(date);
  const formattedDate = weekstartDate.format('YYYY-MM-DD') as keyof typeof userWeeklyStatus;
  if (formattedDate in userWeeklyStatus) {
    return userWeeklyStatus[formattedDate].booked;
  }
  return 0;
}

export function getCurrWeekStatus(
  date: dayjs.Dayjs,
  weeklyLimitRes: { userWeeklyStatus: { [x: string]: any } } | undefined,
) {
  const formattedDate = date.format('YYYY-MM-DD');
  const weekStartDate = getCurrWeekStartDate(date).format('YYYY-MM-DD');
  if (
    weeklyLimitRes &&
    weeklyLimitRes.userWeeklyStatus &&
    weeklyLimitRes.userWeeklyStatus[weekStartDate] &&
    formattedDate in weeklyLimitRes.userWeeklyStatus
  ) {
    return weeklyLimitRes.userWeeklyStatus[formattedDate];
  }
  return { attended: 0, booked: 0 };
}

export const getHrsSymbolByLang = (lang: string) => {
  switch (lang) {
    case 'es':
      return 'h';
    case 'po':
      return 'godz.';
    case 'ko':
      return '시간';
    case 'ja':
      return '時間';
    case 'ru':
      return 'ч.';
    case 'zh':
      return '小时';
    case 'id':
      return 'j';

    default:
      return 'h';
  }
};

export const getMinsSymbolByLang = (lang: string) => {
  switch (lang) {
    case 'ar':
      return 'دقيقة';
    case 'es':
      return 'min';
    case 'de':
      return 'Min';
    case 'br':
      return 'min';
    case 'ro':
      return 'min';
    case 'ko':
      return '분';
    case 'ja':
      return '分';
    case 'ru':
      return 'м.';
    case 'tr':
      return 'dk';
    case 'vi':
      return 'phút';
    case 'zh':
      return '分钟';

    default:
      return 'mins';
  }
};

export const getMinSymbolByLang = (lang: string) => {
  switch (lang) {
    case 'ar':
      return 'دقيقة';
    case 'es':
      return 'min';
    case 'de':
      return 'Min';
    case 'br':
      return 'min';
    case 'ro':
      return 'min';
    case 'ko':
      return '분';
    case 'ja':
      return '分';
    case 'ru':
      return 'м.';
    case 'tr':
      return 'dk';
    case 'vi':
      return 'phút';
    case 'zh':
      return '分钟';
    default:
      return 'min';
  }
};

export const toCamelCase = (str: string): string => {
  return str
    .replace(/[?']/g, '') // Remove all '?' ''' characters
    .toLowerCase() // Convert the entire string to lowercase
    .split(' ') // Split the string into an array of words
    .map(
      (word, index) =>
        index === 0
          ? word // Keep the first word lowercase
          : word.charAt(0).toUpperCase() + word.slice(1), // Capitalize the first letter of subsequent words
    )
    .join(''); // Join the words back into a single string
};
// Function to retrieve data and check for expiry
export function getFromLocalStorageWithExpiry(key: string) {
  const dataString = localStorage.getItem(key);
  if (!dataString) {
    return null;
  }

  const data = JSON.parse(dataString);
  const now = Date.now();

  if (now > data.expiry) {
    // Data has expired, remove it from storage
    localStorage.removeItem(key);
    return null;
  }

  return data.value;
}

export function saveToLocalStorageWithExpiry(
  key: string,
  sessionId: number,
  expiryInMinutes: number,
) {
  const now = Date.now();
  const expiryTime = now + expiryInMinutes * 60 * 1000; // Convert minutes to milliseconds
  const data = {
    value: sessionId,
    expiry: expiryTime,
  };
  localStorage.setItem(key, JSON.stringify(data));
}

export const isSessionOverlapping = (sessions: Session[], session: Session) => {
  return sessions?.some((item) => {
    return (
      item.isEnroll &&
      new Date(item.date).valueOf() <= new Date(session.date).valueOf() + 44 * 60 * 1000 &&
      new Date(session.date).valueOf() <= new Date(item.date).valueOf() + 44 * 60 * 1000
    );
  });
};

export const getContentEnv = () => {
  let params: Record<string, string> = {};
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
  }
  return params.contentEnv || process.env.REACT_APP_CONTENT_ENV || 'prod';
};

export const tutoringUrl = () => {
  switch (getContentEnv()) {
    case 'prod':
      return 'https://v2.tutoring-prd.pearsonprd.tech';
    case 'stage':
      return 'https://v2.tutoring-stg.pearsondev.tech';
    default:
      return 'https://v2.tutoring-dev.pearsondev.tech';
  }
};

export function areLevelArraysEqual(arr1: number[], arr2: number[]): boolean {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort((a, b) => a - b);
  const sortedArr2 = [...arr2].sort((a, b) => a - b);

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export function getUserTimezone(): string {
  // Get the user's timezone offset in minutes
  const offset = new Date().getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, '0');
  const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
  const sign = offset > 0 ? '-' : '+';
  const gmtOffset = `GMT${sign}${offsetHours}:${offsetMinutes}`;

  // Get the user's region and timezone using Intl.DateTimeFormat
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return `(${gmtOffset}) ${timeZone}`;
}
