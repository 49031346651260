/* eslint-disable no-console */
import { SurveyQuestion, Session } from './types';
import { downloadTextAsFile, tutoringUrl } from './utils';

export const getContentEnv = () => {
  let params: Record<string, string> = {};
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
  }
  return params.contentEnv || process.env.REACT_APP_CONTENT_ENV || 'prod';
};

const tutoringApiUrl = () => {
  switch (getContentEnv()) {
    case 'prod':
      return 'https://api.tutoring-prd.pearsonprd.tech';
    case 'stage':
      return 'https://api.tutoring-stg.pearsondev.tech';
    default:
      return 'https://api.tutoring-dev.pearsondev.tech';
  }
};

const mondlyAPIPrefix = () => {
  switch (getContentEnv()) {
    case 'prod':
      return 'https://api.mondly.com';
    case 'stage':
      return 'https://api.stg.mdl.ift.pearson-intl.com';
    default:
      return 'https://api.mondly.com';
  }
};

export const getUser = async (token: string) => {
  try {
    const resp = await fetch(`${mondlyAPIPrefix()}/v1/services/live-tutoring/user/info`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await resp.json();
    return result?.user;
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
    return undefined;
  }
};

export const getMondlyUser = async (token: string) => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/user/getMondlyUser`, {
      // const resp = await fetch(`http://localhost:8080/v1/user/getMondlyUser`, {
      method: 'get',
      headers: {
        'X-Authorization': `Bearer ${token}`,
      },
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
    return undefined;
  }
};

export const updateUserNameMondly = async (token: string, name: string): Promise<void> => {
  try {
    await fetch('https://api.mondly.com/v1/services/live-tutoring/settings/update-profile', {
      method: 'post',
      body: JSON.stringify(name),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
  }
};

export const isPrivateGroup = async (groupId: number): Promise<boolean> => {
  try {
    if (groupId) {
      const resp = await fetch(`${tutoringApiUrl()}/v1/license/isPrivateGroup/${groupId}`, {
        method: 'get',
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      return await resp.json();
    }
    return false;
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
    return false;
  }
};

export const getSessions = async (token: string, lang: string) => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/sessions/upcoming/${lang || ''}`, {
      method: 'get',
      headers: {
        'X-Authorization': `Bearer ${token}`,
      },
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getSession = async (sessionId: string, token: string) => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/sessions/${sessionId}`, {
      method: 'get',
      headers: {
        'X-Authorization': `Bearer ${token}`,
      },
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getLevels = async (token: string) => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/getLevels`, {
      method: 'get',
      headers: {
        'X-Authorization': `Bearer ${token}`,
      },
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getICSFile = async (sessionId: string, lang = 'en_us') => {
  try {
    const resp = await fetch(
      `${tutoringApiUrl()}/v1/sessions/${sessionId}/getICSFile?mother_lang=${lang}`,
      {
        method: 'get',
      },
    );
    const blob = await resp.blob();
    downloadTextAsFile(blob, `invite.ics`);
    return '';
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const enrollUserToSession = async (
  token: string,
  sessionId: number,
  mother_lang = 'en_us',
) => {
  try {
    const resp = await fetch(
      `${tutoringApiUrl()}/v1/sessions/${sessionId}/enroll?lang=${mother_lang}`,
      {
        method: 'post',
        headers: {
          'X-Authorization': `Bearer ${token}`,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: JSON.stringify(null),
      },
    );
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const cancelUserToSession = async (
  tenantId: string,
  userId: string,
  token: string,
  sessionId: number,
) => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/sessions/${sessionId}/cancel`, {
      method: 'post',
      headers: {
        'X-Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(null),
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const joinNewSession = async (
  sessionId: number,
  token: string,
  userName: string,
  platform: string,
  force?: boolean,
) => {
  const logoutUrl = `${tutoringUrl()}/closetab`;
  try {
    const resp = await fetch(
      `${tutoringApiUrl()}/v1/sessions/${sessionId}/joinSession?userName=${userName}${
        platform === 'web' ? `&logoutUrl=${logoutUrl}` : ''
      }${force ? '&force=true' : ''}`,
      {
        method: 'get',
        headers: {
          'X-Authorization': `Bearer ${token}`,
        },
      },
    );
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const checkIfMeetingStarted = async (sessionId: number): Promise<boolean> => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/sessions/${sessionId}/isMeetingRunning`, {
      method: 'get',
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return false;
  }
};

// Previous ver feedback call:
export const feedbackSession = async (
  token: string,
  sessionId: string,
  feedback: { feedbackResponse: SurveyQuestion[]; os: string | undefined },
) => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/feedback/${sessionId}/feedback`, {
      method: 'post',
      body: JSON.stringify(feedback),
      headers: {
        'X-Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

// get session info:
export const getSessionInfo = async (token: string, sessionId: string): Promise<Session | null> => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/sessions/${sessionId}`, {
      method: 'get',
      headers: {
        'X-Authorization': `Bearer ${token}`,
      },
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

// Single select (star rate) feedback call:
export const singleFeedback = async (
  token: string,
  sessionId: string,
  feedbackId: string,
  responseId: number,
) => {
  try {
    const resp = await fetch(
      `${tutoringApiUrl()}/v1/feedback/${sessionId}/feedback/feedback${feedbackId}/response/${responseId}`,
      {
        method: 'get',
        headers: {
          'X-Authorization': `Bearer ${token}`,
        },
      },
    );
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

// Multiple select feedback call:
export const multipleSelectFeedback = async (
  token: string,
  userResponse: {
    sessionId: number;
    feedbackId: number;
    response: { userSelection: string[]; userFreeText: string };
  },
) => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/feedback/userResponse`, {
      method: 'POST',
      body: JSON.stringify(userResponse),
      headers: {
        'X-Authorization': `Bearer ${token}`,
        'content-type': 'application/json',
      },
    });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchMondlyUserToken = async () => {
  try {
    const resp = await window.mondlyNative?.getUserToken();
    return resp?.token;
  } catch (e) {
    // console.log(e);
    console.log(JSON.stringify(e));
    return undefined;
  }
};

export const getBlockUsersList = async () => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/getBlockUsersList`, { method: 'get' });
    return await resp.json();
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

// private user weekly limit:
export const getPrivateUserWeeklyLimit = async (token: string, offset: number = 0) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ offset }),
  };

  try {
    const resp = await fetch(`${tutoringApiUrl()}/v1/sessions/getUserWeeklyLimit`, requestOptions);
    return await resp.json();
  } catch (e) {
    console.log('error:', e);
    return undefined;
  }
};

// private group details by groupId:
export const getGroupById = async (groupId: number, token: string) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ groupId }),
  };

  try {
    const group = await fetch(`${tutoringApiUrl()}/license/getUserGroupData`, requestOptions);
    return await group.json();
  } catch (e) {
    console.log('error:', e);
    return undefined;
  }
};

export const getHashWizard = async (hash: string) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };
    const resp = await fetch(`${tutoringApiUrl()}/v1/license/getHash?hash=${hash}`, requestOptions);
    return await resp.json();
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
