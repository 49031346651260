import { useCallback, useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserContext } from '../../Providers/UserContext';
import { useSessionsContext } from '../../Providers/SessionsContext';
import { useModalsContext } from '../../Providers/ModalsContext';
import { useDeviceContext } from '../../Providers/DeviceContext';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import AlertModal from './AlertModal';
import infoIconImage from '../../assets/image/info-icon.png';
import closeImage from '../../assets/image/close.png';
import {
  getLangDir,
  getIsUserLiveTutoring,
  getFromLocalStorage,
  isSessionOverlapping,
} from '../../helpers/utils';

interface AgreeAndBookProps {
  buildUserData: (userName: string, userId: string, localStorageName: string | null) => void;
  setTermsAndConditionsModal: (a: boolean) => void | undefined;
  enrollSession: (sessionId: number) => void;
  resetKeyboardScroll: () => void;
  lang: string;
}

function AgreeAndBook({
  buildUserData,
  setTermsAndConditionsModal,
  enrollSession,
  resetKeyboardScroll,
  lang,
}: AgreeAndBookProps) {
  const { user } = useUserContext();
  const { os } = useDeviceContext();
  const [joinBtn, setJoinBtn] = useState(!!user?.name);
  const [userName, setUserName] = useState(user?.name);
  const localStorageName = useRef(getFromLocalStorage(`${user?.id || 'fake1'}-username`));
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'preClassScreen.name' });
  const inputElementRef = useRef<HTMLInputElement>(null);
  const { currentSession, sessions, updateCurrentSession } = useSessionsContext();
  const { updateModalContent, updateStrictModalContent } = useModalsContext();
  const { mixpanelTrackEvent } = useMixpanelContext();

  const onUpdateName = (name: string) => {
    setUserName(name);
    if (name.length >= 2) {
      setJoinBtn(true);
    } else {
      setJoinBtn(false);
    }
  };

  const onNameChange = (updatedName: string) => {
    onUpdateName(updatedName);
    resetKeyboardScroll();
  };

  const verifyBookingRestrictions = useCallback(() => {
    if (currentSession) {
      if (
        (sessions && sessions.filter((item) => item.isEnroll).length >= 3) ||
        (user?.isWizard && sessions && sessions.filter((item) => item.isEnroll).length >= 1)
      ) {
        if (!user?.b2b.groupId) {
          if (user?.isWizard) {
            updateStrictModalContent(<AlertModal restrictionMsgType="max1BookedSessions" />);
          } else {
            updateStrictModalContent(<AlertModal restrictionMsgType="max3BookedSessions" />);
          }

          updateModalContent(undefined);
        }
      } else if (sessions && isSessionOverlapping(sessions, currentSession)) {
        updateStrictModalContent(<AlertModal restrictionMsgType="noDoubleBooking" />);
        updateModalContent(undefined);
      }
    }
  }, [currentSession, sessions, updateModalContent, updateStrictModalContent]);

  const updateUserData = () => {
    if (userName && user?.id) {
      buildUserData(userName, user?.id, localStorageName.current);
    }

    if (currentSession) {
      enrollSession(currentSession?.id);

      // Mixpanel metric:
      mixpanelTrackEvent('EnrollSession', {
        SessionId: currentSession?.id,
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      });
    }
    updateCurrentSession(null);
    updateModalContent(undefined);
  };

  const handleSubmit = (e: any) => {
    verifyBookingRestrictions();
    e.preventDefault();
    if (joinBtn) {
      updateUserData();
    }
  };

  useEffect(() => {
    if (inputElementRef) {
      inputElementRef.current!.onfocus = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      };
    }
  });

  useEffect(() => {
    verifyBookingRestrictions();
  }, [currentSession, verifyBookingRestrictions]);

  return (
    <section
      className={`agree-and-book-container ${os !== 'iOS' ? 'higher-container' : ''}`}
      dir={getLangDir(lang)}
    >
      <div className="agree-and-book-header">
        <button
          type="submit"
          className="header-btn-close"
          onClick={() => {
            updateModalContent(undefined);
            // dispatch(setModalContent(undefined));
            // Mixpanel metric:
            mixpanelTrackEvent('AgreeAndBookScreenExit', {
              SessionId: currentSession?.id,
              companyId: user?.b2b.companyId,
              groupId: user?.b2b.groupId,
              groupName: user?.b2b.groupName,
              companyName: user?.b2b.companyName,
              userRoles: user?.roles,
            });
          }}
        >
          <img src={closeImage} alt="" />
        </button>
        {user && !getIsUserLiveTutoring(user.roles) && (
          <section className="beta-tag">
            <div className="beta-tag-txt">Beta</div>
          </section>
        )}
      </div>
      <div className="agree-and-book-content">
        <div className="pre-class-title">
          <FormattedMessage id="preClassScreen.yourDetails" />
        </div>
        <div className="pre-class-description">
          <FormattedMessage id="preClassScreen.pleaseProvideYourName" />
        </div>
        <form
          id="userName"
          action="submit"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <input
            ref={inputElementRef}
            value={userName}
            id="inputName"
            type="text"
            className="pre-class-input input-name agree-and-book"
            placeholder={placeholder}
            onChange={(e) => {
              onNameChange(e.target.value);
            }}
            onBlur={resetKeyboardScroll}
            dir={!userName?.length && lang === 'ar' ? 'rtl' : 'auto'}
          />
        </form>
        <div className="pre-class-disclaimer-terms">
          <div className="pre-class-record-disclaimer">
            <img src={infoIconImage} alt="" className="info-icon-img" />
            <FormattedMessage id="preClassScreen.allClassesAreRecorded" />
          </div>
          {!localStorageName.current && (
            <div className="pre-class-disc-terms">
              <p>
                <FormattedMessage id="preClassScreen.byClicking" />
                &nbsp;
                <span className="italic">
                  “<FormattedMessage id="common.agreeAndBook" />”
                </span>
                &nbsp;
                <FormattedMessage id="preClassScreen.youIndicateConsentToThe" />
                &nbsp;
                <button
                  type="button"
                  className="terms-and-conditions-link"
                  onClick={() => {
                    if (!user?.isWizard) {
                      setTermsAndConditionsModal(true);
                    }
                  }}
                >
                  <FormattedMessage id="preClassScreen.termsAndConditions" />
                </button>
                &nbsp;
                <FormattedMessage id="preClassScreen.andOver18" />
              </p>
            </div>
          )}
        </div>

        <button
          type="button"
          form="userName"
          className={`cta-btn pre-class-join-btn agree-and-book ${!joinBtn ? 'disabled' : ''}`}
          onClick={() => updateUserData()}
          disabled={!joinBtn}
        >
          <FormattedMessage id="common.agreeAndBook" />
        </button>
      </div>
    </section>
  );
}

export default AgreeAndBook;
