import { createContext, useContext, useCallback, useMemo, useState, memo, ReactNode } from 'react';

interface State {
  // modalContent: any;
  // strictModalContent: any;
  modalContent: JSX.Element | undefined;
  strictModalContent: JSX.Element | undefined;
  updateModalContent: (modalContent: JSX.Element | undefined) => void;
  updateStrictModalContent: (modalContent: JSX.Element | undefined) => void;
}

const initContext: State = {
  modalContent: undefined,
  strictModalContent: undefined,
  updateModalContent: () => {},
  updateStrictModalContent: () => {},
};

const ModalsContext = createContext(initContext);

type ModalsProviderProps = {
  children: ReactNode;
};

export const ModalsProvider = memo(({ children }: ModalsProviderProps) => {
  const [modalContent, setModalContent] = useState(initContext.modalContent);
  const [strictModalContent, setStrictModalContent] = useState(initContext.strictModalContent);

  const updateModalContent = useCallback((updatedModalContent: any) => {
    if (updatedModalContent !== undefined) {
      setModalContent(updatedModalContent);
    } else setModalContent(undefined);
  }, []);

  const updateStrictModalContent = useCallback((updatedStrictModalContent: any) => {
    if (updatedStrictModalContent !== undefined) {
      setStrictModalContent(updatedStrictModalContent);
    } else setStrictModalContent(undefined);
  }, []);

  const value = useMemo(
    () => ({
      modalContent,
      strictModalContent,
      updateModalContent,
      updateStrictModalContent,
    }),
    [modalContent, strictModalContent, updateModalContent, updateStrictModalContent],
  );

  return <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>;
});

export const useModalsContext = () => useContext(ModalsContext);
