import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Modal, Drawer } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { useUserContext } from './Providers/UserContext';
import { useSessionsContext } from './Providers/SessionsContext';
import { useModalsContext } from './Providers/ModalsContext';
import { useMixpanelContext } from './Providers/MixpanelContext';
import { useDeviceContext } from './Providers/DeviceContext';
import Sessions from './Components/Sessions/Sessions';
import WelcomeModal from './Components/Modals/WelcomeModal';
import LiveSession from './Components/LiveSession/LiveSession';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions';
import Header from './Components/Header/Header';
import Feedback from './Components/Feedback/FeedbackJune24/FeedbackJune24';
import UserGuidePage from './Components/UserGuide/UserGuidePage';
import ClassGuidePage from './Components/ClassGuide/ClassGuidePage';
import BottomConfirmationPopup from './Components/Shared/BottomConfirmationPopup';
import Filters from './Components/Filters/FiltersMultipleSelect';
import './assets/styles/main.scss';
import OvalLoader from './assets/loader/oval';
import { sendEventToMondly } from './helpers/Event';
// import openFiltersImage from './assets/image/open-filters.png';
import CloseTab from './Components/Feedback/CloseTab';
import { getBlockUsersList } from './helpers/Network';
import { getFromLocalStorage, getLangDir, getMobileOperatingSystem } from './helpers/utils';

import closeImage from './assets/image/close-modal-icon.png';

interface AppProps {
  rawLang: string;
  level: number;
  platform: string;
}

function App({ rawLang = 'en', level, platform = 'mobile' }: AppProps) {
  const [lang, setLang] = useState(rawLang);
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const [isFeedbackScreen, setIsFeedbackScreen] = useState(false);
  const [isUserGuidePage, setIsUserGuidePage] = useState(false);
  const [isClassGuidePage, setIsClassGuidePage] = useState(false);
  const [isShowHeader, setIsShowHeader] = useState(true);
  const [bodyScrollHeight, setBodyScrollHeight] = useState(0);
  const isWelcomeScreenClicked = getFromLocalStorage('isWelcomeScreenClicked');
  // Providers:
  const { user, fetchUser } = useUserContext();
  const { mixpanelTrackEvent, mixpanelTimeEvent } = useMixpanelContext();
  const { setPlatform } = useDeviceContext();
  const { modalContent, strictModalContent, updateModalContent, updateStrictModalContent } =
    useModalsContext();
  const {
    isFiltersDrawerOpen,
    sessions,
    // isBookedSessions,
    updateIsFiltersDrawerOpen,
    updateFilterQuery,
  } = useSessionsContext();
  const [usersBlockList, setBlockUsersList] = useState([]);
  const [deviceId, setDeviceId] = useState('');
  const [isShowBottomPopup, setIsShowBottomPopup] = useState(false);
  const [bottomPopupType, setBottomPopupType] = useState('');
  const [bottomPopupTxt, setBottomPopupTxt] = useState('');
  const feedbackQNumber = 4;
  const [yellowProgressBarMaxVal, setYellowProgressBarMaxVal] = useState(feedbackQNumber);
  const [yellowProgressBarCurrVal, setYellowProgressBarCurrVal] = useState(1);
  const isTestWeb = useRef(false);

  // IMPORTANT: set lang from Mondly format to readable locale format:
  useEffect(() => {
    if (lang === 'en_us' || lang === 'en_gb') {
      setLang('en');
    }
  }, [lang]);

  // IMPORTANT: set body height by OS:
  // const useSetOSVariable = () => {
  useEffect(() => {
    const os = getMobileOperatingSystem();
    document.documentElement.style.setProperty('--os', os);
  }, []);
  // };

  const onShowBottomPopup = (type: string, txt: string) => {
    setBottomPopupType(type);
    setBottomPopupTxt(txt);
    setIsShowBottomPopup(true);
    setTimeout(() => {
      setIsShowBottomPopup(false);
    }, 3000);
  };

  const isCloseTabPage = !!window.location.href.match(/closetab/);

  const getMixPanelDeviceId = useCallback(() => {
    const cookies = document.cookie;
    const cookieArray = cookies.split(';');
    cookieArray.forEach((cookie) => {
      // Trim any whitespace and split the cookie into name-value pairs
      const [name, value] = cookie.trim().split('=');
      if (name.startsWith('mp_')) {
        const jsonValue = JSON.parse(decodeURIComponent(value));
        setDeviceId(jsonValue.$device_id);
      }
    });
    return deviceId;
  }, [deviceId]);

  // Mixpanel for performance:
  useEffect(() => {
    mixpanelTimeEvent('AppIsUserId'); // start the timer when page stars loading
    mixpanelTimeEvent('AppGetSessionsFromLaunch'); // start the timer for getSessions time tracking when page stars loading
  }, [mixpanelTimeEvent]);

  // LAUNCHDARKLY:
  const ldClient = useLDClient();
  useEffect(() => {
    ldClient?.waitUntilReady().then(() => {
      if (user?.id) {
        ldClient.identify({ key: user?.id, custom: { language: lang } });
      }
    });
  }, [lang, ldClient, user?.id]);

  useEffect(() => {
    if (process.env.REACT_APP_CONTENT_ENV === 'prod') {
      if (user?.id) {
        mixpanel.identify(user?.id);
        mixpanelTrackEvent('AppIsUserId', {
          userSubscriptions: user?.roles,
          companyId: user?.b2b.companyId,
          groupId: user?.b2b.groupId,
          groupName: user?.b2b.groupName,
          companyName: user?.b2b.companyName,
          userRoles: user?.roles,
        });
      }
    }
  }, [mixpanelTrackEvent, user?.id, user?.roles]);

  useMemo(async () => {
    setBlockUsersList(await getBlockUsersList());
  }, []);

  // app modals handling:
  const handleCloseModal = useCallback(() => {
    updateModalContent(undefined);
  }, [updateModalContent]);

  const handleCloseStrictModal = useCallback(
    (e: any, reason: string) => {
      if (e && reason !== 'backdropClick') {
        updateModalContent(undefined);
        updateStrictModalContent(undefined);
      }
    },
    [updateModalContent, updateStrictModalContent],
  );

  const handleCloseWelcomeModal = useCallback(() => {
    try {
      // Mixpanel metric:
      mixpanelTrackEvent('WelcomeScreenConsent', {
        userId: user?.id,
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      });
      if (!isWelcomeScreenClicked) {
        localStorage.setItem('isWelcomeScreenClicked', JSON.stringify(true));
      }
    } catch (err) {
      console.log(err);
    }
    handleCloseModal();
  }, [handleCloseModal, mixpanelTrackEvent, user?.id, isWelcomeScreenClicked]);

  // for welcome modal:
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const welcomeScreenModalContent = useMemo(() => {
    return (
      <div className={`modal welcome-modal ${isLandscape ? 'landscape' : ''}`}>
        <button
          type="button"
          className="close-button"
          onClick={handleCloseWelcomeModal}
          aria-label="Close modal"
        >
          <img src={closeImage} alt="close-icon" className="close-icon-img" />
        </button>
        <WelcomeModal />
        <button
          type="submit"
          className="cta-btn mobile-full-width welcome-modal-cta"
          onClick={() => handleCloseWelcomeModal()}
        >
          <FormattedMessage id="welcomeModal.findClass" />
        </button>
      </div>
    );
  }, [handleCloseWelcomeModal, user]);

  // const [isKeyboardOpened, setIsKeyboardOpened] = useState(false);

  // useEffect(() => {
  //   document
  //     .querySelector('meta[name=theme-color]')
  //     ?.setAttribute('content', isKeyboardOpened || modalContent ? '#6200af' : '#4ea9fd');
  // document
  //   .querySelector('meta[name=apple-mobile-web-app-status-bar-style]')
  //   ?.setAttribute('content', isKeyboardOpened || modalContent ? '#6200af' : '#4ea9fd');
  // }, [isKeyboardOpened]);

  // adjust base color when keyboard opens:
  // const setBaseColor = () => {
  // setIsKeyboardOpened(true);
  // };

  // const isMobile = !(document.body.offsetWidth > 500);

  // keyboard scroll for native behavior:
  const resetKeyboardScroll = () => {
    // console.log('resetKeyboardScroll attempted');
    // if (isMobile) {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    // }
    // setIsKeyboardOpened(false);
  };

  useEffect(() => {
    // scroll up to avoid ios keyboard push
    if (!modalContent) {
      resetKeyboardScroll();
    }
  }, [modalContent]);

  useEffect(() => {
    if (process.env.REACT_APP_CONTENT_ENV === 'prod') {
      // INIT:
      try {
        mixpanel.init(process.env.REACT_APP_PUBLIC_MIXPANEL_CLIENT_SIDE_TOKEN!, {
          debug: false,
        });
      } catch (err) {
        console.log(err);
      }

      try {
        // get and set deviceId
        getMixPanelDeviceId();
      } catch (err) {
        console.log(err);
      }
    }
  }, [getMixPanelDeviceId]);

  useEffect(() => {
    if (user) {
      // Mixpanel metric with versioning and user data:
      mixpanelTrackEvent('TutoringLaunch', {
        language: lang,
        version: 'v2',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        currTime: new Date().toLocaleTimeString(),
        companyId: user.b2b.companyId,
        groupId: user.b2b.groupId,
        groupName: user.b2b.groupName,
        companyName: user.b2b.companyName,
        userRoles: user.roles,
      });
    }
  }, [user, lang, mixpanelTrackEvent]);

  // Init:
  useEffect(() => {
    try {
      // Report tutoring app opened to Mondly
      sendEventToMondly(window.mondlyNative?.analyticsTypes.OPEN, {});
    } catch (err) {
      console.log(err);
    }
  }, []);

  // Reset filters and DeviceProvider platform on entering app:
  useEffect(() => {
    updateFilterQuery({ filterLevel: [], filterTime: [], filterDate: '' });
    setPlatform(platform);
    // IMPORTANT: no dependancy in brackets!
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // Mixpanel for performance:
    mixpanelTimeEvent('AppFetchUserDuration'); // start the timer on initial fetchUser call
    // TODO: can we remove this?
    fetchUser(!!params.testWeb, params.hash);
    if (params.hash) setLang('br');
    isTestWeb.current = !!params.testWeb;
  }, [mixpanelTimeEvent, fetchUser]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    // feedback route:
    const regexPattern = /\/sessionend\//;
    const isMatching = regexPattern.test(currentPath);
    if (isMatching) {
      setIsFeedbackScreen(true);
      // setIsInterviewDrawerOpen(false);
    } else {
      setIsFeedbackScreen(false);
    }
    // learner page route:
    const LearnerPageRegexPattern = /\/learnerguide\//i;
    const isMatchingLearnerPage = LearnerPageRegexPattern.test(currentPath);
    if (isMatchingLearnerPage) {
      setIsUserGuidePage(true);
    } else {
      setIsUserGuidePage(false);
    }
    const ClassGuidePageRegexPattern = /\/classguide\//i;
    const isMatchingClassGuidePage = ClassGuidePageRegexPattern.test(currentPath);
    if (isMatchingClassGuidePage) {
      setIsClassGuidePage(true);
    } else {
      setIsClassGuidePage(false);
    }
  }, []);

  useEffect(() => {
    try {
      // First time show welcome screen
      setTimeout(() => {
        if (sessions?.length && !isWelcomeScreenClicked && !user?.isWizard) {
          updateModalContent(welcomeScreenModalContent);
        }
      }, 500);
    } catch (err) {
      console.log(err);
    }
  }, [isWelcomeScreenClicked, sessions?.length, updateModalContent, welcomeScreenModalContent]);

  // bodyRef to scroll sessions div, header effects and tab-switcher positioning:
  const bodyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (bodyRef.current) {
        setBodyScrollHeight(bodyRef.current.scrollTop);
        const scrollFromBottom =
          bodyRef.current.scrollHeight - bodyRef.current.clientHeight - bodyRef.current.scrollTop;
        const currentScrollY = bodyRef.current.scrollTop;
        const direction = currentScrollY > lastScrollY ? 'up' : 'down';
        if (direction === 'down' && scrollFromBottom > 100) {
          setIsSticky(true);
        } else if (direction === 'up') {
          setIsSticky(false);
        }
        setLastScrollY(currentScrollY);
      }
    };

    // window.addEventListener('scroll', handleScroll);
    // return () => window.removeEventListener('scroll', handleScroll);

    const scrollableElement = bodyRef.current;
    scrollableElement?.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      scrollableElement?.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  // Filters drawer:
  const filtersDrawerSx = {
    '& .MuiPaper-root': {
      marginTop: '52px',
      maxHeight: 'calc(100% - 52px)',
    },
  };

  const onOpenFilters = () => {
    updateIsFiltersDrawerOpen(true);
  };

  const closeFiltersDrawer = () => {
    updateIsFiltersDrawerOpen(false);
    resetKeyboardScroll();
  };

  useMemo(() => {
    if (!isFiltersDrawerOpen) {
      resetKeyboardScroll();
    }
  }, [isFiltersDrawerOpen]);

  useEffect(() => {
    if (platform === 'web' && isUserGuidePage) {
      setIsShowHeader(false);
    } else {
      setIsShowHeader(true);
    }
  }, [platform, isUserGuidePage]);

  return (
    <BrowserRouter>
      {isShowHeader && (
        <Header
          bodyScrollHeight={bodyScrollHeight}
          isTermsAndConditions={termsAndConditionsModal}
          lang={lang}
          yellowProgressBarMaxVal={yellowProgressBarMaxVal}
          yellowProgressBarCurrVal={yellowProgressBarCurrVal}
          isFeedbackScreen={isFeedbackScreen}
          setIsFeedbackScreen={setIsFeedbackScreen}
          isUserGuidePage={isUserGuidePage}
          isClassGuidePage={isClassGuidePage}
          platform={platform}
          setIsUserGuidePage={setIsUserGuidePage}
          setIsClassGuidePage={setIsClassGuidePage}
        />
      )}
      <div className={`bodyWrapper ${isFeedbackScreen ? 'feedback' : ''}`} ref={bodyRef}>
        {user?.id ? (
          <Routes>
            <Route path="/closetab" element={<CloseTab />} />
            <Route
              path="/"
              element={
                <Sessions
                  bodyScrollHeight={bodyScrollHeight}
                  setTermsAndConditionsModal={setTermsAndConditionsModal}
                  lang={lang}
                  level={level}
                  resetKeyboardScroll={resetKeyboardScroll}
                  usersBlockList={usersBlockList}
                  deviceId={deviceId}
                  platform={platform}
                  isSticky={isSticky}
                  setIsUserGuidePage={setIsUserGuidePage}
                  setIsClassGuidePage={setIsClassGuidePage}
                  onOpenFilters={onOpenFilters}
                />
              }
            />
            <Route
              path="/session/:sessionId"
              element={
                <LiveSession
                  onShowBottomPopup={(type: string, txt: string) => onShowBottomPopup(type, txt)}
                  usersBlockList={usersBlockList}
                  deviceId={deviceId}
                  lang={lang}
                  platform={platform}
                />
              }
            />
            <Route path="/block" element={<div> </div>} />
            <Route
              path="/sessionend/:sessionId"
              element={
                <Feedback
                  resetKeyboardScroll={resetKeyboardScroll}
                  lang={lang}
                  setYellowProgressBarMaxVal={setYellowProgressBarMaxVal}
                  setYellowProgressBarCurrVal={setYellowProgressBarCurrVal}
                  setIsFeedbackScreen={setIsFeedbackScreen}
                />
              }
            />
            <Route path="/learnerguide" element={<UserGuidePage />} />
            <Route path="/classguide" element={<ClassGuidePage platform={platform} />} />
            {/* TODO: merge routes for Sessions */}
            <Route
              path="/sessionbook/:sessionbook"
              element={
                <Sessions
                  bodyScrollHeight={bodyScrollHeight}
                  setTermsAndConditionsModal={setTermsAndConditionsModal}
                  lang={lang}
                  level={level}
                  resetKeyboardScroll={resetKeyboardScroll}
                  usersBlockList={usersBlockList}
                  deviceId={deviceId}
                  platform={platform}
                  isSticky={isSticky}
                  setIsUserGuidePage={setIsUserGuidePage}
                  setIsClassGuidePage={setIsClassGuidePage}
                  onOpenFilters={onOpenFilters}
                />
              }
            />
          </Routes>
        ) : (
          <div className="loaderWrapper">
            <OvalLoader />
          </div>
        )}
      </div>

      {/* {!modalContent &&
        !isBookedSessions &&
        !isFeedbackScreen &&
        !isCloseTabPage &&
        !isUserGuidePage && (
          <button
            type="submit"
            className={`open-filters-btn ${lang === 'ar' ? 'rtl' : ''}`}
            onClick={() => onOpenFilters()}
          >
            <img src={openFiltersImage} alt="" />
          </button>
        )} */}

      {isCloseTabPage && <CloseTab />}

      {isShowBottomPopup && (
        <BottomConfirmationPopup confirmationType={bottomPopupType} msgTxt={bottomPopupTxt} />
      )}

      {!!modalContent && (
        <Modal
          open={!!modalContent}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleCloseModal();
            }
          }}
          disableAutoFocus
          disableEscapeKeyDown
        >
          <div className="flex justify-center align-center" dir={getLangDir(lang)}>
            {modalContent}
          </div>
        </Modal>
      )}
      {!!strictModalContent && (
        <Modal
          open={!!strictModalContent}
          onClose={handleCloseStrictModal}
          disableEscapeKeyDown
          disableAutoFocus
        >
          <div className="flex justify-center align-center full" dir="auto">
            {strictModalContent}
          </div>
        </Modal>
      )}

      {termsAndConditionsModal && (
        <div className="terms-and-conditions-div">
          <Header
            bodyScrollHeight={bodyScrollHeight}
            isTermsAndConditions
            setTermsAndConditionsModal={setTermsAndConditionsModal}
            lang={lang}
            platform={platform}
          />
          <TermsAndConditions />
        </div>
      )}

      <Drawer
        anchor="bottom"
        open={isFiltersDrawerOpen}
        onClose={closeFiltersDrawer}
        className="filters-drawer-container"
        sx={filtersDrawerSx}
      >
        <Filters />
      </Drawer>
    </BrowserRouter>
  );
}

export default App;
