import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getGroupById, getMondlyUser, isPrivateGroup, getHashWizard } from '../helpers/Network';
import { Children, User } from '../helpers/types';
import { getFromLocalStorage } from '../helpers/utils';
import { useMixpanelContext } from './MixpanelContext';

interface UserContextProps {
  token: string | undefined;
  user: User | undefined;
  userPrivateGroupInfo: Children | null;
  fetchUser: (fakeUser: boolean, userId?: string) => void;
  updateUserName: (userName: string) => void;
}

const emptyContext: UserContextProps = {
  token: undefined,
  user: undefined,
  userPrivateGroupInfo: null,
  fetchUser: () => {},
  updateUserName: () => {},
};

const UserContext = createContext(emptyContext);

type UserProviderProps = {
  children: ReactNode;
};

// react memo for preventing re-rendering if variables are unchanged.
export const UserProvider = memo(({ children }: UserProviderProps) => {
  const [token, setToken] = useState<string | undefined>(emptyContext.token);
  const [user, setUser] = useState<User | undefined>(emptyContext.user);
  const [userPrivateGroupInfo, setUserPrivateGroupInfo] = useState<Children | null>(
    emptyContext.userPrivateGroupInfo,
  );
  const { mixpanelTrackEvent, mixpanelTimeEvent } = useMixpanelContext();

  const fetchUser = useCallback(async (fakeUser?: boolean, hash?: string) => {
    if (fakeUser) {
      // set fake user token:
      setToken(`fake_token_fake1`);
      setUser(
        {
          id: 'fake1',
          email: 'fake@email.com',
          name: getFromLocalStorage(`fake1-username`) || 'fakeuser',
          state: 1,
          isPrivate: false,
          roles: {
            premium: false,
            liveTutoring: false,
          },
          b2b: {
            companyId: null,
            companyName: null,
            groupId: null,
            groupName: null,
          },
          // b2b: {
          //   companyId: 1927,
          //   companyName: 'Class 1',
          //   groupId: 32685,
          //   groupName: 'KKU',
          // },
        },

        // {
        //   id: '897',
        //   email: 'mondly.tutoring3@gmail.com',
        //   name: 'Mondly',
        //   state: 1,
        //   roles: {
        //     premium: true,
        //     liveTutoring: true,
        //   },
        //   b2b: {
        //     companyId: 374,
        //     companyName: 'Class 1',
        //     groupId: 37,
        //     groupName: 'Test 1 (for STG)',
        //   },
        // },
      );
    } else if (hash) {
      const wizResponse = await getHashWizard(hash);
      if (wizResponse) {
        setToken(`wizard_token ${hash}`);
        setUser({
          id: wizResponse.contactId,
          // email: 'fake@email.com',
          name: wizResponse.username,
          email: wizResponse.email,
          isWizard: true,
          wizardSessionsNum: wizResponse.wizardSessionsNum,
          state: 1,
          isPrivate: false,
          roles: {
            premium: false,
            liveTutoring: false,
          },
          b2b: {
            companyId: null,
            companyName: null,
            groupId: null,
            groupName: null,
          },
          // b2b: {
          //   companyId: 1927,
          //   companyName: 'Class 1',
          //   groupId: 32685,
          //   groupName: 'KKU',
          // },
        });
        console.log('wizResponse boom', wizResponse);
      }
    } else {
      try {
        const resp = await window.mondlyNative?.getUserToken();
        const tokenResponse = resp?.token;
        // set retrieved user token:
        setToken(tokenResponse);
        if (tokenResponse) {
          // const userResponse = await getUser(tokenResponse);
          const userResponse = await getMondlyUser(tokenResponse);
          if (userResponse) {
            userResponse.id = userResponse.id.toString();
            userResponse.name =
              getFromLocalStorage(`${userResponse.id}-username`) || userResponse.name;
            const isUserPrivate = await isPrivateGroup(userResponse.b2b.groupId);
            userResponse.isPrivate = isUserPrivate;
            setUser(userResponse);
            // Mixpanel metric:
            mixpanelTrackEvent('UserLogin', {
              userDetails: userResponse,
              companyId: userResponse?.b2b.companyId,
              groupId: userResponse?.b2b.groupId,
              groupName: userResponse?.b2b.groupName,
              companyName: userResponse?.b2b.companyName,
              userRoles: user?.roles,
            });
          }
        }
      } catch (err) {
        console.error('Async operation failed:', err);
      }
    }
  }, []);

  const updateUserName = useCallback((userName: string) => {
    setUser((prevUser) => {
      // Check if prevUser is defined and has a name property
      if (prevUser && prevUser.name !== undefined) {
        return {
          ...prevUser,
          name: userName,
        };
      }
      // Handle the case where prevUser or prevUser.name is undefined
      return prevUser;
    });
  }, []);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    // Mixpanel for performance:
    mixpanelTimeEvent('AppFetchUserDuration'); // start the timer on initial fetchUser call
    fetchUser(!!params.testWeb, params.hash).then(
      () =>
        // Mixpanel for performance:
        mixpanelTrackEvent('AppFetchUserDuration', {
          companyId: user?.b2b.companyId,
          groupId: user?.b2b.groupId,
          groupName: user?.b2b.groupName,
          companyName: user?.b2b.companyName,
          userRoles: user?.roles,
        }), // stop the timer on initial fetchUser call
    );
  }, [fetchUser, mixpanelTimeEvent, mixpanelTrackEvent]);

  // refresh user every 1min:
  useEffect(() => {
    const refreshUserInterval = setInterval(() => {
      if (params) {
        fetchUser(!!params.testWeb, params.hash);
      }
    }, 60 * 1000);

    return () => clearInterval(refreshUserInterval);
  }, []);

  // get private group license info:
  const getPrivateGroupLicenseInfo = async () => {
    if (user && token) {
      if (user.b2b.companyId) {
        const groupLicenseDetails: Children = await getGroupById(user.b2b.companyId, token);
        if (groupLicenseDetails) {
          setUserPrivateGroupInfo(groupLicenseDetails);
        }
      }
    }
  };

  useEffect(() => {
    getPrivateGroupLicenseInfo();
  }, [user]);

  const value = useMemo(
    () => ({
      token,
      user,
      userPrivateGroupInfo,
      fetchUser,
      updateUserName,
    }),
    [fetchUser, token, updateUserName, user],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
});

export const useUserContext = () => useContext(UserContext);
