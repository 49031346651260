import { FormattedMessage } from 'react-intl';
import { useModalsContext } from '../../Providers/ModalsContext';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { useUserContext } from '../../Providers/UserContext';
import { useDeviceContext } from '../../Providers/DeviceContext';
import FreeFeatureModal from './FreeFeatureModal';
import CrownImage from '../../assets/image/big-crown-icon.png';
import UnlimitedImage from '../../assets/image/unlimited-infinity-circle.png';
import PremiumImage from '../../assets/image/premium-card-circle.png';
import KidsImage from '../../assets/image/kids-card-circle.png';
import PearsonLogoWhiteImage from '../../assets/image/Pearson-logo-white.png';
import SubscriptionDiscountRibbonImage from '../../assets/image/subscription-discount-ribbon.png';
import { getFromLocalStorage } from '../../helpers/utils';

interface SubscriptionModalProps {
  lang: string;
}

function SubscriptionModal({ lang }: SubscriptionModalProps) {
  const { updateModalContent } = useModalsContext();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();
  const { os } = useDeviceContext();
  const onChooseSubscription = (months: number) => {
    try {
      const isSubscriptionChosen = getFromLocalStorage('isSubscriptionChosen');
      if (!isSubscriptionChosen) {
        localStorage.setItem('isSubscriptionChosen', JSON.stringify(true));
        // Catch first time choice:
        // Mixpanel metric:
        mixpanelTrackEvent('SubscriptionChoice', {
          subscriptionTypeChoice: months,
          companyId: user?.b2b.companyId,
          groupId: user?.b2b.groupId,
          groupName: user?.b2b.groupName,
          companyName: user?.b2b.companyName,
          userRoles: user?.roles,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      updateModalContent(<FreeFeatureModal />);
    }
  };

  return (
    <section className={`subscription-modal ${os !== 'iOS' ? 'minimize' : ''}`}>
      <img className="crown-icon" src={CrownImage} alt="" />
      <div className="content">
        <div className="divider-line-horizontal" />
        <h1>
          <FormattedMessage id="subscriptionModal.mondlyClassByPearson" />
        </h1>
        <p className="sub-header">
          <FormattedMessage id="subscriptionModal.subHeader" />
        </p>
        <div className="subscription-access">
          <ul>
            <li>
              <div className="list-item extra-padding-end">
                <img className="list-img" src={UnlimitedImage} alt="" />
                <p>
                  {' '}
                  <FormattedMessage id="subscriptionModal.unlimitedClassses" />
                </p>
              </div>
            </li>
            <li>
              <div className="list-item">
                <img className="list-img" src={PremiumImage} alt="" />
                <p>
                  <FormattedMessage id="subscriptionModal.premiumAccess" />
                </p>
              </div>
            </li>
            <li>
              <div className="list-item">
                <img className="list-img" src={KidsImage} alt="" />
                <p>
                  <FormattedMessage id="subscriptionModal.premiumAccessKids" />
                </p>
              </div>
            </li>
          </ul>
          <img
            className={`pearson-logo-white ${lang === 'ar' ? 'rtl' : ''}`}
            src={PearsonLogoWhiteImage}
            alt=""
          />
        </div>
        <div className="subscription-price">
          <ul>
            <li>
              <button type="submit" onClick={() => onChooseSubscription(1)}>
                <div className="price-btn">
                  <div className="flex-1">
                    <h3>
                      1 <FormattedMessage id="common.month" />
                    </h3>
                    <p className="sub-title">$99.99</p>
                  </div>
                  <div className="divider-line" />
                  <div className="flex-1">
                    <h3 className="price-title">$99.99</h3>
                    <p className="sub-title">
                      /<FormattedMessage id="common.month" />
                    </p>
                  </div>
                </div>
              </button>
            </li>
            <li>
              <button type="submit" onClick={() => onChooseSubscription(3)}>
                <div className="price-btn">
                  <div className="flex-1">
                    <h3>
                      3 <FormattedMessage id="common.months" />
                    </h3>
                    <p className="sub-title">$244.97</p>
                  </div>
                  <div className="divider-line" />
                  <div className="flex-1">
                    <h3 className="price-title">$74.99</h3>
                    <p className="sub-title">
                      /<FormattedMessage id="common.month" />
                    </p>
                  </div>
                </div>
              </button>
            </li>
            <li>
              <button
                type="submit"
                className="recommended-option"
                onClick={() => onChooseSubscription(6)}
              >
                <div className="price-btn">
                  <div>
                    <h3>
                      6 <FormattedMessage id="common.months" />
                    </h3>
                    <div className="sub-title-info">
                      <p className="sub-title-discount-price">$489.94</p>
                      <p className="sub-title">$269.94</p>
                    </div>
                  </div>
                  <div className="divider-line" />
                  <div>
                    <h3 className="price-title">$44.99</h3>
                    <p className="sub-title">
                      /<FormattedMessage id="common.month" />
                    </p>
                  </div>
                </div>
                <img className="discount-ribbon" src={SubscriptionDiscountRibbonImage} alt="" />
                <div className="most-popular">
                  <p>
                    <FormattedMessage id="subscriptionModal.mostPopular" />
                  </p>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="disclaimer">Mondly subscriptions automatically renew.</div> */}
    </section>
  );
}

export default SubscriptionModal;
