import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserContext } from '../../Providers/UserContext';
import { useModalsContext } from '../../Providers/ModalsContext';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import crownImage from '../../assets/image/crown.png';
import closeImage from '../../assets/image/close.png';
import goBackBtnImage from '../../assets/image/go-back-btn.png';
import closeThick from '../../assets/image/close-thick-icon-24.png';
import caretLeft from '../../assets/image/caret-left-24.png';
import useWindowSize from '../../hooks/useWindowSize';
import { sendEventToMondly } from '../../helpers/Event';
import SubscriptionModal from '../Modals/SubscriptionModal';
import YellowProgressBar from '../Shared/YellowProgressBar';
import { getIsUserLiveTutoring, getUserTimezone } from '../../helpers/utils';

interface HeaderProps {
  bodyScrollHeight: number;
  isTermsAndConditions: boolean;
  setTermsAndConditionsModal?: (a: boolean) => void;
  setIsUserGuidePage?: (a: boolean) => void;
  setIsClassGuidePage?: (a: boolean) => void;
  setIsFeedbackScreen?: (a: boolean) => void;
  lang: string;
  isFeedbackScreen?: boolean;
  isUserGuidePage?: boolean;
  isClassGuidePage?: boolean;
  yellowProgressBarCurrVal?: number;
  yellowProgressBarMaxVal?: number;
  platform: string;
}

function Header({
  bodyScrollHeight,
  isTermsAndConditions = false,
  isUserGuidePage = false,
  isClassGuidePage = false,
  isFeedbackScreen = false,
  setIsFeedbackScreen = undefined,
  setTermsAndConditionsModal = undefined,
  setIsUserGuidePage = undefined,
  setIsClassGuidePage = undefined,
  lang,
  yellowProgressBarCurrVal = 1,
  yellowProgressBarMaxVal = 4,
  platform,
}: HeaderProps) {
  const { user } = useUserContext();
  const { updateModalContent } = useModalsContext();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const screenWidth = windowSize.width || 768;
  const [isScrolled, setIsScrolled] = useState(false);
  const isSessionEnd = location.includes('/sessionend/');
  const sessionId = isSessionEnd ? location.match('/sessionend/(.*)')?.[1] : '-1';
  const intl = useIntl();

  useEffect(() => {
    if (
      (screenWidth < 768 && bodyScrollHeight > 71) ||
      (screenWidth >= 768 && bodyScrollHeight > 116)
    ) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [bodyScrollHeight, intl.locale, screenWidth]);

  function handleCloseBtn() {
    if (setIsFeedbackScreen) {
      setIsFeedbackScreen(false);
    }
    if (isSessionEnd) {
      // Report click on close button
      sendEventToMondly(window.mondlyNative?.analyticsTypes.LESSON_SCREEN_QUIT, {
        screenID: window.mondlyNative?.screenIds.FEEDBACK,
        lessonID: sessionId?.toString(),
      });

      // Mixpanel metric:
      // mixpanelTrackEvent('FeedbackClose');

      navigate('/');
    } else {
      // Mixpanel metric:
      mixpanelTrackEvent('TutoringExit', {
        companyId: user?.b2b.companyId,
        groupId: user?.b2b.groupId,
        groupName: user?.b2b.groupName,
        companyName: user?.b2b.companyName,
        userRoles: user?.roles,
      });

      console.log({ msg: 'Close window' });
      // if (hash)
      window.mondlyNative?.closeWebView().catch((err) => console.log(err));
    }
  }

  const handleGoBackBtn = () => {
    if (setIsUserGuidePage) {
      setIsUserGuidePage(false);
      navigate('/');
    }
    if (setIsClassGuidePage) {
      setIsClassGuidePage(false);
      navigate('/?showBooked=true');
    }
  };

  const getHeaderTitle = () => {
    if (isTermsAndConditions) {
      return <FormattedMessage id="preClassScreen.termsAndConditions" />;
    }
    if (isSessionEnd) {
      return <FormattedMessage id="feedback.feedback" />;
    }
    return 'Language Tutoring';
  };

  const openSubscriptionModal = () => {
    updateModalContent(<SubscriptionModal lang={lang} />);
  };

  const getCurrProgressBarVal = () => {
    if (yellowProgressBarCurrVal <= yellowProgressBarMaxVal) {
      return yellowProgressBarCurrVal.toFixed(0);
    }
    return yellowProgressBarMaxVal.toFixed(0);
  };

  return (
    <div
      className={`header ${isScrolled ? 'isScrolled' : ''}  ${
        isSessionEnd ? 'feedback-header' : ''
      } ${isFeedbackScreen ? 'updatedHeader' : ''} 
      ${isUserGuidePage || isClassGuidePage ? 'learner-guide-header' : ''}
      ${platform === 'web' && isUserGuidePage ? 'no-header' : ''}
      `}
    >
      {isFeedbackScreen && (
        <div className="header-row updated-row">
          <button type="submit" className="nav-btn" onClick={handleCloseBtn}>
            <img src={closeThick} alt="" />
          </button>
          <YellowProgressBar maxVal={yellowProgressBarMaxVal} currVal={yellowProgressBarCurrVal} />
          <div className="progressBar-progress header-corner-info">
            {getCurrProgressBarVal()}/{yellowProgressBarMaxVal}
          </div>
        </div>
      )}

      {isUserGuidePage && (
        <div className="header-row updated-row">
          {platform === 'web' && isUserGuidePage ? (
            <div style={{ visibility: 'hidden', width: '44px' }}>spaceholder</div>
          ) : (
            <button type="submit" className="nav-btn" onClick={handleGoBackBtn}>
              <img src={caretLeft} alt="" />
            </button>
          )}
          <p className="learner-guide-title">
            <FormattedMessage id="learnerGuidePage.classLevel" />
          </p>
          <div style={{ visibility: 'hidden', width: '44px' }}>spaceholder</div>
        </div>
      )}

      {isClassGuidePage && (
        <div className="header-row updated-row">
          <button type="submit" className="nav-btn" onClick={handleGoBackBtn}>
            <img src={caretLeft} alt="" />
          </button>
          <p className="learner-guide-title">
            <FormattedMessage id="classGuidePage.classTips" />
          </p>
          <div style={{ visibility: 'hidden', width: '44px' }}>spaceholder</div>
        </div>
      )}

      {!isFeedbackScreen && !isUserGuidePage && !isClassGuidePage && (
        <div className="header-row">
          {!isTermsAndConditions && (
            <button type="submit" className="header-btn-close" onClick={handleCloseBtn}>
              <img src={closeImage} alt="" />
            </button>
          )}
          {isTermsAndConditions && (
            <button
              type="submit"
              className="go-back-btn"
              onClick={() => setTermsAndConditionsModal?.(false)}
            >
              <img src={goBackBtnImage} alt="" />
            </button>
          )}

          <div className="header__text">
            <p>{getHeaderTitle()}</p>
            {!isFeedbackScreen && !isUserGuidePage && !isClassGuidePage && (
              <p className="subtitle">{getUserTimezone()}</p>
            )}
          </div>

          <button
            type="button"
            className={`crown-btn ${
              isTermsAndConditions || (user && !getIsUserLiveTutoring(user.roles) && !user.isWizard)
                ? ''
                : 'hide'
            }`}
            onClick={openSubscriptionModal}
          >
            <img src={crownImage} alt="" />
          </button>
        </div>
      )}
    </div>
  );
}

export default Header;
