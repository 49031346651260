import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { useUserContext } from '../../Providers/UserContext';
import { ReactComponent as ClassGuideImg } from '../../assets/image/clipboard.svg';
import { ReactComponent as CaretRight } from '../../assets/image/caret-right-light-24.svg';

interface ClassGuideBannerProps {
  setIsClassGuidePage: (val: boolean) => void;
  // platform: string;
  lang: string;
}

function ClassGuideBanner({ setIsClassGuidePage, lang }: ClassGuideBannerProps) {
  const navigate = useNavigate();
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();

  const handleNavigateToClassGuide = () => {
    mixpanelTrackEvent('ClassGuideBannerClicked', {
      userId: user?.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
    // if (platform === 'web') {
    //   const learnerGuideUrl = `/classGuide/?testWeb=true&platform=web&mother_lang=${lang}`;
    //   window.open(learnerGuideUrl, '_blank');
    // } else {
    setIsClassGuidePage(true);
    navigate('/classguide');
    // }
  };

  const getProgressImage = () => {
    return <CaretRight />;
  };

  return (
    <section
      className="class-guide-banner flex space-between"
      role="button"
      tabIndex={0}
      onClick={() => handleNavigateToClassGuide()}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') handleNavigateToClassGuide();
      }}
    >
      <div className={`user-guide-banner-image-text flex gap-8 ${lang === 'ar' ? 'rtl' : ''}`}>
        <div className="user-guide-img" style={{ height: '44px' }}>
          <ClassGuideImg />
        </div>
        <div style={{ alignSelf: 'center' }}>
          <p className="title" dir="auto">
            <FormattedMessage id="classGuideBanner.howToGetTheMostOutOfAClass" />
          </p>
        </div>
      </div>
      <div className="user-guide-img" style={{ height: '24px' }}>
        {getProgressImage()}
      </div>
    </section>
  );
}

export default ClassGuideBanner;
