import { FormattedMessage } from 'react-intl';
import alertBell from '../../assets/image/alert-bell.png';

interface JoiningClassModalProps {
  onClose: () => void;
}

function JoiningClassModal({ onClose }: JoiningClassModalProps) {
  return (
    <div className="joining-class-modal">
      <div className="icon-container">
        <img src={alertBell} alt="Bell Icon" />
      </div>
      <div className="title">
        <FormattedMessage id="joiningClassModal.title" />
      </div>
      <div className="subtitle">
        <FormattedMessage id="joiningClassModal.YouMust" />
      </div>
      <div className="subtitle">
        <FormattedMessage id="joiningClassModal.ClassSmoothly" />
      </div>
      <div className="button-container">
        <button type="button" onClick={onClose}>
          <FormattedMessage id="joiningClassModal.confirmButton" />
        </button>
      </div>
    </div>
  );
}

export default JoiningClassModal;
