import { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { getLangDir } from '../../../helpers/utils';
import StarImage from '../../../assets/image/star-filled-icon-24.png';

interface FeedbackStarRateProps {
  starVal: number;
  onResponse: (val: number) => void;
  questionNum: 1 | 2 | 3 | 4;
  lang: string;
  fullFeedback: {
    [key: number]: {
      rate: number | null;
      response: {
        userSelection: string[];
        userFreeText: string;
      };
    };
  };
}

function FeedbackStarRate({
  starVal,
  onResponse,
  questionNum,
  lang,
  fullFeedback,
}: FeedbackStarRateProps) {
  const onChooseRate = () => {
    onResponse(starVal);
  };
  const getIsOptionSelected = () => {
    return fullFeedback[questionNum].rate === starVal;
  };
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(getIsOptionSelected());
  }, [fullFeedback, questionNum]);

  const stars = Array.from({ length: starVal }, (_, index) => (
    <img src={StarImage} alt="Star" key={index} />
  ));

  const getRatingDescription = () => {
    // if (questionNum === 1) {
    switch (starVal) {
      case 1:
        return <FormattedMessage id="feedbackStarRate.terrible" />;
      case 2:
        return <FormattedMessage id="feedbackStarRate.bad" />;

      case 3:
        return <FormattedMessage id="feedbackStarRate.ok" />;
      case 4:
        return <FormattedMessage id="feedbackStarRate.good" />;

      case 5:
        return <FormattedMessage id="feedbackStarRate.fantastic" />;

      default:
        return <FormattedMessage id="feedbackStarRate.fantastic" />;
    }
    // }
    // switch (starVal) {
    //   case 1:
    //     return <FormattedMessage id="feedbackStarRate.iStronglyDislikedIt" />;
    //   case 2:
    //     return <FormattedMessage id="feedbackStarRate.iDislikedIt" />;
    //   case 3:
    //     return <FormattedMessage id="feedbackStarRate.imNeutralIt" />;
    //   case 4:
    //     return <FormattedMessage id="feedbackStarRate.iLikedIt" />;
    //   case 5:
    //     return <FormattedMessage id="feedbackStarRate.iStronglyLikedIt" />;

    //   default:
    //     return <FormattedMessage id="feedbackStarRate.iStronglyLiked" />;
    // }
  };

  return (
    <section
      className={`sequential-feedback-star-rate-container ${isSelected ? 'selected' : ''}`}
      dir={getLangDir(lang)}
    >
      <button className="rate-btn" type="button" onClick={() => onChooseRate()}>
        <p>{getRatingDescription()}</p>
        <div className="stars">{stars}</div>
      </button>
    </section>
  );
}

export default FeedbackStarRate;
