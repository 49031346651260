import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { useUserContext } from '../../Providers/UserContext';

function UserGuidePage() {
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();
  useEffect(() => {
    mixpanelTrackEvent('PageView', {
      PageName: 'Level guide',
      userId: user?.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
  }, []);

  return (
    <section className="user-guide-page flex column" dir="auto">
      <div>
        <p className="title">
          <FormattedMessage id="userGuideBanner.whichClassIsRightForMe" />
        </p>
        <p className="subtitle">
          <FormattedMessage id="learnerGuidePage.ifYouAreUnsureWhichClassToChoose" />
        </p>
      </div>
      <div className="levels">
        <div className="level beginner">
          <p className="level-name-title" dir="auto">
            <FormattedMessage id="classCard.level.Beginner" />
          </p>
          <div className="level-cards">
            <div className="cefr first">
              <div className="cefr-title flex space-between align-center">
                <p className="cefr-level">A1</p>
                <div className="flex gap-5" dir="auto">
                  <p className="gse-level">
                    <FormattedMessage id="learnerGuidePage.gseScore" /> :
                  </p>
                  <p className="gse-level">10 - 29</p>
                </div>
              </div>
              <div className="cefr-content" dir="auto">
                <FormattedMessage id="learnerGuidePage.iCanAskAndAnswerSimpleQuestions" />
              </div>
            </div>
            <div className="cefr second">
              <div className="cefr-title flex space-between align-center">
                <p className="cefr-level">A2</p>
                <div className="flex gap-5" dir="auto">
                  <p className="gse-level">
                    <FormattedMessage id="learnerGuidePage.gseScore" /> :
                  </p>
                  <p className="gse-level">30 - 42</p>
                </div>
              </div>
              <div className="cefr-content" dir="auto">
                <FormattedMessage id="learnerGuidePage.iCanAchieveSimpleRoutineTasks" />
              </div>
            </div>
          </div>
        </div>
        <div className="level intermediate">
          <p className="level-name-title" dir="auto">
            {' '}
            <FormattedMessage id="classCard.level.Intermediate" />
          </p>
          <div className="level-cards">
            <div className="cefr first">
              <div className="cefr-title flex space-between align-center">
                <p className="cefr-level">B1</p>
                <div className="flex gap-5" dir="auto">
                  <p className="gse-level">
                    <FormattedMessage id="learnerGuidePage.gseScore" /> :
                  </p>
                  <p className="gse-level">43 - 58</p>
                </div>
              </div>
              <div className="cefr-content" dir="auto">
                <FormattedMessage id="learnerGuidePage.iCanInteractWithSomeFluency" />
              </div>
            </div>
            <div className="cefr second">
              <div className="cefr-title flex space-between align-center">
                <p className="cefr-level">B2</p>
                <div className="flex gap-5" dir="auto">
                  <p className="gse-level">
                    <FormattedMessage id="learnerGuidePage.gseScore" /> :
                  </p>
                  <p className="gse-level">59 - 75</p>
                </div>
              </div>
              <div className="cefr-content" dir="auto">
                <FormattedMessage id="learnerGuidePage.iCanInteractWithPeopleOnMoreAbstractTopics" />
              </div>
            </div>
          </div>
        </div>
        <div className="level advanced">
          <p className="level-name-title" dir="auto">
            {' '}
            <FormattedMessage id="classCard.level.Advanced" />
          </p>
          <div className="level-cards">
            <div className="cefr first">
              <div className="cefr-title flex space-between align-center">
                <p className="cefr-level">C1</p>
                <div className="flex gap-5" dir="auto">
                  <p className="gse-level">
                    <FormattedMessage id="learnerGuidePage.gseScore" /> :
                  </p>
                  <p className="gse-level">76 - 84</p>
                </div>
              </div>
              <div className="cefr-content" dir="auto">
                <FormattedMessage id="learnerGuidePage.imCapableOfComplexInteractions" />
              </div>
            </div>
            <div className="cefr second">
              <div className="cefr-title flex space-between align-center">
                <p className="cefr-level">C2</p>
                <div className="flex gap-5" dir="auto">
                  <p className="gse-level">
                    <FormattedMessage id="learnerGuidePage.gseScore" /> :
                  </p>
                  <p className="gse-level">85 - 90</p>
                </div>
              </div>
              <div className="cefr-content" dir="auto">
                <FormattedMessage id="learnerGuidePage.iCanUseTheLanguageComfortably" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserGuidePage;
