import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMixpanelContext } from '../../Providers/MixpanelContext';
import { useUserContext } from '../../Providers/UserContext';
import { ReactComponent as NotificationOffIcon } from '../../assets/image/notificationoff-icon-24.svg';
import { ReactComponent as LaptopIcon } from '../../assets/image/laptop.svg';
import { ReactComponent as GroupIcon } from '../../assets/image/Group.svg';
import { ReactComponent as ClockIcon } from '../../assets/image/clock.svg';
import { ReactComponent as VideoCameraIcon } from '../../assets/image/video-camera.svg';

interface ClassGuidePageProps {
  platform: string;
}

function ClassGuidePage({ platform }: ClassGuidePageProps) {
  const { mixpanelTrackEvent } = useMixpanelContext();
  const { user } = useUserContext();
  useEffect(() => {
    mixpanelTrackEvent('PageView', {
      PageName: 'Class guide',
      userId: user?.id,
      companyId: user?.b2b.companyId,
      groupId: user?.b2b.groupId,
      groupName: user?.b2b.groupName,
      companyName: user?.b2b.companyName,
      userRoles: user?.roles,
    });
  }, []);

  return (
    <section className="class-guide-page" dir="auto">
      <div>
        <p className="title">
          <FormattedMessage id="classGuideBanner.howToGetTheMostOutOfAClass" />
        </p>
      </div>
      <div className="class-guidelines">
        <div className="guideline">
          <div className="header">
            <div className={`guideline-img ${platform === 'web' ? 'web' : ''}`}>
              <NotificationOffIcon />
            </div>
            <p className="guideline-title">
              <FormattedMessage id="classGuidePage.findAQuietSpot" />
            </p>
          </div>

          <p className="description">
            <FormattedMessage id="classGuidePage.makeSureThereIsNoBgcNoise" />
          </p>
        </div>

        <div className="guideline">
          <div className="header">
            <div className={`guideline-img ${platform === 'web' ? 'web' : ''}`}>
              <LaptopIcon />
            </div>
            <p className="guideline-title">
              <FormattedMessage id="classGuidePage.useAComputerWithChrome" />
            </p>
          </div>

          <p className="description">
            <FormattedMessage id="classGuidePage.weRecommendUsingALaptop" />
          </p>
          <div className="description flex gap-8">
            <div style={{ height: '24px', width: '24px' }}>
              <GroupIcon />
            </div>
            <FormattedMessage id="classGuidePage.noComputerYouCanStillJoin" />
          </div>
        </div>
        <div className="guideline">
          <div className="header">
            <div className={`guideline-img ${platform === 'web' ? 'web' : ''}`}>
              <ClockIcon />
            </div>
            <p className="guideline-title">
              <FormattedMessage id="classGuidePage.joinOnTime" />
            </p>
          </div>

          <p className="description">
            <FormattedMessage id="classGuidePage.theClassroomWillClose5MinutesAfter" />
          </p>
        </div>
        <div className="guideline">
          <div className="header">
            <div className={`guideline-img ${platform === 'web' ? 'web' : ''}`}>
              <VideoCameraIcon />
            </div>
            <p className="guideline-title">
              <FormattedMessage id="classGuidePage.turnOnYourCamera" />
            </p>
          </div>

          <p className="description">
            <FormattedMessage id="classGuidePage.youreMoreLikelyToFeelLike" />
          </p>
        </div>
      </div>
    </section>
  );
}

export default ClassGuidePage;
