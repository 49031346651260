import { FormattedMessage } from 'react-intl';
import { useModalsContext } from '../../Providers/ModalsContext';

function FreeFeatureModal() {
  const { updateModalContent } = useModalsContext();

  const onCloseModal = () => {
    // dispatch(setModalContent(undefined));
    updateModalContent(undefined);
  };

  return (
    <section className="free-feature-modal modal">
      <div className="content">
        <h1>
          <FormattedMessage id="freeFeatureModal.greatNews" />
        </h1>
        <p>
          <FormattedMessage id="freeFeatureModal.theFeatureIsCurrentyFree" />
        </p>
        <p>
          <FormattedMessage id="freeFeatureModal.enjoyIt" />
        </p>
        <button type="submit" className="cta-btn pre-class-join-btn" onClick={onCloseModal}>
          <FormattedMessage id="feedback.continue" />
        </button>
      </div>
      <div className="disclaimer">
        <FormattedMessage id="freeFeatureModal.mondlyClassIsCurrentlyFree" />
      </div>
    </section>
  );
}

export default FreeFeatureModal;
